import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { backend_url, server } from "../../server";
import UserHelmet from "../../helmet/userHelmet";
import { AiOutlineDelete } from "react-icons/ai";
import { showToastError } from "../../static/toastMessage";
import { addToCart, removeFromCart } from "../../redux/actions/cart";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";

const ReviewOrderPage = () => {
  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRemovingItem, setIsRemovingItem] = useState(false);

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.originalPrice,
    0
  );

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const quantityChangeHandler = (data) => {
    dispatch(addToCart(data));
  };

  const paymentSubmit = () => {
    navigate("/checkout");
  };

  const removeFromReviewOrder = async (data) => {
    setIsRemovingItem(true);
    try {
      await dispatch(removeFromCart(data));
      setIsRemovingItem(false);
    } catch (error) {
      setIsRemovingItem(false);
      showToastError(error);
    }
  };

  useEffect(() => {
    if (cart.length === 0 && !isRemovingItem) {
      navigate("/");
    }
  }, [cart.length, isRemovingItem, navigate]);

  return (
    <UserHelmet title={user?.name} page="Review Order Page">
      <div className="w-full flex flex-col items-center py-8">
        <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
          <div className="w-full 800px:w-[65%]">
            <h5 className="text-[18px] font-[500]">
              {user?.name}'s Cart {cart && cart.length === 1 && `Item`}
              {cart && cart.length > 1 && `Items`}
            </h5>
            <hr className="h-px mt-4 mb-4 w-[50%] bg-gray-200 border-0 dark:bg-gray-700" />
            {cart &&
              cart.map((i, index) => (
                <ReviewOrder
                  key={index}
                  item={i}
                  cart={cart}
                  removeFromReviewOrder={removeFromReviewOrder}
                  quantityChangeHandler={quantityChangeHandler}
                  removeFromCartHandler={removeFromCartHandler}
                />
              ))}
          </div>
          <div className="w-full 800px:w-[40%] flex 800px:mt-0 mt-8">
            <CartData
              subTotalPrice={subTotalPrice}
              paymentSubmit={paymentSubmit}
              cartData={cart}
            />
          </div>
        </div>
      </div>
    </UserHelmet>
  );
};

const ReviewOrder = ({
  item,
  removeFromReviewOrder,
  quantityChangeHandler,
  removeFromCartHandler,
}) => {
  const statusInformation = item.formFields.map((field) => {
    return field.status;
  });

  const [value, setValue] = useState(item.qty);

  const [hovered, setHovered] = useState(false);

  const availableAtOnce = 10;

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const increment = (data) => {
    if (data.stock <= value) {
      showToastError(`${data.stock} are only in stocks!`);
    } else if (availableAtOnce <= value) {
      showToastError(
        `${availableAtOnce} items can only be done in one checkout!`
      );
    } else if (value > 10) {
      showToastError("Maximum 10 items per order allowed.");
    } else {
      setValue((prevValue) => prevValue + 1);
      const updateCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  const decrement = (data) => {
    if (value > 1) {
      setValue(value === 1 ? 1 : value - 1);
      const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
      quantityChangeHandler(updateCartData);
    } else {
      showToastError("At least 1 items should be ordered.");
    }
  };

  const iconColor = hovered ? "red" : "#333";

  const isStatus = Object.values(statusInformation).some((status) => status);

  return (
    <>
      <React.Fragment key={item._id}>
        <div className="w-full 800px:w-[95%] bg-white rounded-md p-5 mb-4 shadow-md">
          <div className="overflow-hidden rounded-md">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {/* {item.tags.includes("API") &&
                !item.tags.includes("Validation") ? (
                  <div>
                    <div
                      className={`bg-[#e44343] border border-[#e4434373] rounded-full w-[25px] h-[25px] ${styles.noramlFlex} justify-center cursor-pointer`}
                      onClick={() => increment(item)}
                    >
                      <HiPlus size={18} color="#fff" />
                    </div>
                    <span className="pl-[10px]">{item.qty}</span>
                    <div
                      className="bg-[#a7abb14f] rounded-full w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
                      onClick={() => decrement(item)}
                    >
                      <HiOutlineMinus size={16} color="#7d879c" />
                    </div>
                  </div>
                ) : null} */}

                <img
                  src={`${backend_url}${item.images[0]}`}
                  alt=""
                  className="ml-4 w-[100px] h-[100px] mr-[10px] rounded-md"
                />
                <div>
                  <a
                    href={`/product/${item._id}`}
                    className="pb-2 text-[#d02222] hover:underline"
                  >
                    {item.name} - {item.productType}
                  </a>
                  {isStatus === true && (
                    <div className="pt-2">
                      {Object.entries(item.formValues).map(
                        ([fieldName, value]) => (
                          <div key={fieldName} className="font-[600]">
                            {
                              item.formFields.find(
                                (field) => field.name === fieldName
                              )?.placeholder
                            }
                            :{" "}
                            {item.formFields
                              .find((field) => field.name === fieldName)
                              ?.options?.find(
                                (option) => option.value === value
                              )?.name || value}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="cursor-pointer">
                <div
                  className="cursor-pointer"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => removeFromReviewOrder(item)}
                >
                  <AiOutlineDelete size={30} color={iconColor} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
      <br />
    </>
  );
};

const CartData = ({ subTotalPrice, paymentSubmit, cartData }) => {
  return (
    <div className="w-[60%] h-44 max-[797px]:w-full bg-[#fff] rounded-md p-5 pb-5 shadow-md">
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">
          Sub-Total{" "}
          {cartData && cartData.length === 1 && `(${cartData.length} item)`}{" "}
          {cartData && cartData.length > 1 && `(${cartData.length} items)`}:
        </h3>
        <h5 className="text-[18px] font-[600]">Rs {subTotalPrice}</h5>
      </div>

      <div
        className={`${styles.button} w-full 800px:w-full mt-10`}
        onClick={paymentSubmit}
      >
        <h5 className="text-white">Proceed to Checkout</h5>
      </div>
    </div>
  );
};

export default ReviewOrderPage;
