import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { showToastError, showToastSuccess } from "../../static/toastMessage";
import { loadSeller } from "../../redux/actions/user";
import { AiOutlineDelete } from "react-icons/ai";
import {
  currentAvailableBank,
  currentAvailableCountry,
} from "../../static/data";
import ShopHelmet from "../../helmet/shopHelmet";

const WithdrawMoney = () => {
  const [open, setOpen] = useState(false);
  const { seller } = useSelector((state) => state.seller);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(50);
  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    bankCountry: "",
    bankShopCode: null,
    bankAccountNumber: null,
    bankHolderName: "",
    bankAddress: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const withdrawMethod = {
      bankName: bankInfo.bankName,
      bankCountry: bankInfo.bankCountry,
      bankShopCode: bankInfo.bankShopCode,
      bankAccountNumber: bankInfo.bankAccountNumber,
      bankHolderName: bankInfo.bankHolderName,
      bankAddress: bankInfo.bankAddress,
    };

    setPaymentMethod(false);

    await axios
      .put(
        `${server}/shop/update-payment-methods`,
        {
          withdrawMethod,
        },
        { withCredentials: true }
      )
      .then((res) => {
        showToastSuccess("Withdraw Method added successfully!");
        dispatch(loadSeller());
        setBankInfo({
          bankName: "",
          bankCountry: "",
          bankSwiftCode: null,
          bankAccountNumber: null,
          bankHolderName: "",
          bankAddress: "",
        });
      })
      .catch((error) => {
        showToastError(error.response.data.message);
      });
  };

  const deleteHandler = async () => {
    await axios
      .delete(`${server}/shop/delete-withdraw-method`, {
        withCredentials: true,
      })
      .then((res) => {
        showToastSuccess("Withdraw method deleted successfully!");
        dispatch(loadSeller());
      });
  };

  const error = () => {
    showToastError(
      "You currently don't have the threshold balance for withdraw!"
    );
  };

  const availableBalance = seller?.availableBalance.toFixed(2);

  const withdrawHandler = async () => {
    if (withdrawAmount < 50 || withdrawAmount > availableBalance) {
      showToastError("Please withdraw balance accordingly!");
    } else {
      const amount = withdrawAmount;
      await axios
        .post(
          `${server}/withdraw/create-withdraw-request`,
          { amount },
          { withCredentials: true }
        )
        .then((res) => {
          showToastSuccess(
            "Withdraw request is successful! We will update you via your mail."
          );
          window.location.reload();
        });
    }
  };

  return (
    <ShopHelmet
      title={seller.name}
      page={`Withdraw Money - Rs ${availableBalance}`}
    >
      <div className="w-full h-[88vh] p-2">
        <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
          <h5 className="text-[20px] pb-4">
            Available Balance: Rs {availableBalance}
          </h5>
          <div
            className={`${styles.button} text-white !h-[42px] !rounded`}
            onClick={() => (availableBalance < 50 ? error() : setOpen(true))}
          >
            Withdraw
          </div>
        </div>
        {open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div
              className={`w-[95%] 800px:w-[50%] bg-white shadow rounded ${
                paymentMethod ? "h-[80vh] overflow-y-auto" : "h-[unset]"
              } min-h-[40vh] p-3`}
            >
              <div className="w-full flex justify-end">
                <RxCross1
                  size={25}
                  onClick={() => setOpen(false) || setPaymentMethod(false)}
                  className="cursor-pointer"
                />
              </div>
              {paymentMethod ? (
                <div>
                  <h3 className="text-[22px] font-Poppins text-center font-[600]">
                    Add new Withdraw Method:
                  </h3>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label>
                        Bank Name <span className="text-red-500">*</span>
                      </label>
                      <select
                        name=""
                        required
                        value={bankInfo.bankName}
                        onChange={(e) =>
                          setBankInfo({ ...bankInfo, bankName: e.target.value })
                        }
                        id=""
                        className={`${styles.input} mt-2 cursor-pointer`}
                      >
                        <option value="" className="block border pb-2">
                          Select Your Bank
                        </option>
                        {currentAvailableBank &&
                          currentAvailableBank.map((item) => (
                            <option
                              className="block pb-2"
                              key={item.id}
                              value={item.name}
                            >
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="pt-2">
                      <label>
                        Bank Country <span className="text-red-500">*</span>
                      </label>
                      <select
                        name=""
                        value={bankInfo.bankCountry}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankCountry: e.target.value,
                          })
                        }
                        id=""
                        required
                        placeholder="Enter your country bank!"
                        className={`${styles.input} mt-2 cursor-pointer`}
                      >
                        <option value="" className="block border pb-2">
                          Select Your Country
                        </option>
                        {currentAvailableCountry &&
                          currentAvailableCountry.map((item) => (
                            <option
                              className="block pb-2"
                              key={item.id}
                              value={item.country}
                            >
                              {item.country}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="pt-2">
                      <label>
                        Shop Code <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        value={bankInfo.bankShopCode}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankShopCode: e.target.value,
                          })
                        }
                        placeholder="Enter your Shop Code"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
                    {/* 3:41:37 / 7:24:09 */}

                    <div className="pt-2">
                      <label>
                        Bank Account Number
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        id=""
                        value={bankInfo.bankAccountNumber}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankAccountNumber: e.target.value,
                          })
                        }
                        required
                        placeholder="Enter your bank account number"
                        className={`${styles.input} mt-2`}
                      />
                    </div>

                    <div className="pt-2">
                      <label>
                        Bank Holder Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        value={bankInfo.bankHolderName}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankHolderName: e.target.value,
                          })
                        }
                        id=""
                        placeholder="Enter the account holder name"
                        className={`${styles.input} mt-2`}
                      />
                    </div>

                    <div className="pt-2 pb-2">
                      <label>
                        Bank Branch <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        id=""
                        value={bankInfo.bankAddress}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankAddress: e.target.value,
                          })
                        }
                        placeholder="Enter your bank branch"
                        className={`${styles.input} mt-2`}
                      />
                    </div>

                    <button
                      type="submit"
                      className={`${styles.button} mb-3 text-white`}
                    >
                      Add
                    </button>
                  </form>
                </div>
              ) : (
                <>
                  <h3 className="text-[22px] font-Poppins">
                    Available Withdraw Methods:
                  </h3>

                  {seller && seller?.withdrawMethod ? (
                    <div>
                      <div className="800px:flex w-full justify-between items-center">
                        <div className="800px:w-[50%]">
                          <h5>
                            Account Number:{" "}
                            {"*".repeat(
                              seller?.withdrawMethod.bankAccountNumber.length -
                                3
                            ) +
                              seller?.withdrawMethod.bankAccountNumber.slice(
                                -3
                              )}
                          </h5>
                          <h5>Bank Name: {seller?.withdrawMethod.bankName}</h5>
                        </div>
                        <div className="800px:w-[50%]">
                          <AiOutlineDelete
                            size={25}
                            className="cursor-pointer"
                            onClick={() => deleteHandler()}
                          />
                        </div>
                      </div>
                      <br />
                      <h4>Available Balance: {availableBalance}$</h4>
                      <br />
                      <div className="800px:flex w-full items-center">
                        <input
                          type="number"
                          placeholder="Amount..."
                          value={withdrawAmount}
                          onChange={(e) => setWithdrawAmount(e.target.value)}
                          className="800px:w-[100px] w-[full] border 800px:mr-3 p-1 rounded"
                        />
                        <div
                          className={`${styles.button} !h-[42px] text-white`}
                          onClick={withdrawHandler}
                        >
                          Withdraw
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className="text-[18px] pt-2">
                        No Withdraw Methods available!
                      </p>
                      <div className="w-full flex items-center">
                        <div
                          className={`${styles.button} text-[#fff] text-[18px] mt-4`}
                          onClick={() => setPaymentMethod(true)}
                        >
                          Add new
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </ShopHelmet>
  );
};

export default WithdrawMoney;
