import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { backend_url } from "../../server";
import styles from "../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { editProduct } from "../../redux/actions/product";
import { categoriesData, countryISOCurrencyCode } from "../../static/data";
import { getAllProductsShop } from "../../redux/actions/product";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { showToastError, showToastSuccess } from "../../static/toastMessage";
import { v4 as uuid4 } from "uuid";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditProduct = ({ setOpen, data }) => {
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const [click, setClick] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [playStoreLink, setPlayStoreLink] = useState("");
  const [appStoreLink, setAppStoreLink] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [stock, setStock] = useState();

  // Images
  const [images, setImages] = useState([]);

  // Description
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [quickDescEditorState, setQuickDescEditorState] = useState(
    EditorState.createEmpty()
  );

  // Buttons
  const [featured, setFeatured] = useState("");
  const [sales, setSales] = useState("");
  const [status, setStatus] = useState("");

  // Input Values
  const [originalPrice, setOriginalPrice] = useState([]);
  const [fields, setFields] = useState([]);

  // Handle Add Click
  const handleAddClick = () => {
    setOriginalPrice([
      ...originalPrice,
      {
        cost: "",
        sku: "",
        currency: "USD",
        currencyPrice: "",
        id: uuid4(),
      },
    ]);
  };

  // Handle Remove
  const handleRemoveClick = (id: String) => {
    let _originalPrice = [...originalPrice];
    _originalPrice = _originalPrice.filter((btnData) => btnData.id !== id);
    setOriginalPrice(_originalPrice);
  };

  // Handle Product Data Price Change
  const handlePriceFieldData = (index, fieldKey, value) => {
    const updatedPriceFields = [...originalPrice];
    const updatedPriceField = {
      ...updatedPriceFields[index],
      [fieldKey]: value,
    };
    updatedPriceFields[index] = updatedPriceField;
    setOriginalPrice(updatedPriceFields);
  };

  // Handle Add User Data Field
  const handleAddField = () => {
    setFields([
      ...fields,
      {
        placeholder: "",
        name: "",
        type: "number",
        options: [{ name: "", value: "", id: uuid4() }],
        required: "",
        status: "",
        id: uuid4(),
      },
    ]);
  };

  // Remove User Form Data Field(s)
  const handleRemoveField = (id: string) => {
    let _updatedFields = [...fields];
    _updatedFields = _updatedFields.filter((field) => field.id !== id);
    setFields(_updatedFields);
  };

  // Handle User Form Data Price Change
  const handleFieldChange = (index, fieldKey, value) => {
    const updatedFields = [...fields];
    const updatedField = { ...updatedFields[index], [fieldKey]: value };
    updatedFields[index] = updatedField;

    if (fieldKey === "options") {
      const options = JSON.parse(value);
      updatedFields[index][fieldKey] = options;
    }

    setFields(updatedFields);
  };

  // Handle Select Option of User Data
  const handleAddSelectOption = (fieldIndex) => {
    let updatedFields = [...fields];
    updatedFields[fieldIndex].options.push({
      name: "",
      value: "",
      id: uuid4(),
    });
    setFields(updatedFields);
  };

  const handleRemoveSelectOption = (fieldIndex, optionId) => {
    const updatedFields = [...fields];
    const field = updatedFields[fieldIndex];
    if (field && field.options) {
      field.options = field.options.filter((option) => option.id !== optionId);
      setFields(updatedFields);
    }
  };

  const handleSelectOptionChange = (
    fieldIndex,
    optionIndex,
    optionKey,
    value
  ) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].options[optionIndex][optionKey] = value;
    setFields(updatedFields);
  };

  useEffect(() => {
    if (error) {
      showToastError(error);
    }
    if (success) {
      showToastSuccess("Product edited successfully!");
      navigate("/dashboard-products");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  useEffect(() => {
    if (data) {
      setName(data.row.name);
      setDescription(data.row.description);
      setCategory(data.row.category);
      setTags(data.row.tags);
      setPlayStoreLink(data.row.playStoreLink);
      setAppStoreLink(data.row.appStoreLink);
      setStock(data.row.stock);
      setFeatured(data.row.featured);
      setSales(data.row.sales);
      setStatus(data.row.status);
      setDiscountPrice(data.row.discountPrice);

      // Original Price
      const originalPriceArray = data.row.originalPrice.map((price) => {
        const cost = price.cost;
        const sku = price.sku;
        const currency = price.currency;
        const currencyPrice =
          price.currencyPrice === null ? "" : price.currencyPrice;
        const id = price._id;
        return {
          cost,
          sku,
          currency,
          currencyPrice,
          id,
        };
      });
      setOriginalPrice(originalPriceArray);

      const formFieldsArray = data.row.formFields.map((data) => {
        const name = data.name;

        const options = data.options.map((option) => {
          const name = option.name;
          const value = option.value;
          const id = option._id;

          return {
            name,
            value,
            id,
          };
        });

        const placeholder = data.placeholder;
        const required = data.required;
        const status = data.status;
        const type = data.type;
        const id = data._id;

        return {
          name,
          placeholder,
          options,
          required,
          status,
          type,
          id,
        };
      });

      setFields(formFieldsArray);

      // Description(s)
      const dataDescription = convertFromRaw(JSON.parse(data.row.description));
      const descriptionState = EditorState.createWithContent(dataDescription);
      setEditorState(descriptionState);

      const dataQuickDescription = convertFromRaw(
        JSON.parse(data.row.quickDescription)
      );
      const quickDescriptionState =
        EditorState.createWithContent(dataQuickDescription);
      setQuickDescEditorState(quickDescriptionState);

      // Images
      setImages(data.row.images.map((imageName) => imageName));
    }
  }, [dispatch, data.id]);

  // Handle Image Change
  const handleImageChange = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  // Delete Image Handler
  const handleDeleteImage = (imageName) => {
    const updatedImages = images.filter((name) => name !== imageName);
    setImages(updatedImages);
  };

  // Handle Description Editor Change
  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  // Handle Description Editor Change for Quick Description
  const handleQuickDescEditorChange = (newQuickDescEditorState) => {
    setQuickDescEditorState(newQuickDescEditorState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedForm = new FormData();

    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    const quickDescContentState = quickDescEditorState.getCurrentContent();
    const quickDescRawContent = convertToRaw(quickDescContentState);

    fields.forEach((field, index) => {
      updatedForm.append(`formFields[${index}][type]`, field.type);
      updatedForm.append(`formFields[${index}][name]`, field.name);
      updatedForm.append(
        `formFields[${index}][placeholder]`,
        field.placeholder
      );
      updatedForm.append(`formFields[${index}][required]`, field.required);
      updatedForm.append(`formFields[${index}][status]`, field.status);

      field.options.forEach((option, optionIndex) => {
        updatedForm.append(
          `formFields[${index}][options][${optionIndex}][name]`,
          option.name
        );
        updatedForm.append(
          `formFields[${index}][options][${optionIndex}][value]`,
          option.value
        );
      });
    });

    originalPrice.forEach((price, index) => {
      updatedForm.append(`priceData[${index}][cost]`, price.cost);
      updatedForm.append(`priceData[${index}][sku]`, price.sku);
      updatedForm.append(`priceData[${index}][currency]`, price.currency);
      updatedForm.append(
        `priceData[${index}][currencyPrice]`,
        price.currencyPrice
      );
    });

    if (images.length === 0) {
      showToastError("Please select at least one image.");
      return;
    } else if (images.length !== 1) {
      showToastError(
        "Please Delete the Previous Image and Upload New or Leave it intact."
      );
      return;
    } else {
      images.forEach((image, index) => {
        updatedForm.append(`images`, image);
      });
    }

    updatedForm.append("name", name);
    updatedForm.append("category", category);
    updatedForm.append("tags", tags);
    updatedForm.append("stock", stock);
    updatedForm.append("playStoreLink", playStoreLink);
    updatedForm.append("appStoreLink", appStoreLink);
    updatedForm.append("featured", featured);
    updatedForm.append("sales", sales);
    updatedForm.append("status", status);
    updatedForm.append("description", JSON.stringify(rawContent));
    updatedForm.append("quickDescription", JSON.stringify(quickDescRawContent));
    updatedForm.append("shopId", seller._id);

    dispatch(editProduct(data.id, updatedForm));
  };

  return (
    <div className="bg-[#fff] cursor-default">
      <div className="fixed w-full h-screen top-0 left-0 bg-[#00000030] z-40 flex items-center justify-center">
        <div className="w-[90%] 800px:w-[60%] h-[90vh] overflow-auto 800px:h-[75vh] bg-white rounded-md shadow-sm relative p-4">
          <RxCross1
            size={30}
            className="absolute right-3 top-3 z-50 cursor-pointer"
            onClick={() => setOpen(false)}
          />
          <h5 className="text-[20px] font-Poppins text-center">
            Edit Product : {data.row.name}
          </h5>
          {/* Create Product Form */}
          <form onSubmit={handleSubmit}>
            <br />
            {/* Product Name */}
            <div>
              <label className="pb-2">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                defaultValue={name}
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your product name..."
              />
            </div>
            <br />
            {/* Link */}
            <div className="w-full 800px:flex items-center">
              {/* Play Store Link */}
              <div className="w-full 800px:w-[49%]">
                <label className="pb-2">Play Store Link</label>
                <input
                  type="text"
                  name="playStoreLink"
                  defaultValue={playStoreLink}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setPlayStoreLink(e.target.value)}
                  placeholder="Enter play store link....."
                />
              </div>

              {/* Gap */}
              <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

              {/* App Store Link */}
              <div className="w-full 800px:w-[49%]">
                <label className="pb-2">Apple Store Link</label>
                <input
                  type="text"
                  name="appStoreLink"
                  defaultValue={appStoreLink}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setAppStoreLink(e.target.value)}
                  placeholder="Enter app store link....."
                />
              </div>
            </div>
            {/* Product Description */}
            <br />
            <div>
              <label className="pb-2">
                Description <span className="text-red-500">*</span>
              </label>
              <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="mt-2 appearance-none block pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                editorClassName="p-2 cursor-text"
                editorStyle={{ overflowY: "auto", height: 200 + "px" }}
                wrapperStyle={{ height: 280 + "px" }}
              />
            </div>
            <br />
            {/* User Form Data */}
            <div>
              <label className="pb-2">
                User Form <span className="text-red-500">*</span>
              </label>
              {fields.map((field, index) => (
                <div className="w-full 800px:flex items-center" key={field.id}>
                  {fields.length - 1 === index && (
                    <button
                      type="button"
                      className="mr-2 mt-2 pb-1 text-[12px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                      onClick={handleAddField}
                    >
                      Add
                    </button>
                  )}
                  <div className="w-full 800px:w-[25%]">
                    <input
                      type="text"
                      defaultValue={field.placeholder}
                      onChange={(event) =>
                        handleFieldChange(
                          index,
                          "placeholder",
                          event.target.value
                        )
                      }
                      required
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Enter a placeholder field...."
                    />
                  </div>
                  {/* Gap */}
                  <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                  <div className="w-full 800px:w-[25%]">
                    <input
                      type="text"
                      defaultValue={field.name}
                      onChange={(event) =>
                        handleFieldChange(index, "name", event.target.value)
                      }
                      required
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Enter a field name...."
                    />
                  </div>

                  {/* Gap */}
                  <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                  <div className="w-full 800px:w-[25%]">
                    <select
                      defaultValue={field.type}
                      onChange={(event) =>
                        handleFieldChange(index, "type", event.target.value)
                      }
                      required
                      className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                      required
                    >
                      <option value="" disabled>
                        Choose a Option
                      </option>
                      <option value="text">Text</option>
                      <option value="number">Number</option>
                      <option value="select">Select</option>
                      <option value="email">Email</option>
                      <option value="checkbox">Checkbox</option>
                      <option value="file">File Upload</option>
                    </select>
                  </div>

                  {/* Gap */}
                  <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                  <div className="w-full 800px:w-[25%]">
                    <select
                      defaultValue={field.required.toString()}
                      onChange={(event) =>
                        handleFieldChange(
                          index,
                          "required",
                          event.target.value === "true"
                        )
                      }
                      className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                      required
                    >
                      <option value="" disabled>
                        Choose a Option
                      </option>
                      <option value="true">Required: Yes</option>
                      <option value="false">Required: No</option>
                    </select>
                  </div>

                  <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                  <div className="w-full 800px:w-[25%]">
                    <select
                      defaultValue={field.status.toString()}
                      onChange={(event) =>
                        handleFieldChange(
                          index,
                          "status",
                          event.target.value === "true"
                        )
                      }
                      className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                      required
                    >
                      <option value="" disabled>
                        Choose a Option
                      </option>
                      <option value="true">Stauts: ON</option>
                      <option value="false">Stauts: OFF</option>
                    </select>
                  </div>

                  <div>
                    {fields.length !== 1 && (
                      <button
                        type="button"
                        className="mr-2 ml-2 mt-2 pb-1 text-[10px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                        onClick={() => handleRemoveField(field.id)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {/* Select Form Data */}

            {fields.map((field, index) => (
              <div key={index}>
                {field.type === "select" && (
                  <>
                    <div>
                      <label className="pb-2">
                        Select Form <span className="text-red-500">*</span>
                      </label>
                      {field.options.map((option, optionIndex) => (
                        <div
                          key={option.id}
                          className="w-full 800px:flex items-center"
                        >
                          {field.options.length - 1 === optionIndex && (
                            <button
                              type="button"
                              className="mr-2 mt-2 pb-1 text-[12px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                              onClick={() => handleAddSelectOption(index)}
                            >
                              Add
                            </button>
                          )}

                          <div className="w-full 800px:w-[50%]">
                            <input
                              type="text"
                              defaultValue={option.name}
                              onChange={(event) =>
                                handleSelectOptionChange(
                                  index,
                                  optionIndex,
                                  "name",
                                  event.target.value
                                )
                              }
                              required
                              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Enter a Select Option...."
                            />
                          </div>

                          <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                          <div className="w-full 800px:w-[50%]">
                            <input
                              type="text"
                              defaultValue={option.value}
                              onChange={(event) =>
                                handleSelectOptionChange(
                                  index,
                                  optionIndex,
                                  "value",
                                  event.target.value
                                )
                              }
                              required
                              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Enter a Select Value...."
                            />
                          </div>

                          <div>
                            {field.options.length !== 1 && (
                              <button
                                type="button"
                                className="mr-2 ml-2 mt-2 pb-1 text-[10px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                                onClick={() =>
                                  handleRemoveSelectOption(index, option.id)
                                }
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Gap */}
                    <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>
                  </>
                )}
              </div>
            ))}

            {/*  Quick Product Description */}
            <br />
            <div>
              <label className="pb-2">
                Quick Description <span className="text-red-500">*</span>
              </label>
              <Editor
                editorState={quickDescEditorState}
                onEditorStateChange={handleQuickDescEditorChange}
                wrapperClassName="mt-2 appearance-none block pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                editorClassName="p-2 cursor-text"
                editorStyle={{ overflowY: "auto", height: 200 + "px" }}
                wrapperStyle={{ height: 280 + "px" }}
              />
            </div>
            <br />
            {/* Product Category */}
            <div>
              <label className="pb-2">
                Category <span className="text-red-500">*</span>
              </label>
              <select
                className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {categoriesData &&
                  categoriesData.map((i) => (
                    <option value={i.title} key={i.title}>
                      {i.title}
                    </option>
                  ))}
              </select>
            </div>
            {/* Add Button Data For Purchases */}
            <br />
            <div>
              <label className="pb-2">
                Add Button Data For Purchases{" "}
                <span className="text-red-500">*</span>
              </label>
              {originalPrice.map((btnData, index) => {
                return (
                  <div
                    key={btnData.id}
                    className="w-full 800px:flex items-center"
                  >
                    {originalPrice.length - 1 === index && (
                      <button
                        type="button"
                        className="mr-2 mt-2 pb-1 text-[12px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                        onClick={handleAddClick}
                      >
                        Add
                      </button>
                    )}
                    <div className="w-full 800px:w-[50%]">
                      <input
                        type="text"
                        name="cost"
                        defaultValue={btnData.cost}
                        onChange={(event) =>
                          handlePriceFieldData(
                            index,
                            "cost",
                            event.target.value
                          )
                        }
                        required
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="Enter your product costs."
                      />
                    </div>
                    {/* Gap */}
                    <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                    <div className="w-full 800px:w-[20%]">
                      <input
                        type="text"
                        name="sku"
                        defaultValue={btnData.sku}
                        onChange={(event) =>
                          handlePriceFieldData(index, "sku", event.target.value)
                        }
                        required
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="SKU CODE...."
                      />
                    </div>

                    <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                    <div className="w-full 800px:w-[15%]">
                      <input
                        type="number"
                        name="currencyPrice"
                        defaultValue={btnData.currencyPrice}
                        onChange={(event) =>
                          handlePriceFieldData(
                            index,
                            "currencyPrice",
                            event.target.value
                          )
                        }
                        onWheel={(e) => e.target.blur()}
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="Currency Price...."
                      />
                    </div>

                    <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                    <div className="w-full 800px:w-[20%]">
                      <select
                        defaultValue={btnData.currency}
                        onChange={(event) =>
                          handlePriceFieldData(
                            index,
                            "currency",
                            event.target.value
                          )
                        }
                        className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                        required
                      >
                        <option value="" disabled>
                          Choose a Currency
                        </option>
                        {countryISOCurrencyCode &&
                          countryISOCurrencyCode.map((i) => (
                            <option value={i.code} key={i.key}>
                              {i.code}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div>
                      {originalPrice.length !== 1 && (
                        <button
                          type="button"
                          className="mr-2 ml-2 mt-2 pb-1 text-[10px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                          onClick={() => handleRemoveClick(btnData.id)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Product Tags */}
            <br />
            <div>
              <label className="pb-2">Tags</label>
              <input
                type="text"
                name="tags"
                defaultValue={tags}
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={(e) => setTags(e.target.value)}
                placeholder="Enter your product tags..."
              />
            </div>
            {/* Product Stock */}
            <br />
            <div>
              <label className="pb-2">
                Product Stock <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="price"
                defaultValue={stock}
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={(e) => setStock(e.target.value)}
                placeholder="Enter your product stock..."
              />
            </div>
            <br />
            <div className="w-full 800px:flex items-center">
              {/* Product Price With Discount */}
              <div className="w-full 800px:w-[20%]">
                <label className="pb-2">Discount Percent</label>
                <input
                  type="number"
                  name="price"
                  defaultValue={discountPrice}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setDiscountPrice(e.target.value)}
                  placeholder="Enter your product price with discount..."
                />
              </div>
              <div className="w-full 800px:w-[3%]"></div>
              {/* Feature or Not Options */}
              <div className="w-full 800px:w-[24%]">
                <label className="pb-2">
                  Featured <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                  value={featured}
                  onChange={(e) => setFeatured(e.target.value)}
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div className="w-full 800px:w-[3%]"></div>
              {/* Sale or Not Options */}
              <div className="w-full 800px:w-[24%]">
                <label className="pb-2">
                  Sale <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                  value={sales}
                  onChange={(e) => setSales(e.target.value)}
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div className="w-full 800px:w-[3%]"></div>
              <div className="w-full 800px:w-[24%]">
                <label className="pb-2">
                  Status <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="ON">ON</option>
                  <option value="OFF">OFF</option>
                </select>
              </div>
            </div>
            {/* Product Images */}
            <br />
            <div>
              <label className="pb-2">
                Upload Images <span className="text-red-500">*</span>
              </label>
              <input
                type="file"
                name=""
                id="upload"
                className="hidden"
                multiple
                onChange={handleImageChange}
              />
              <div className="w-full flex items-center flex-wrap">
                <label htmlFor="upload">
                  <AiOutlinePlusCircle
                    size={30}
                    className="mt-3 cursor-pointer"
                    color="#555"
                  />
                </label>
                {images.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={
                        typeof image === "string"
                          ? `${backend_url}${image}`
                          : URL.createObjectURL(image)
                      }
                      alt={`product-${index}`}
                      className="h-[120px] w-[120px] object-cover m-2 rounded-md"
                    />
                    <button
                      type="button"
                      className="absolute top-0 right-0 mt-1 mr-1 text-white bg-red-500 p-1 rounded-full"
                      onClick={() => handleDeleteImage(image)}
                    >
                      <AiOutlineMinusCircle size={20} />
                    </button>
                  </div>
                ))}
              </div>
              <div className="text-xs text-gray-500 italic font-bold">
                Note: Please Delete The Previous Image (Otherwise it will
                auto-delete old image if you upload new one) and, upload Only
                One Image.
              </div>
              <div>
                <button
                  type="submit"
                  className={`${
                    isLoading
                      ? "cursor-not-allowed bg-gray-400"
                      : "bg-black hover:bg-[#1B1C21]"
                  } w-full mt-2 py-2 px-3 border border-transparent rounded-md shadow-lg text-sm font-medium text-white uppercase focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  disabled={isLoading}
                >
                  {isLoading ? "Editing..." : "Edit Product"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
