import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const orderReducer = createReducer(initialState, {
  // Get All Orders of User
  getAllUserOrdersRequest: (state) => {
    state.isLoading = true;
  },
  getAllUserOrdersSuccess: (state, action) => {
    state.isLoading = false;
    state.orders = action.payload;
  },
  getAllUserOrdersFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Get All Orders of Shop
  getAllShopOrdersRequest: (state) => {
    state.isLoading = true;
  },
  getAllShopOrdersSuccess: (state, action) => {
    state.isLoading = false;
    state.orders = action.payload;
  },
  getAllShopOrdersFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Get All Orders For Admin
  adminAllOrdersRequest: (state) => {
    state.adminOrderLoading = true;
  },
  adminAllOrdersSuccess: (state, action) => {
    state.adminOrderLoading = false;
    state.adminOrders = action.payload;
  },
  adminAllOrdersFailed: (state, action) => {
    state.adminOrderLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
