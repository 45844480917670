import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import UserHelmet from "../../helmet/userHelmet";

const TrackOrder = () => {
  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch]);

  const data = orders && orders.find((item) => item._id === id);

  if (!data) {
    window.location.href = "/404";
  }

  return (
    <UserHelmet title={user?.name} page={data?.status}>
      <div className="w-full h-[80vh] flex justify-center items-center">
        {" "}
        <>
          {data && data?.status === "Processing" ? (
            <h1 className="text-[20px]">
              Your Order is currently being proccessed by our shop.
            </h1>
          ) : data?.status === "Payment On Hold" ? (
            <h1 className="text-[20px]">
              Your payment is currently on hold either due to fraud detection
              system being alert or problem with payment api. Please keep on
              checking
            </h1>
          ) : data?.status === "Info Incorrect" ? (
            <h1 className="text-[20px]">
              Your provided Info is incorrect. Please Contact us via Send
              Message button{" "}
            </h1>
          ) : data?.status === "Transferred to Merchant API Delivery System" ? (
            <h1 className="text-[20px]">
              Your order is Transferred to our delivery system and soon will be
              delivered.
            </h1>
          ) : data?.status === "Delivering" ? (
            <h1 className="text-[20px]">
              Your order is being delivered. Hold tight and wait.
            </h1>
          ) : data?.status === "Delivered" ? (
            <h1 className="text-[20px]">
              Your order has been delivered! If you notice some mistake don't
              hesitate to message us.
            </h1>
          ) : data?.status === "Cancelled" ? (
            <h1 className="text-[20px]">
              Your order has been cancelled! If your fund has been deducted,
              contact us!
            </h1>
          ) : data?.status === "Initiating Refund" ? (
            <h1 className="text-[20px]">
              We have received your Refund request. We will contact you shortly
              for verifying and moving forward with it.
            </h1>
          ) : data?.status === "Refund Success" ? (
            <h1 className="text-[20px]">Your Refund has been success!</h1>
          ) : data?.status === "Refund Refused" ? (
            <h1 className="text-[20px]">Your Refund has been refused!</h1>
          ) : null}
        </>
      </div>
    </UserHelmet>
  );
};

export default TrackOrder;
