import React, { useEffect } from "react";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/failure.json";
import Helmet from "../../helmet/helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";

const EsewaOrderFailurePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      var currentURL = document.URL;

      const esewaTransId = currentURL.split("?")[1].split("=")[1];

      if (!esewaTransId) {
        window.location.href = "/404";
      } else {
        const body = {
          transId: esewaTransId,
        };

        const fonepayInfo = axios
          .post(`${server}/payment/esewa/getInfo`, body)
          .then((res) => {
            const newBody = {
              status: res.data.esewaPayment.status,
              statusCode: res.data.esewaPayment.statusCode,
              transId: res.data.esewaPayment.transactionId,
              orderId: res.data.esewaPayment.orderId,
              TokenId: res.data.esewaPayment.token,
            };

            axios
              .post(`${server}/order/update-esewa-status`, newBody)
              .then((res) => {
                if (
                  res.data.order.paymentInfo.status === "Cancelled" ||
                  res.data.order.status === "Cancelled" ||
                  res.data.order.paymentInfo.status === "Error"
                ) {
                  setTimeout(() => {
                    navigate(`/user/order/${res.data.order._id}`);
                  }, 800);
                }
              });
          });
      }
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Helmet title="Order Failed">
      <div>
        <Header />
        <Failure />
        <Footer />
      </div>
    </Helmet>
  );
};

const Failure = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} width={300} height={300} />
      <h5 className="text-center mb-14 text-[25px] text-[#000000a1]">
        Your order has been denied or failed 😞
      </h5>
      <br />
      <br />
    </div>
  );
};

export default EsewaOrderFailurePage;
