import React, { useEffect } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import Helmet from "../helmet/helmet";

const CareersPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Helmet title="Career (Not Hiring!)">
      <div>
        <Header />
        <Careers />
        <Footer />
      </div>
    </Helmet>
  );
};

const Careers = () => {
  return (
    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Careers</h2>
      <span className="bg-red-500 text-[#E8E6E3] font-[600] text-[12px] leading-3 py-[3px] pl-[6px] pr-[6px]  ml-[2px] rounded-[9999px] whitespace-nowrap">
        Not Hiring
      </span>

      <h1 className="text-center w-full pb-[100px] text-[20px] pt-[100px]">
        Currently there are no open jobs!
      </h1>
    </div>
  );
};

export default CareersPage;
