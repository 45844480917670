import React, { useEffect } from "react";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/failure.json";
import Helmet from "../../helmet/helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";

const ImePayOrderFailurePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      var currentURL = document.URL;

      const encodedURL = currentURL.split("?")[1].split("=")[1];

      const decodedURL = atob(decodeURIComponent(encodedURL)).split("|");

      if (!encodedURL || !decodedURL) {
        window.location.href = "/404";
      } else {
        const body = {
          ResponseCode: decodedURL[0],
          ResponseDescription: decodedURL[1],
          Msisdn: decodedURL[2],
          TransactionId: decodedURL[3],
          RefId: decodedURL[4],
          TranAmount: decodedURL[5],
          TokenId: decodedURL[6],
        };

        axios
          .post(`${server}/payment/imepay/updateDatabase`, body)
          .then((res) => {
            if (res.status === 200) {
              const body = {
                MerchantCode: res.data.message.MerchantCode,
                Msisdn: res.data.message.Msisdn,
                RefId: res.data.message.RefId,
                TransactionId: res.data.message.TransactionId,
                TokenId: res.data.message.TokenId,
              };

              axios
                .post(`${server}/order/update-imepay-status`, body)
                .then((res) => {
                  if (
                    res.data.order.paymentInfo.status === "Failed" ||
                    res.data.order.status === "Cancelled"
                  ) {
                    setTimeout(() => {
                      navigate(`/user/order/${res.data.order._id}`);
                    }, 800);
                  }
                });
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Helmet title="Order Failed">
      <div>
        <Header />
        <Failure />
        <Footer />
      </div>
    </Helmet>
  );
};

const Failure = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} width={300} height={300} />
      <h5 className="text-center mb-14 text-[25px] text-[#000000a1]">
        Your order has been denied or failed 😞
      </h5>
      <br />
      <br />
    </div>
  );
};

export default ImePayOrderFailurePage;
