import React, { useEffect } from "react";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/failure.json";
import Helmet from "../../helmet/helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";

const MyPayOrderFailurePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      var currentURL = document.URL;

      const MerchantTransactionID = currentURL
        .split("?")[1]
        .split("&")[0]
        .split("=")[1];

      const orderID = currentURL.split("?")[1].split("&")[1].split("=")[1];

      if (!MerchantTransactionID || !orderID) {
        window.location.href = "/404";
      } else {
        const body = {
          MerchantTransactionID: MerchantTransactionID,
          orderID: orderID,
        };

        axios.post(`${server}/order/update-mypay-status`, body).then((res) => {
          if (
            res.data.order.paymentInfo.status === "Cancelled" ||
            res.data.order.paymentInfo.status === "Failed" ||
            res.data.order.paymentInfo.status === "Incomplete"
          ) {
            setTimeout(() => {
              navigate(`/user/order/${res.data.order._id}`);
            }, 800);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Helmet title="Order Failed">
      <div>
        <Header />
        <Failure />
        <Footer />
      </div>
    </Helmet>
  );
};

const Failure = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} width={300} height={300} />
      <h5 className="text-center mb-14 text-[25px] text-[#000000a1]">
        Your order has been denied or failed 😞
      </h5>
      <br />
      <br />
    </div>
  );
};

export default MyPayOrderFailurePage;
