// navigation Data
export const navItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Game Cards",
    url: "/game-cards",
  },
  {
    title: "Products",
    url: "/products",
  },
  {
    title: "Events",
    url: "/events",
  },
  {
    title: "FAQ",
    url: "/faq",
  },
];

// branding data
export const brandingData = [
  {
    id: 1,
    title: "Free Shipping",
    Description: "For all Digital Orders!",
    icon: (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1H5.63636V24.1818H35"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M8.72763 35.0002C10.4347 35.0002 11.8185 33.6163 11.8185 31.9093C11.8185 30.2022 10.4347 28.8184 8.72763 28.8184C7.02057 28.8184 5.63672 30.2022 5.63672 31.9093C5.63672 33.6163 7.02057 35.0002 8.72763 35.0002Z"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M31.9073 35.0002C33.6144 35.0002 34.9982 33.6163 34.9982 31.9093C34.9982 30.2022 33.6144 28.8184 31.9073 28.8184C30.2003 28.8184 28.8164 30.2022 28.8164 31.9093C28.8164 33.6163 30.2003 35.0002 31.9073 35.0002Z"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M34.9982 1H11.8164V18H34.9982V1Z"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M11.8164 7.18164H34.9982"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 2,
    title: "Daily Surprise Offers",
    Description: "Save up to 25% off",
    icon: (
      <svg
        width="32"
        height="34"
        viewBox="0 0 32 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 17.4502C31 25.7002 24.25 32.4502 16 32.4502C7.75 32.4502 1 25.7002 1 17.4502C1 9.2002 7.75 2.4502 16 2.4502C21.85 2.4502 26.95 5.7502 29.35 10.7002"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M30.7 2L29.5 10.85L20.5 9.65"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 4,
    title: "Affortable Prices",
    Description: "Get Factory direct price",
    icon: (
      <svg
        width="32"
        height="35"
        viewBox="0 0 32 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 13H5.5C2.95 13 1 11.05 1 8.5V1H7"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M25 13H26.5C29.05 13 31 11.05 31 8.5V1H25"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M16 28V22"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M16 22C11.05 22 7 17.95 7 13V1H25V13C25 17.95 20.95 22 16 22Z"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M25 34H7C7 30.7 9.7 28 13 28H19C22.3 28 25 30.7 25 34Z"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 5,
    title: "Secure Payments",
    Description: "100% protected payments",
    icon: (
      <svg
        width="32"
        height="38"
        viewBox="0 0 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6654 18.667H9.33203V27.0003H22.6654V18.667Z"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M12.668 18.6663V13.6663C12.668 11.833 14.168 10.333 16.0013 10.333C17.8346 10.333 19.3346 11.833 19.3346 13.6663V18.6663"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M31 22C31 30.3333 24.3333 37 16 37C7.66667 37 1 30.3333 1 22V5.33333L16 2L31 5.33333V22Z"
          stroke="#FFBB38"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
      </svg>
    ),
  },
];

// categories data
export const categoriesData = [
  {
    id: 1,
    title: "Gift Cards",
    subTitle: "",
    image_Url: require("../assets/images/CategoriesDataImages/categoriesData_GiftCards.png"),
  },
  {
    id: 2,
    title: "Enterntainment",
    subTitle: "",
    image_Url: require("../assets/images/CategoriesDataImages/categoriesData_EnterntainmentServicesGiftCard.png"),
  },
  {
    id: 3,
    title: "Subscriptions",
    subTitle: "",
    image_Url: require("../assets/images/CategoriesDataImages/categoriesData_Subscriptions.png"),
  },
  {
    id: 4,
    title: "PC Softwares & Games",
    subTitle: "",
    image_Url: require("../assets/images/CategoriesDataImages/categoriesData_Softwares.png"),
  },
  {
    id: 5,
    title: "Mobile/PC Games Top-Up",
    subTitle: "",
    image_Url: require("../assets/images/CategoriesDataImages/categoriesData_TopUp.png"),
  },
  {
    id: 6,
    title: "Razer Gold",
    subTitle: "",
    image_Url: require("../assets/images/CategoriesDataImages/categoriesData_RazerGold.png"),
  },
];

export const footerProductLinks = [
  {
    name: "About us",
    link: "/about",
  },
  {
    name: "Events",
    link: "/events",
  },
  {
    name: (
      <div>
        Careers -{" "}
        <span className="bg-red-500 text-[#E8E6E3] font-[600] text-[12px] leading-3 py-[3px] pl-[6px] pr-[6px]  ml-[2px] rounded-[9999px] whitespace-nowrap">
          Not Hiring!
        </span>
      </div>
    ),
    link: "/careers",
  },
  {
    name: "FAQ",
    link: "/faq",
  },
];

export const footercompanyLinks = [
  {
    name: "Gift Cards",
    link: "/products?category=Gift%20Cards",
  },
  {
    name: "Subscriptions",
    link: "/products?category=Subscriptions",
  },
  {
    name: "Top-Up",
    link: "/products?category=Mobile/PC%20Games%20Top-Up",
  },
  {
    name: "Razer Gold",
    link: "/products?category=Razer%20Gold",
  },
];

export const footerSupportLinks = [
  {
    name: "Contact Us",
    link: "/contact",
  },
  {
    name: "Live Chat",
    link: "/inbox",
  },
  {
    name: "Privacy & Policy",
    link: "/privacy",
  },
  {
    name: "Terms & Conditions",
    link: "/terms",
  },
];

export const currentAvailableBank = [
  {
    id: "nica",
    name: "NIC Asia Bank Limited",
    country: "Nepal",
  },
  {
    id: "gime",
    name: "Global Ime Bank Limited",
  },
  {
    id: "rbb",
    name: "Rastriya Banijya Bank Limited",
  },
];

export const currentAvailableCountry = [
  {
    id: "np",
    country: "Nepal",
  },
];

export const countryISOCurrencyCode = [
  {
    code: "AED",
    decimals: 2,
    name: "United Arab Emirates dirham",
    number: "784",
  },
  {
    code: "AFN",
    decimals: 2,
    name: "Afghan afghani",
    number: "971",
  },
  {
    code: "ALL",
    decimals: 2,
    name: "Albanian lek",
    number: "8",
  },
  {
    code: "AMD",
    decimals: 2,
    name: "Armenian dram",
    number: "51",
  },
  {
    code: "ANG",
    decimals: 2,
    name: "Netherlands Antillean guilder",
    number: "532",
  },
  {
    code: "AOA",
    decimals: 2,
    name: "Angolan kwanza",
    number: "973",
  },
  {
    code: "ARS",
    decimals: 2,
    name: "Argentine peso",
    number: "32",
  },
  {
    code: "AUD",
    decimals: 2,
    name: "Australian dollar",
    number: "36",
  },
  {
    code: "AWG",
    decimals: 2,
    name: "Aruban florin",
    number: "533",
  },
  {
    code: "AZN",
    decimals: 2,
    name: "Azerbaijani manat",
    number: "944",
  },
  {
    code: "BAM",
    decimals: 2,
    name: "Bosnia and Herzegovina convertible mark",
    number: "977",
  },
  {
    code: "BBD",
    decimals: 2,
    name: "Barbados dollar",
    number: "52",
  },
  {
    code: "BDT",
    decimals: 2,
    name: "Bangladeshi taka",
    number: "50",
  },
  {
    code: "BGN",
    decimals: 2,
    name: "Bulgarian lev",
    number: "975",
  },
  {
    code: "BHD",
    decimals: 3,
    name: "Bahraini dinar",
    number: "48",
  },
  {
    code: "BIF",
    decimals: 0,
    name: "Burundian franc",
    number: "108",
  },
  {
    code: "BMD",
    decimals: 2,
    name: "Bermudian dollar (customarily known as Bermuda dollar)",
    number: "60",
  },
  {
    code: "BND",
    decimals: 2,
    name: "Brunei dollar",
    number: "96",
  },
  {
    code: "BOB",
    decimals: 2,
    name: "Boliviano",
    number: "68",
  },
  {
    code: "BOV",
    decimals: 2,
    name: "Bolivian Mvdol (funds code)",
    number: "984",
  },
  {
    code: "BRL",
    decimals: 2,
    name: "Brazilian real",
    number: "986",
  },
  {
    code: "BSD",
    decimals: 2,
    name: "Bahamian dollar",
    number: "44",
  },
  {
    code: "BTN",
    decimals: 2,
    name: "Bhutanese ngultrum",
    number: "64",
  },
  {
    code: "BWP",
    decimals: 2,
    name: "Botswana pula",
    number: "72",
  },
  {
    code: "BYR",
    decimals: 0,
    name: "Belarusian ruble",
    number: "974",
  },
  {
    code: "BZD",
    decimals: 2,
    name: "Belize dollar",
    number: "84",
  },
  {
    code: "CAD",
    decimals: 2,
    name: "Canadian dollar",
    number: "124",
  },
  {
    code: "CDF",
    decimals: 2,
    name: "Congolese franc",
    number: "976",
  },
  {
    code: "CHE",
    decimals: 2,
    name: "WIR Euro (complementary currency)",
    number: "947",
  },
  {
    code: "CHF",
    decimals: 2,
    name: "Swiss franc",
    number: "756",
  },
  {
    code: "CHW",
    decimals: 2,
    name: "WIR Franc (complementary currency)",
    number: "948",
  },
  {
    code: "CLF",
    decimals: 0,
    name: "Unidad de Fomento (funds code)",
    number: "990",
  },
  {
    code: "CLP",
    decimals: 0,
    name: "Chilean peso",
    number: "152",
  },
  {
    code: "CNY",
    decimals: 2,
    name: "Chinese yuan",
    number: "156",
  },
  {
    code: "COP",
    decimals: 2,
    name: "Colombian peso",
    number: "170",
  },
  {
    code: "COU",
    decimals: 2,
    name: "Unidad de Valor Real",
    number: "970",
  },
  {
    code: "CRC",
    decimals: 2,
    name: "Costa Rican colon",
    number: "188",
  },
  {
    code: "CUC",
    decimals: 2,
    name: "Cuban convertible peso",
    number: "931",
  },
  {
    code: "CUP",
    decimals: 2,
    name: "Cuban peso",
    number: "192",
  },
  {
    code: "CVE",
    decimals: 0,
    name: "Cape Verde escudo",
    number: "132",
  },
  {
    code: "CZK",
    decimals: 2,
    name: "Czech koruna",
    number: "203",
  },
  {
    code: "DJF",
    decimals: 0,
    name: "Djiboutian franc",
    number: "262",
  },
  {
    code: "DKK",
    decimals: 2,
    name: "Danish krone",
    number: "208",
  },
  {
    code: "DOP",
    decimals: 2,
    name: "Dominican peso",
    number: "214",
  },
  {
    code: "DZD",
    decimals: 2,
    name: "Algerian dinar",
    number: "12",
  },
  {
    code: "EGP",
    decimals: 2,
    name: "Egyptian pound",
    number: "818",
  },
  {
    code: "ERN",
    decimals: 2,
    name: "Eritrean nakfa",
    number: "232",
  },
  {
    code: "ETB",
    decimals: 2,
    name: "Ethiopian birr",
    number: "230",
  },
  {
    code: "EUR",
    decimals: 2,
    name: "Euro",
    number: "978",
  },
  {
    code: "FJD",
    decimals: 2,
    name: "Fiji dollar",
    number: "242",
  },
  {
    code: "FKP",
    decimals: 2,
    name: "Falkland Islands pound",
    number: "238",
  },
  {
    code: "GBP",
    decimals: 2,
    name: "Pound sterling",
    number: "826",
  },
  {
    code: "GEL",
    decimals: 2,
    name: "Georgian lari",
    number: "981",
  },
  {
    code: "GHS",
    decimals: 2,
    name: "Ghanaian cedi",
    number: "936",
  },
  {
    code: "GIP",
    decimals: 2,
    name: "Gibraltar pound",
    number: "292",
  },
  {
    code: "GMD",
    decimals: 2,
    name: "Gambian dalasi",
    number: "270",
  },
  {
    code: "GNF",
    decimals: 0,
    name: "Guinean franc",
    number: "324",
  },
  {
    code: "GTQ",
    decimals: 2,
    name: "Guatemalan quetzal",
    number: "320",
  },
  {
    code: "GYD",
    decimals: 2,
    name: "Guyanese dollar",
    number: "328",
  },
  {
    code: "HKD",
    decimals: 2,
    name: "Hong Kong dollar",
    number: "344",
  },
  {
    code: "HNL",
    decimals: 2,
    name: "Honduran lempira",
    number: "340",
  },
  {
    code: "HRK",
    decimals: 2,
    name: "Croatian kuna",
    number: "191",
  },
  {
    code: "HTG",
    decimals: 2,
    name: "Haitian gourde",
    number: "332",
  },
  {
    code: "HUF",
    decimals: 2,
    name: "Hungarian forint",
    number: "348",
  },
  {
    code: "IDR",
    decimals: 2,
    name: "Indonesian rupiah",
    number: "360",
  },
  {
    code: "ILS",
    decimals: 2,
    name: "Israeli new shekel",
    number: "376",
  },
  {
    code: "INR",
    decimals: 2,
    name: "Indian rupee",
    number: "356",
  },
  {
    code: "IQD",
    decimals: 3,
    name: "Iraqi dinar",
    number: "368",
  },
  {
    code: "IRR",
    decimals: 0,
    name: "Iranian rial",
    number: "364",
  },
  {
    code: "ISK",
    decimals: 0,
    name: "Icelandic króna",
    number: "352",
  },
  {
    code: "JMD",
    decimals: 2,
    name: "Jamaican dollar",
    number: "388",
  },
  {
    code: "JOD",
    decimals: 3,
    name: "Jordanian dinar",
    number: "400",
  },
  {
    code: "JPY",
    decimals: 0,
    name: "Japanese yen",
    number: "392",
  },
  {
    code: "KES",
    decimals: 2,
    name: "Kenyan shilling",
    number: "404",
  },
  {
    code: "KGS",
    decimals: 2,
    name: "Kyrgyzstani som",
    number: "417",
  },
  {
    code: "KHR",
    decimals: 2,
    name: "Cambodian riel",
    number: "116",
  },
  {
    code: "KMF",
    decimals: 0,
    name: "Comoro franc",
    number: "174",
  },
  {
    code: "KPW",
    decimals: 0,
    name: "North Korean won",
    number: "408",
  },
  {
    code: "KRW",
    decimals: 0,
    name: "South Korean won",
    number: "410",
  },
  {
    code: "KWD",
    decimals: 3,
    name: "Kuwaiti dinar",
    number: "414",
  },
  {
    code: "KYD",
    decimals: 2,
    name: "Cayman Islands dollar",
    number: "136",
  },
  {
    code: "KZT",
    decimals: 2,
    name: "Kazakhstani tenge",
    number: "398",
  },
  {
    code: "LAK",
    decimals: 0,
    name: "Lao kip",
    number: "418",
  },
  {
    code: "LBP",
    decimals: 0,
    name: "Lebanese pound",
    number: "422",
  },
  {
    code: "LKR",
    decimals: 2,
    name: "Sri Lankan rupee",
    number: "144",
  },
  {
    code: "LRD",
    decimals: 2,
    name: "Liberian dollar",
    number: "430",
  },
  {
    code: "LSL",
    decimals: 2,
    name: "Lesotho loti",
    number: "426",
  },
  {
    code: "LTL",
    decimals: 2,
    name: "Lithuanian litas",
    number: "440",
  },
  {
    code: "LVL",
    decimals: 2,
    name: "Latvian lats",
    number: "428",
  },
  {
    code: "LYD",
    decimals: 3,
    name: "Libyan dinar",
    number: "434",
  },
  {
    code: "MAD",
    decimals: 2,
    name: "Moroccan dirham",
    number: "504",
  },
  {
    code: "MDL",
    decimals: 2,
    name: "Moldovan leu",
    number: "498",
  },
  {
    code: "MGA",
    decimals: 0,
    name: "Malagasy ariary",
    number: "969",
  },
  {
    code: "MKD",
    decimals: 0,
    name: "Macedonian denar",
    number: "807",
  },
  {
    code: "MMK",
    decimals: 0,
    name: "Myanma kyat",
    number: "104",
  },
  {
    code: "MNT",
    decimals: 2,
    name: "Mongolian tugrik",
    number: "496",
  },
  {
    code: "MOP",
    decimals: 2,
    name: "Macanese pataca",
    number: "446",
  },
  {
    code: "MRO",
    decimals: 0,
    name: "Mauritanian ouguiya",
    number: "478",
  },
  {
    code: "MUR",
    decimals: 2,
    name: "Mauritian rupee",
    number: "480",
  },
  {
    code: "MVR",
    decimals: 2,
    name: "Maldivian rufiyaa",
    number: "462",
  },
  {
    code: "MWK",
    decimals: 2,
    name: "Malawian kwacha",
    number: "454",
  },
  {
    code: "MXN",
    decimals: 2,
    name: "Mexican peso",
    number: "484",
  },
  {
    code: "MXV",
    decimals: 2,
    name: "Mexican Unidad de Inversion (UDI) (funds code)",
    number: "979",
  },
  {
    code: "MYR",
    decimals: 2,
    name: "Malaysian ringgit",
    number: "458",
  },
  {
    code: "MZN",
    decimals: 2,
    name: "Mozambican metical",
    number: "943",
  },
  {
    code: "NAD",
    decimals: 2,
    name: "Namibian dollar",
    number: "516",
  },
  {
    code: "NGN",
    decimals: 2,
    name: "Nigerian naira",
    number: "566",
  },
  {
    code: "NIO",
    decimals: 2,
    name: "Nicaraguan córdoba",
    number: "558",
  },
  {
    code: "NOK",
    decimals: 2,
    name: "Norwegian krone",
    number: "578",
  },
  {
    code: "NPR",
    decimals: 2,
    name: "Nepalese rupee",
    number: "524",
  },
  {
    code: "NZD",
    decimals: 2,
    name: "New Zealand dollar",
    number: "554",
  },
  {
    code: "OMR",
    decimals: 3,
    name: "Omani rial",
    number: "512",
  },
  {
    code: "PAB",
    decimals: 2,
    name: "Panamanian balboa",
    number: "590",
  },
  {
    code: "PEN",
    decimals: 2,
    name: "Peruvian nuevo sol",
    number: "604",
  },
  {
    code: "PGK",
    decimals: 2,
    name: "Papua New Guinean kina",
    number: "598",
  },
  {
    code: "PHP",
    decimals: 2,
    name: "Philippine peso",
    number: "608",
  },
  {
    code: "PKR",
    decimals: 2,
    name: "Pakistani rupee",
    number: "586",
  },
  {
    code: "PLN",
    decimals: 2,
    name: "Polish złoty",
    number: "985",
  },
  {
    code: "PYG",
    decimals: 0,
    name: "Paraguayan guaraní",
    number: "600",
  },
  {
    code: "QAR",
    decimals: 2,
    name: "Qatari riyal",
    number: "634",
  },
  {
    code: "RON",
    decimals: 2,
    name: "Romanian new leu",
    number: "946",
  },
  {
    code: "RSD",
    decimals: 2,
    name: "Serbian dinar",
    number: "941",
  },
  {
    code: "RUB",
    decimals: 2,
    name: "Russian rouble",
    number: "643",
  },
  {
    code: "RWF",
    decimals: 0,
    name: "Rwandan franc",
    number: "646",
  },
  {
    code: "SAR",
    decimals: 2,
    name: "Saudi riyal",
    number: "682",
  },
  {
    code: "SBD",
    decimals: 2,
    name: "Solomon Islands dollar",
    number: "90",
  },
  {
    code: "SCR",
    decimals: 2,
    name: "Seychelles rupee",
    number: "690",
  },
  {
    code: "SDG",
    decimals: 2,
    name: "Sudanese pound",
    number: "938",
  },
  {
    code: "SEK",
    decimals: 2,
    name: "Swedish krona/kronor",
    number: "752",
  },
  {
    code: "SGD",
    decimals: 2,
    name: "Singapore dollar",
    number: "702",
  },
  {
    code: "SHP",
    decimals: 2,
    name: "Saint Helena pound",
    number: "654",
  },
  {
    code: "SLL",
    decimals: 0,
    name: "Sierra Leonean leone",
    number: "694",
  },
  {
    code: "SOS",
    decimals: 2,
    name: "Somali shilling",
    number: "706",
  },
  {
    code: "SRD",
    decimals: 2,
    name: "Surinamese dollar",
    number: "968",
  },
  {
    code: "SSP",
    decimals: 2,
    name: "South Sudanese pound",
    number: "728",
  },
  {
    code: "STD",
    decimals: 0,
    name: "São Tomé and Príncipe dobra",
    number: "678",
  },
  {
    code: "SYP",
    decimals: 2,
    name: "Syrian pound",
    number: "760",
  },
  {
    code: "SZL",
    decimals: 2,
    name: "Swazi lilangeni",
    number: "748",
  },
  {
    code: "THB",
    decimals: 2,
    name: "Thai baht",
    number: "764",
  },
  {
    code: "TJS",
    decimals: 2,
    name: "Tajikistani somoni",
    number: "972",
  },
  {
    code: "TMT",
    decimals: 2,
    name: "Turkmenistani manat",
    number: "934",
  },
  {
    code: "TND",
    decimals: 3,
    name: "Tunisian dinar",
    number: "788",
  },
  {
    code: "TOP",
    decimals: 2,
    name: "Tongan paʻanga",
    number: "776",
  },
  {
    code: "TRY",
    decimals: 2,
    name: "Turkish lira",
    number: "949",
  },
  {
    code: "TTD",
    decimals: 2,
    name: "Trinidad and Tobago dollar",
    number: "780",
  },
  {
    code: "TWD",
    decimals: 2,
    name: "New Taiwan dollar",
    number: "901",
  },
  {
    code: "TZS",
    decimals: 2,
    name: "Tanzanian shilling",
    number: "834",
  },
  {
    code: "UAH",
    decimals: 2,
    name: "Ukrainian hryvnia",
    number: "980",
  },
  {
    code: "UGX",
    decimals: 2,
    name: "Ugandan shilling",
    number: "800",
  },
  {
    code: "USD",
    decimals: 2,
    name: "United States dollar",
    number: "840",
  },
  {
    code: "USN",
    decimals: 2,
    name: "United States dollar (next day) (funds code)",
    number: "997",
  },
  {
    code: "USS",
    decimals: 2,
    name: "United States dollar (same day) (funds code) (one source[who?] claims it is no longer used, but it is still on the ISO 4217-MA list)",
    number: "998",
  },
  {
    code: "UYI",
    decimals: 0,
    name: "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)",
    number: "940",
  },
  {
    code: "UYU",
    decimals: 2,
    name: "Uruguayan peso",
    number: "858",
  },
  {
    code: "UZS",
    decimals: 2,
    name: "Uzbekistan som",
    number: "860",
  },
  {
    code: "VEF",
    decimals: 2,
    name: "Venezuelan bolívar fuerte",
    number: "937",
  },
  {
    code: "VND",
    decimals: 0,
    name: "Vietnamese dong",
    number: "704",
  },
  {
    code: "VUV",
    decimals: 0,
    name: "Vanuatu vatu",
    number: "548",
  },
  {
    code: "WST",
    decimals: 2,
    name: "Samoan tala",
    number: "882",
  },
  {
    code: "XAF",
    decimals: 0,
    name: "CFA franc BEAC",
    number: "950",
  },
  {
    code: "XAG",
    decimals: null,
    name: "Silver (one troy ounce)",
    number: "961",
  },
  {
    code: "XAU",
    decimals: null,
    name: "Gold (one troy ounce)",
    number: "959",
  },
  {
    code: "XBA",
    decimals: null,
    name: "European Composite Unit (EURCO) (bond market unit)",
    number: "955",
  },
  {
    code: "XBB",
    decimals: null,
    name: "European Monetary Unit (E.M.U.-6) (bond market unit)",
    number: "956",
  },
  {
    code: "XBC",
    decimals: null,
    name: "European Unit of Account 9 (E.U.A.-9) (bond market unit)",
    number: "957",
  },
  {
    code: "XBD",
    decimals: null,
    name: "European Unit of Account 17 (E.U.A.-17) (bond market unit)",
    number: "958",
  },
  {
    code: "XCD",
    decimals: 2,
    name: "East Caribbean dollar",
    number: "951",
  },
  {
    code: "XDR",
    decimals: null,
    name: "Special drawing rights",
    number: "960",
  },
  {
    code: "XFU",
    decimals: null,
    name: "UIC franc (special settlement currency)",
    number: "Nil",
  },
  {
    code: "XOF",
    decimals: 0,
    name: "CFA franc BCEAO",
    number: "952",
  },
  {
    code: "XPD",
    decimals: null,
    name: "Palladium (one troy ounce)",
    number: "964",
  },
  {
    code: "XPF",
    decimals: 0,
    name: "CFP franc",
    number: "953",
  },
  {
    code: "XPT",
    decimals: null,
    name: "Platinum (one troy ounce)",
    number: "962",
  },
  {
    code: "XTS",
    decimals: null,
    name: "Code reserved for testing purposes",
    number: "963",
  },
  {
    code: "XXX",
    decimals: null,
    name: "No currency",
    number: "999",
  },
  {
    code: "YER",
    decimals: 2,
    name: "Yemeni rial",
    number: "886",
  },
  {
    code: "ZAR",
    decimals: 2,
    name: "South African rand",
    number: "710",
  },
  {
    code: "ZMW",
    decimals: 2,
    name: "Zambian kwacha",
    number: "967",
  },
];

export const countryPhoneCodes = [
  {
    name: "Afghanistan",
    dial_code: "93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: " 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "1670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "263",
    code: "ZW",
  },
];
