import React, { useEffect, useState } from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { createProduct } from "../../redux/actions/product";
import { categoriesData } from "../../static/data";
import { v4 as uuid4 } from "uuid";
import { countryISOCurrencyCode } from "../../static/data";
import { showToastError, showToastSuccess } from "../../static/toastMessage";
import ShopHelmet from "../../helmet/shopHelmet";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [quickDescEditorState, setQuickDescEditorState] = useState(
    EditorState.createEmpty()
  );

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [featured, setFeatured] = useState("");
  const [sales, setSales] = useState("");
  const [status, setStatus] = useState("");
  const [tags, setTags] = useState("");
  const [playStoreLink, setPlayStoreLink] = useState("");
  const [appStoreLink, setAppStoreLink] = useState("");
  const [discountPrice, setDiscountPrice] = useState("0");
  const [stock, setStock] = useState();

  const [originalPrice, setOriginalPrice] = useState([
    {
      cost: "",
      sku: "",
      currency: "USD",
      currencyPrice: "",
      id: uuid4(),
    },
  ]);

  const [fields, setFields] = useState([
    {
      placeholder: "",
      name: "",
      type: "number",
      options: [{ name: "", value: "", id: uuid4() }],
      required: "",
      status: "",
      id: uuid4(),
    },
  ]);

  // Handle Add Click
  const handleAddClick = () => {
    setOriginalPrice([
      ...originalPrice,
      {
        cost: "",
        sku: "",
        currency: "USD",
        currencyPrice: "",
        id: uuid4(),
      },
    ]);
  };

  // Handle Remove
  const handleRemoveClick = (id: string) => {
    let _originalPrice = [...originalPrice];
    _originalPrice = _originalPrice.filter((btnData) => btnData.id !== id);
    setOriginalPrice(_originalPrice);
  };

  // Handle Product Data Price Change
  const handlePriceFieldData = (index, fieldKey, value) => {
    const updatedPriceFields = [...originalPrice];
    updatedPriceFields[index][fieldKey] = value;
    setOriginalPrice(updatedPriceFields);
  };

  // Handle Add User Data Field
  const handleAddField = () => {
    setFields([
      ...fields,
      {
        placeholder: "",
        name: "",
        type: "number",
        options: [{ name: "", value: "" }],
        required: "",
        status: "",
        id: uuid4(),
      },
    ]);
  };

  // Remove User Form Data Field(s)
  const handleRemoveField = (id: string) => {
    let _updatedFields = [...fields];
    _updatedFields = _updatedFields.filter((field) => field.id !== id);
    setFields(_updatedFields);
  };

  // Handle User Form Data Price Change
  const handleFieldChange = (index, fieldKey, value) => {
    const updatedFields = [...fields];
    updatedFields[index][fieldKey] = value;

    if (fieldKey === "options") {
      const options = JSON.parse(value);
      updatedFields[index][fieldKey] = options;
    }

    setFields(updatedFields);
  };

  // Handle Select Option of User Data
  const handleAddSelectOption = (fieldIndex) => {
    let updatedFields = [...fields];
    updatedFields[fieldIndex].options.push({
      name: "",
      value: "",
      id: uuid4(),
    });
    setFields(updatedFields);
  };

  const handleRemoveSelectOption = (fieldIndex, optionId) => {
    const updatedFields = [...fields];
    const field = updatedFields[fieldIndex];
    if (field && field.options) {
      field.options = field.options.filter((option) => option.id !== optionId);
      setFields(updatedFields);
    }
  };

  const handleSelectOptionChange = (
    fieldIndex,
    optionIndex,
    optionKey,
    value
  ) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].options[optionIndex][optionKey] = value;
    setFields(updatedFields);
  };

  // Handle Description Editor Change
  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  // Handle Description Editor Change for Quick Description
  const handleQuickDescEditorChange = (newQuickDescEditorState) => {
    setQuickDescEditorState(newQuickDescEditorState);
  };

  useEffect(() => {
    if (error) {
      showToastError(error);
    }
    if (success) {
      showToastSuccess("Product created successfully!");
      navigate("/dashboard");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleDeleteImage = (i) => {
    const updatedImages = [...images];
    updatedImages.splice(i, 1);
    setImages(updatedImages);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();

    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    const quickDescContentState = quickDescEditorState.getCurrentContent();
    const quickDescRawContent = convertToRaw(quickDescContentState);

    if (images.length === 0) {
      showToastError("Please select at least one image.");
    } else {
      images.forEach((image) => {
        newForm.append("images", image);
      });
    }

    originalPrice.forEach((data) => {
      newForm.append("originalPrice", data.cost);
    });

    fields.forEach((field, index) => {
      newForm.append(`formFields[${index}][type]`, field.type);
      newForm.append(`formFields[${index}][name]`, field.name);
      newForm.append(`formFields[${index}][placeholder]`, field.placeholder);
      newForm.append(`formFields[${index}][required]`, field.required);
      newForm.append(`formFields[${index}][status]`, field.status);

      field.options.forEach((option, optionIndex) => {
        newForm.append(
          `formFields[${index}][options][${optionIndex}][name]`,
          option.name
        );
        newForm.append(
          `formFields[${index}][options][${optionIndex}][value]`,
          option.value
        );
      });
    });

    originalPrice.forEach((price, index) => {
      newForm.append(`priceData[${index}][cost]`, price.cost);
      newForm.append(`priceData[${index}][sku]`, price.sku);
      newForm.append(`priceData[${index}][currency]`, price.currency);
      newForm.append(`priceData[${index}][currencyPrice]`, price.currencyPrice);
    });

    newForm.append("name", name);
    newForm.append("quickDescription", JSON.stringify(quickDescRawContent));
    newForm.append("description", JSON.stringify(rawContent));
    newForm.append("category", category);
    newForm.append("featured", featured);
    newForm.append("sales", sales);
    newForm.append("status", status);
    newForm.append("playStoreLink", playStoreLink);
    newForm.append("appStoreLink", appStoreLink);
    newForm.append("tags", tags);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);
    newForm.append("shopId", seller._id);
    dispatch(createProduct(newForm));
  };

  return (
    <ShopHelmet title={seller.name} page="Create Products">
      <div className="w-[90%] 800px:w-[90%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-auto">
        <h5 className="text-[30px] font-Poppins text-center">Create Product</h5>
        {/* Create Product Form */}
        <form onSubmit={handleSubmit}>
          <br />
          {/* Product Name */}
          <div>
            <label className="pb-2">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={name}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your product name..."
            />
          </div>
          <br />
          {/* Link */}
          <div className="w-full 800px:flex items-center">
            {/* Play Store Link */}
            <div className="w-full 800px:w-[49%]">
              <label className="pb-2">Play Store Link</label>
              <input
                type="text"
                name="playStoreLink"
                value={playStoreLink}
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={(e) => setPlayStoreLink(e.target.value)}
                placeholder="Enter play store link....."
              />
            </div>

            {/* Gap */}
            <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

            {/* App Store Link */}
            <div className="w-full 800px:w-[49%]">
              <label className="pb-2">Apple Store Link</label>
              <input
                type="text"
                name="appStoreLink"
                value={appStoreLink}
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={(e) => setAppStoreLink(e.target.value)}
                placeholder="Enter app store link....."
              />
            </div>
          </div>
          {/* Product Description */}
          <br />
          <div>
            <label className="pb-2">
              Description <span className="text-red-500">*</span>
            </label>
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="mt-2 appearance-none block pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              editorClassName="p-2"
              editorStyle={{ overflowY: "auto", height: 200 + "px" }}
              wrapperStyle={{ height: 280 + "px" }}
            />
          </div>
          <br />
          {/* User Form Data */}
          <div>
            <label className="pb-2">
              User Form <span className="text-red-500">*</span>
            </label>
            {fields.map((field, index) => (
              <div key={index} className="w-full 800px:flex items-center">
                {fields.length - 1 === index && (
                  <button
                    type="button"
                    className="mr-2 mt-2 pb-1 text-[12px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                    onClick={() => handleAddField(field.id)}
                  >
                    Add
                  </button>
                )}
                <div className="w-full 800px:w-[25%]">
                  <input
                    type="text"
                    value={field.placeholder}
                    onChange={(event) =>
                      handleFieldChange(
                        index,
                        "placeholder",
                        event.target.value
                      )
                    }
                    required
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="Enter a placeholder field...."
                  />
                </div>
                {/* Gap */}
                <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                <div className="w-full 800px:w-[25%]">
                  <input
                    type="text"
                    value={field.name}
                    onChange={(event) =>
                      handleFieldChange(index, "name", event.target.value)
                    }
                    required
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="Enter a field name...."
                  />
                </div>

                {/* Gap */}
                <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                <div className="w-full 800px:w-[25%]">
                  <select
                    value={field.type}
                    onChange={(event) =>
                      handleFieldChange(index, "type", event.target.value)
                    }
                    required
                    className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                    required
                  >
                    <option value="" disabled>
                      Choose a Option
                    </option>
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="select">Select</option>
                    <option value="email">Email</option>
                    <option value="checkbox">Checkbox</option>
                    <option value="file">File Upload</option>
                  </select>
                </div>

                {/* Gap */}
                <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                <div className="w-full 800px:w-[25%]">
                  <select
                    value={field.required.toString()}
                    onChange={(event) =>
                      handleFieldChange(
                        index,
                        "required",
                        event.target.value === "true"
                      )
                    }
                    className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                    required
                  >
                    <option value="" disabled>
                      Choose a Option
                    </option>
                    <option value="true">Required: Yes</option>
                    <option value="false">Required: No</option>
                  </select>
                </div>

                <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                <div className="w-full 800px:w-[25%]">
                  <select
                    value={field.status.toString()}
                    onChange={(event) =>
                      handleFieldChange(
                        index,
                        "status",
                        event.target.value === "true"
                      )
                    }
                    className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                    required
                  >
                    <option value="" disabled>
                      Choose a Option
                    </option>
                    <option value="true">Status: ON</option>
                    <option value="false">Status: OFF</option>
                  </select>
                </div>

                <div>
                  {fields.length !== 1 && (
                    <button
                      type="button"
                      className="mr-2 ml-2 mt-2 pb-1 text-[10px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                      onClick={() => handleRemoveField(field.id)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* Select Form Data */}

          {fields.map((field, index) => (
            <div key={index}>
              {field.type === "select" && (
                <>
                  <div>
                    <label className="pb-2">
                      Select Form <span className="text-red-500">*</span>
                    </label>
                    {field.options.map((option, optionIndex) => (
                      <div
                        key={optionIndex}
                        className="w-full 800px:flex items-center"
                      >
                        {field.options.length - 1 === optionIndex && (
                          <button
                            type="button"
                            className="mr-2 mt-2 pb-1 text-[12px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                            onClick={() => handleAddSelectOption(index)}
                          >
                            Add
                          </button>
                        )}

                        <div className="w-full 800px:w-[50%]">
                          <input
                            type="text"
                            value={option.name}
                            onChange={(event) =>
                              handleSelectOptionChange(
                                index,
                                optionIndex,
                                "name",
                                event.target.value
                              )
                            }
                            required
                            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="Enter a Select Option...."
                          />
                        </div>

                        <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                        <div className="w-full 800px:w-[50%]">
                          <input
                            type="text"
                            value={option.value}
                            onChange={(event) =>
                              handleSelectOptionChange(
                                index,
                                optionIndex,
                                "value",
                                event.target.value
                              )
                            }
                            required
                            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="Enter a Select Value...."
                          />
                        </div>

                        <div>
                          {field.options.length !== 1 && (
                            <button
                              type="button"
                              className="mr-2 ml-2 mt-2 pb-1 text-[10px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                              onClick={() =>
                                handleRemoveSelectOption(index, option.id)
                              }
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Gap */}
                  <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>
                </>
              )}
            </div>
          ))}

          {/*  Quick Product Description */}
          <br />
          <div>
            <label className="pb-2">
              Quick Description <span className="text-red-500">*</span>
            </label>
            <Editor
              editorState={quickDescEditorState}
              onEditorStateChange={handleQuickDescEditorChange}
              wrapperClassName="mt-2 appearance-none block pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              editorClassName="p-2"
              editorStyle={{ overflowY: "auto", height: 200 + "px" }}
              wrapperStyle={{ height: 280 + "px" }}
            />
          </div>
          {/* Product Category */}
          <br />
          <div>
            <label className="pb-2">
              Category <span className="text-red-500">*</span>
            </label>
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="" disabled>
                Choose a category
              </option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>
          {/* Add Button Data For Purchases */}
          <br />
          <div>
            <label className="pb-2">
              Add Button Data For Purchases{" "}
              <span className="text-red-500">*</span>
            </label>
            {originalPrice.map((btnData, index) => {
              return (
                <div
                  key={btnData.id}
                  className="w-full 800px:flex items-center"
                >
                  {originalPrice.length - 1 === index && (
                    <button
                      type="button"
                      className="mr-2 mt-2 pb-1 text-[12px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                      onClick={handleAddClick}
                    >
                      Add
                    </button>
                  )}
                  <div className="w-full 800px:w-[50%]">
                    <input
                      type="text"
                      name="cost"
                      value={btnData.cost}
                      onChange={(event) =>
                        handlePriceFieldData(index, "cost", event.target.value)
                      }
                      required
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Enter your product costs."
                    />
                  </div>
                  {/* Gap */}
                  <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                  <div className="w-full 800px:w-[20%]">
                    <input
                      type="text"
                      name="sku"
                      value={btnData.sku}
                      onChange={(event) =>
                        handlePriceFieldData(index, "sku", event.target.value)
                      }
                      required
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="SKU CODE...."
                    />
                  </div>

                  <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                  <div className="w-full 800px:w-[15%]">
                    <input
                      type="number"
                      name="currencyPrice"
                      value={btnData.currencyPrice}
                      onChange={(event) =>
                        handlePriceFieldData(
                          index,
                          "currencyPrice",
                          event.target.value
                        )
                      }
                      onWheel={(e) => e.target.blur()}
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Currency Price...."
                    />
                  </div>

                  <div className="w-full 800px:w-[2%] sm:mt-3 max-[635px]:mt-3"></div>

                  <div className="w-full 800px:w-[20%]">
                    <select
                      value={btnData.currency}
                      onChange={(event) =>
                        handlePriceFieldData(
                          index,
                          "currency",
                          event.target.value
                        )
                      }
                      className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                      required
                    >
                      <option value="" disabled>
                        Choose a Currency
                      </option>
                      {countryISOCurrencyCode &&
                        countryISOCurrencyCode.map((i) => (
                          <option value={i.code} key={i.key}>
                            {i.code}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div>
                    {originalPrice.length !== 1 && (
                      <button
                        type="button"
                        className="mr-2 ml-2 mt-2 pb-1 text-[10px] font-[450] text-white bg-black w-[50px] h-[35px] flex items-center justify-center rounded-xl cursor-pointer uppercase"
                        onClick={() => handleRemoveClick(btnData.id)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* Product Tags */}
          <br />
          <div>
            <label className="pb-2">Tags</label>
            <input
              type="text"
              name="tags"
              value={tags}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setTags(e.target.value)}
              placeholder="Enter your product tags..."
            />
          </div>
          {/* Product Stock */}
          <br />
          <div>
            <label className="pb-2">
              Product Stock <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="price"
              value={stock}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setStock(e.target.value)}
              placeholder="Enter your product stock..."
            />
          </div>
          <br />
          <div className="w-full 800px:flex items-center">
            {/* Product Price With Discount */}
            <div className="w-full 800px:w-[20%]">
              <label className="pb-2">Discount Percent</label>
              <input
                type="number"
                name="price"
                value={discountPrice}
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={(e) => setDiscountPrice(e.target.value)}
                placeholder="Enter your product price with discount..."
              />
            </div>
            <div className="w-full 800px:w-[3%]"></div>
            {/* Feature or Not Options */}
            <div className="w-full 800px:w-[24%]">
              <label className="pb-2">
                Featured <span className="text-red-500">*</span>
              </label>
              <select
                className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                value={featured}
                onChange={(e) => setFeatured(e.target.value)}
              >
                <option value="" disabled>
                  Choose a Option
                </option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </select>
            </div>
            <div className="w-full 800px:w-[3%]"></div>
            {/* Sale or Not Options */}
            <div className="w-full 800px:w-[24%]">
              <label className="pb-2">
                Sale <span className="text-red-500">*</span>
              </label>
              <select
                className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                value={sales}
                onChange={(e) => setSales(e.target.value)}
              >
                <option value="" disabled>
                  Choose a Option
                </option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </select>
            </div>
            <div className="w-full 800px:w-[3%]"></div>
            <div className="w-full 800px:w-[24%]">
              <label className="pb-2">
                Status <span className="text-red-500">*</span>
              </label>
              <select
                className="w-full mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="" disabled>
                  Choose a Option
                </option>
                <option value="ON">ON</option>
                <option value="OFF">OFF</option>
              </select>
            </div>
          </div>
          {/* Product Images */}
          <br />
          <div>
            <label className="pb-2">
              Upload Images <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              name=""
              id="upload"
              className="hidden"
              multiple
              onChange={handleImageChange}
            />
            <div className="w-full flex items-center flex-wrap">
              <label htmlFor="upload">
                <AiOutlinePlusCircle
                  size={30}
                  className="mt-3 cursor-pointer"
                  color="#555"
                />
              </label>
              {images &&
                images.map((i) => (
                  <div key={i} className="relative">
                    <img
                      src={URL.createObjectURL(i)}
                      key={i}
                      alt=""
                      className="h-[120px] w-[120px] object-cover m-2 rounded-md"
                    />
                    <button
                      type="button"
                      className="absolute top-0 right-0 mt-1 mr-1 text-white bg-red-500 p-1 rounded-full"
                      onClick={() => handleDeleteImage(i)}
                    >
                      <AiOutlineMinusCircle size={20} />
                    </button>
                  </div>
                ))}
            </div>
            <br />
            <div>
              <input
                type="submit"
                value="Create"
                className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        </form>
      </div>
    </ShopHelmet>
  );
};

export default CreateProduct;
