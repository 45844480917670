import React, { useEffect } from "react";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/107043-success.json";
import Helmet from "../../helmet/helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";

const KhaltiBankingOrderSuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      var currentURL = document.URL;

      const params = new URLSearchParams(currentURL.split("?")[1]);

      const status = params.get("status");
      const txnID = params.get("t");
      const bankIDX = params.get("idx");
      const token = params.get("token");
      const bankReference = params.get("bank_reference");
      const amountPaisa = params.get("amount");
      const paidPhoneNumber = params.get("mobile");
      const productID = params.get("product_identity");
      const productName = params.get("product_name");

      if (status === 200) {
        const verifyPaymentBody = {
          token: token,
          amount: amountPaisa,
        };

        axios
          .post(
            `${server}/payment/khalti/mobileBanking/verify`,
            verifyPaymentBody
          )
          .then((res) => {
            console.log(res);
          });
      }
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Helmet title="Order Success">
      <div>
        <Header />
        <Success />
        <Footer />
      </div>
    </Helmet>
  );
};

const Success = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} width={300} height={300} />
      <h5 className="text-center mb-14 text-[25px] text-[#000000a1]">
        Your order is successful 😍
      </h5>
      <br />
      <br />
    </div>
  );
};

export default KhaltiBankingOrderSuccessPage;
