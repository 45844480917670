import React from "react";
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import AllProductsDisplay from "../components/Route/AllProductsDisplay/AllProductsDisplay";
import Events from "../components/Events/Events";
import Footer from "../components/Layout/Footer";
import Helmet from "../helmet/helmet";

const HomePage = () => {
  return (
    <Helmet title="Home">
      <div>
        <Header activeHeading={1} />
        <Hero />
        <Categories />
        <FeaturedProduct />
        <Events />
        <BestDeals />
        <AllProductsDisplay />
        {/* <Sponsored /> */}
        <Footer />
      </div>
    </Helmet>
  );
};

export default HomePage;
