import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import axios from "axios";
import { banking_portal_url, server } from "../../server";
import { RxCross1 } from "react-icons/rx";
import { showToastError } from "../../static/toastMessage";
import UserHelmet from "../../helmet/userHelmet";
import MyPayLogo from "../../assets/images/Payments/mypay.webp";
import ImePayLogo from "../../assets/images/Payments/imepay.svg";
import FonePayQRLogo from "../../assets/images/Payments/fonepayQR.png";
import LoadingOverlay from "../Layout/LoadingOverlay";
import KhaltiLogo from "../../assets/images/Payments/Khalti.png";
import eSewaLogo from "../../assets/images/Payments/eSewa.png";
import BankingLogo from "../../assets/images/Payments/Banking.png";

const Payment = () => {
  const [orderData, setOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [select, setSelect] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
  }, []);

  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: user && user,
    totalPrice: orderData?.totalPrice,
    discountPrice: orderData?.discountPercentage,
  };

  const paymentData = {
    amount: Math.round(orderData?.totalPrice * 100),
  };

  // MyPay Payment Handler
  const mypayPaymentHandler = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      setTimeout(async () => {
        const generateOrderIdResponse = await axios.get(
          `${server}/order/generate-order-id`
        );

        const orderId = generateOrderIdResponse.data.orderId;

        const body = {
          orderID: orderId,
          totalPrice: orderData?.totalPrice,
        };

        const response = await axios.post(
          `${server}/payment/mypay/getToken`,
          body
        );

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        order.orderId = orderId;

        order.paymentInfo = {
          id: response.data.message.MerchantTransactionId,
          razerReference: orderData?.cart?.razerReferenceID,
          type: "MyPay",
          status: "Pending",
        };

        await axios
          .post(`${server}/order/create-order`, order, config)
          .then((res) => {
            setOpen(false);
            localStorage.setItem("cartItems", JSON.stringify([]));
            localStorage.setItem("latestOrder", JSON.stringify([]));
          });

        const statusData = {
          id: response.data.message.MerchantTransactionId,
          orderID: orderId,
        };

        await axios.post(
          `${server}/payment/mypay/getPaymentStatus`,
          statusData
        );

        window.location.href = response.data.message.RedirectURL;

        setIsLoading(false);
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  // Imepay Payment Handler
  const imepayPaymentHandler = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      setTimeout(async () => {
        const generateOrderIdResponse = await axios.get(
          `${server}/order/generate-order-id`
        );

        const orderId = generateOrderIdResponse.data.orderId;

        const body = {
          orderID: orderId,
          totalPrice: orderData?.totalPrice,
        };

        const response = await axios.post(
          `${server}/payment/imepay/getToken`,
          body
        );

        if (response.status === 200) {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          order.orderId = orderId;

          order.paymentInfo = {
            id: response.data.message.TokenId,
            razerReference: orderData?.cart?.razerReferenceID,
            type: "Imepay",
            status: "Pending",
          };

          await axios
            .post(`${server}/order/create-order`, order, config)
            .then((res) => {
              setOpen(false);
              localStorage.setItem("cartItems", JSON.stringify([]));
              localStorage.setItem("latestOrder", JSON.stringify([]));
            });

          const body = {
            TokenId: response.data.message.TokenId,
            RefId: response.data.message.RefId,
            TranAmount: response.data.message.Amount,
            Method: "GET",
          };

          const sendData = await axios.post(
            `${server}/payment/imepay/initiatePayment`,
            body
          );

          window.location.href = `${sendData.data.imeUrl}${sendData.data.message}`;
        } else {
          showToastError("Payment Error! Refresh the page and try again.");
        }

        setIsLoading(false);
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  const fonepayQRUploadHandler = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      setTimeout(async () => {
        const generateOrderIdResponse = await axios.get(
          `${server}/order/generate-order-id`
        );

        const orderId = generateOrderIdResponse.data.orderId;

        const body = {
          orderId: orderId,
        };

        const response = await axios.post(
          `${server}/payment/fonepay/getToken`,
          body
        );

        if (response.status === 200) {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          order.orderId = orderId;

          order.paymentInfo = {
            id: response.data.token,
            razerReference: orderData?.cart?.razerReferenceID,
            type: "Fonepay(QR)",
            status: "Pending",
          };

          const newBody = {
            TokenId: response.data.token,
            orderId: orderId,
            totalPrice: orderData?.totalPrice,
            userPhone: user.phoneNumbers,
          };

          await axios
            .post(`${server}/order/create-order`, order, config)
            .then((res) => {
              setOpen(false);
              localStorage.setItem("cartItems", JSON.stringify([]));
              localStorage.setItem("latestOrder", JSON.stringify([]));
            });

          const newResponse = await axios.post(
            `${server}/payment/fonepay/postInfo`,
            newBody
          );

          window.location.href = `${newResponse.data.fonepayUrl}${newResponse.data.fonepayPayment.transactionId}`;
        } else {
          showToastError("Payment Error! Refresh the page and try again.");
        }

        setIsLoading(false);
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  const eSewaPaymentHandler = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      setTimeout(async () => {
        const generateOrderIdResponse = await axios.get(
          `${server}/order/generate-order-id`
        );

        const orderId = generateOrderIdResponse.data.orderId;

        const body = {
          orderId: orderId,
        };

        const response = await axios.post(
          `${server}/payment/esewa/getToken`,
          body
        );

        if (response.status === 200) {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          order.orderId = orderId;

          const razerReference = orderData?.cart.map((r) => {
            return r.razerReferenceID;
          });

          order.paymentInfo = {
            id: response.data.token,
            razerReference: razerReference,
            type: "eSewa(QR)",
            status: "Pending",
          };

          const newBody = {
            TokenId: response.data.token,
            orderId: orderId,
            totalPrice: orderData?.totalPrice,
            userPhone: user?.phoneNumbers,
          };

          await axios
            .post(`${server}/order/create-order`, order, config)
            .then((res) => {
              setOpen(false);
              localStorage.setItem("cartItems", JSON.stringify([]));
              localStorage.setItem("latestOrder", JSON.stringify([]));
            });

          const newResponse = await axios.post(
            `${server}/payment/esewa/postInfo`,
            newBody
          );

          window.location.href = `${newResponse.data.esewaURL}${newResponse.data.esewaPayment.transactionId}`;
        } else {
          showToastError("Payment Error! Refresh the page and try again.");
        }

        setIsLoading(false);
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  const khaltiPaymentHandler = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      setTimeout(async () => {
        const generateOrderIdResponse = await axios.get(
          `${server}/order/generate-order-id`
        );

        const orderId = generateOrderIdResponse.data.orderId;

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        order.orderId = orderId;

        const orderName = orderData?.cart
          .map((item) => {
            return `${item.name} - ${item.productType}`;
          })
          .join(", ");

        const subTotalPrice = orderData?.cart?.reduce(
          (acc, item) => acc + item.qty * item.originalPrice,
          0
        );

        const identity = orderData?.cart.map((i) => {
          return i._id;
        });

        const qty = orderData?.cart.map((q) => {
          return q.qty;
        });

        const ogPrice = orderData?.cart.map((og) => {
          return og.originalPrice;
        });

        const productDetails = {
          identity: identity,
          name: orderName,
          quantity: qty,
          unit_price: ogPrice,
        };

        const body = {
          orderId: orderId,
          orderName: orderName,
          totalPrice: orderData?.totalPrice,
          subTotalPrice: subTotalPrice,
          discountPrice: orderData?.discountPercentage,
          userName: user?.name,
          userEmail: user?.email,
          userPhone: user?.phoneNumber,
          productDetails,
        };

        const khaltiData = await axios.post(
          `${server}/payment/khalti/getPIDX`,
          body
        );

        order.paymentInfo = {
          id: khaltiData.data.message.pidx,
          razerReference: orderData?.cart?.razerReferenceID,
          type: "Khalti",
          status: "Pending",
        };

        if (khaltiData.status === 200) {
          const orderRes = await axios
            .post(`${server}/order/create-order`, order, config)
            .then((res) => {
              setOpen(false);
              localStorage.setItem("cartItems", JSON.stringify([]));
              localStorage.setItem("latestOrder", JSON.stringify([]));
            });

          window.location.href = khaltiData.data.message.payment_url;
        } else {
          showToastError("Khalti API Error");
          setIsLoading(false);
        }

        setIsLoading(false);
      }, 1000);
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  const khaltiMobileBankingHandler = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      setTimeout(async () => {
        const generateOrderIdResponse = await axios.get(
          `${server}/order/generate-order-id`
        );

        const orderId = generateOrderIdResponse.data.orderId;

        const totalPrice = orderData?.totalPrice;
        const discountPrice = orderData?.discountPercentage;
        const userPhone = user?.phoneNumber;

        const orderName = orderData?.cart
          .map((item) => {
            return `${item.name} - ${item.productType}`;
          })
          .join(", ");

        const body = btoa(
          orderId +
            "|" +
            orderName +
            "|" +
            totalPrice +
            "|" +
            `${discountPrice === "" ? 0 : discountPrice}` +
            "|" +
            `${userPhone === undefined ? "" : userPhone}`
        );

        const orderQueryString = encodeURIComponent(JSON.stringify(order));

        // localStorage.setItem("cartItems", JSON.stringify([]));
        // localStorage.setItem("latestOrder", JSON.stringify([]));

        window.open(
          `${banking_portal_url}?data=${body}&order=${orderQueryString}`,
          "_blank"
        );

        setIsLoading(false);
      }, 1000);
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <UserHelmet title={user?.name} page="Payment Page">
        <div className="w-full flex flex-col items-center py-8">
          <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
            <div className="w-full 800px:w-[65%]">
              <PaymentInfo
                user={user}
                open={open}
                setOpen={setOpen}
                setSelect={setSelect}
                select={select}
              />
            </div>
            <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
              <CartData
                orderData={orderData}
                select={select}
                isLoading={isLoading}
                mypayPaymentHandler={mypayPaymentHandler}
                imepayPaymentHandler={imepayPaymentHandler}
                khaltiPaymentHandler={khaltiPaymentHandler}
                // khaltiMobileBankingHandler={khaltiMobileBankingHandler}
                fonepayQRUploadHandler={fonepayQRUploadHandler}
                eSewaPaymentHandler={eSewaPaymentHandler}
              />
            </div>
          </div>
        </div>
      </UserHelmet>
    </>
  );
};

const PaymentInfo = ({ user, open, setOpen, setSelect, select }) => {
  return (
    <div className="w-full 800px:w-[95%] bg-[#fff] rounded-md p-5 pb-8">
      {/* Ime Pay Payment */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(1)}
          >
            {select === 1 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <div className="pl-2 cursor-pointer" onClick={() => setSelect(1)}>
            <img src={ImePayLogo} className="h-9 rounded-sm" />
          </div>
        </div>
      </div>
      <br />
      {/* Khalti Payment */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(2)}
          >
            {select === 2 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <div className="pl-2 cursor-pointer" onClick={() => setSelect(2)}>
            <img src={KhaltiLogo} className="h-8" />
          </div>
        </div>
      </div>
      <br />
      {/* MyPay Payment */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(3)}
          >
            {select === 3 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <div className="pl-2 cursor-pointer" onClick={() => setSelect(3)}>
            <img src={MyPayLogo} className="h-8" />
          </div>
        </div>
      </div>
      <br />
      {/* Esewa QR Payment */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(4)}
          >
            {select === 4 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <div className="pl-2 cursor-pointer" onClick={() => setSelect(4)}>
            <img src={eSewaLogo} className="h-9 rounded-sm" />
          </div>
        </div>
      </div>
      {/* Mobile Banking Payment */}
      {/* <br />
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(5)}
          >
            {select === 5 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <div className="pl-2 cursor-pointer" onClick={() => setSelect(5)}>
            <img src={BankingLogo} className="h-9 rounded-sm" />
          </div>
        </div>
      </div> */}

      <br />
      {/* Fonepay QR Payment */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(5)}
          >
            {select === 5 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <div className="pl-2 cursor-pointer" onClick={() => setSelect(5)}>
            <img src={FonePayQRLogo} className="h-9 rounded-sm" />
          </div>
        </div>
      </div>
    </div>
  );
};

const CartData = ({
  select,
  orderData,
  isLoading,
  mypayPaymentHandler,
  imepayPaymentHandler,
  khaltiPaymentHandler,
  // khaltiMobileBankingHandler,
  fonepayQRUploadHandler,
  eSewaPaymentHandler,
}) => {
  return (
    <>
      <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
        <div className="flex justify-between">
          <h3 className="text-[16px] font-[400] text-[#000000a4]">
            Sub-Total:
          </h3>
          <h5 className="text-[18px] font-[600]">
            Rs {orderData?.subTotalPrice}
          </h5>
        </div>
        <br />
        <div className="flex justify-between border-b pb-3">
          <h3 className="text-[16px] font-[400] text-[#000000a4]">Discount:</h3>
          <h5 className="text-[18px] font-[600]">
            {orderData?.discountPercentage
              ? "- Rs " + orderData.discountPercentage
              : "-"}
          </h5>
        </div>
        <br />

        <div className="flex justify-between pb-3">
          <h3 className="text-[18px] font-[750] text-[#000000a4]">Total:</h3>
          <h5 className="text-[18px] font-[600] text-end">
            Rs {orderData?.totalPrice}
          </h5>
        </div>
      </div>
      {select === 1 ? (
        <div className="w-full flex">
          <form className="w-full" onSubmit={imepayPaymentHandler}>
            <input
              type="submit"
              value="Pay with IME Pay"
              disabled={isLoading}
              className={`${styles.button} !bg-[#f63b60] w-full text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
            />
          </form>
        </div>
      ) : select === 2 ? (
        <div className="w-full flex">
          <form className="w-full" onSubmit={khaltiPaymentHandler}>
            <input
              type="submit"
              value="Pay with Khalti"
              disabled={isLoading}
              className={`${styles.button} !bg-[#f63b60] w-full text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
            />
          </form>
        </div>
      ) : select === 3 ? (
        <div className="w-full flex">
          <form className="w-full" onSubmit={mypayPaymentHandler}>
            <input
              type="submit"
              value="Pay with My Pay"
              disabled={isLoading}
              className={`${styles.button} !bg-[#f63b60] text-[#fff] w-full h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
            />
          </form>
        </div>
      ) : select === 4 ? (
        <div className="w-full flex">
          <form className="w-full" onSubmit={eSewaPaymentHandler}>
            <input
              type="submit"
              value="Pay with eSewa (QR)"
              disabled={isLoading}
              className={`${styles.button} !bg-[#f63b60] w-full text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
            />
          </form>
        </div>
      ) : select === 5 ? (
        <div className="w-full flex">
          <form className="w-full" onSubmit={fonepayQRUploadHandler}>
            <input
              type="submit"
              value="Pay with Fonepay (QR)"
              disabled={isLoading}
              className={`${styles.button} !bg-[#f63b60] w-full text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
            />
          </form>
        </div>
      ) : //  : select === 5 ? (
      //   <div className="w-full flex">
      //     <form className="w-full" onSubmit={khaltiMobileBankingHandler}>
      //       <input
      //         type="submit"
      //         value="Pay with Bank"
      //         // disabled={isLoading}
      //         className={`${styles.button} !bg-[#f63b60] w-full text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
      //       />
      //     </form>
      //   </div>
      // )
      null}
    </>
  );
};

export default Payment;
