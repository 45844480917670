import React from "react";
import Helmet from "../../helmet/helmet";
import { AiOutlineLoading } from "react-icons/ai";

const LoadingOverlay = () => {
  return (
    <Helmet title="Loading.....">
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
        }}
      >
        <AiOutlineLoading className="animate-spin text-white text-4xl" />
      </div>
    </Helmet>
  );
};

export default LoadingOverlay;
