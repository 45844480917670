import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Footer from "../components/Layout/Footer";
import Helmet from "../helmet/helmet";

const GameCardsPage = () => {
  const [data, setData] = useState([]);
  const { allProducts, isLoading } = useSelector((state) => state.products);

  useEffect(() => {
    // const d =
    //   allProducts && allProducts.sort((a, b) => b.sold_out - a.sold_out);
    // const d = allProducts;
    // setData(d);
    window.scrollTo(0, 0);
  }, [allProducts]);

  const relatedProducts =
    allProducts &&
    allProducts.filter((product) => {
      return product.featured === "YES";
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Helmet title="Game Cards">
          <div>
            <Header activeHeading={2} />
            <br />
            <br />
            {relatedProducts && relatedProducts.length !== 0 && (
              <div className={`${styles.section}`}>
                <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
                  {relatedProducts &&
                    relatedProducts.map((i, index) => (
                      <ProductCard data={i} key={index} />
                    ))}
                </div>
              </div>
            )}
            {relatedProducts && relatedProducts.lenght === 0 && (
              <div className={`${styles.section}`}>
                <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12"></div>
              </div>
            )}
            <Footer />
          </div>
        </Helmet>
      )}
    </>
  );
};

export default GameCardsPage;
