import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";

const AllProductsDisplay = () => {
  const { allProducts } = useSelector((state) => state.products);

  const allProductsDisplay =
    allProducts &&
    allProducts.filter((product) => {
      return (
        product.featured === "NO" &&
        product.sales === "NO" &&
        product.status === "ON"
      );
    });

  const sortedProducts = allProductsDisplay?.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const firstTenProducts = sortedProducts?.slice(0, 5);

  return (
    <>
      {firstTenProducts?.length === 0 ? null : (
        <div>
          <div className={`${styles.section}`}>
            <div className="w-full justify-between flex">
              <div className={`${styles.heading}`}>
                <h1>All Products</h1>
              </div>
              <div className={`${styles.heading} hover:underline`}>
                <a href="/products">View All Products -></a>
              </div>
            </div>
            {firstTenProducts && firstTenProducts.length !== 0 && (
              <div className="grid grid-cols-1 min-[500px]:grid-cols-2 gap-[20px] md:grid-cols-3 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0">
                {firstTenProducts &&
                  firstTenProducts.map((i, index) => (
                    <>
                      <ProductCard data={i} key={index} />
                    </>
                  ))}
              </div>
            )}
            {firstTenProducts?.length === 0 && (
              <>
                <h4 className="pb-4">
                  No Featured Products have been added currently!
                </h4>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AllProductsDisplay;
