import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Helmet from "../helmet/helmet";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  const sortDataAlphabetically = (data) => {
    return data.slice().sort((a, b) => a.name.localeCompare(b.name));
  };

  useEffect(() => {
    if (allProducts) {
      if (categoryData === null) {
        const d = allProducts;
        setData(sortDataAlphabetically(d));
      } else {
        const d = allProducts.filter((i) => i.category === categoryData);
        setData(sortDataAlphabetically(d));
      }
      window.scrollTo(0, 0);
    }
  }, [allProducts, categoryData]);

  if (!allProducts) {
    <Loader />;
  }

  const getCurrentProducts = () => {
    const totalProducts = data.length;
    const lastPageIndex = Math.ceil(totalProducts / productsPerPage);

    let indexOfLastProduct = currentPage * productsPerPage;
    if (indexOfLastProduct > totalProducts) {
      indexOfLastProduct = totalProducts;
    }

    const indexOfFirstProduct = (currentPage - 1) * productsPerPage;

    const currentProducts = data.slice(indexOfFirstProduct, indexOfLastProduct);

    return currentProducts;
  };

  const currentProducts = getCurrentProducts();

  const sortedProducts = sortDataAlphabetically(currentProducts);
  const totalPages = Math.ceil(data.length / productsPerPage);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Helmet title={categoryData === null ? "Products" : categoryData}>
          <div>
            <Header activeHeading={3} />
            <br />
            <br />
            <div className={`${styles.section}`}>
              <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
                {sortedProducts.map((i, index) => (
                  <>
                    <ProductCard data={i} key={index} />
                  </>
                ))}
              </div>
              {data && data.length === 0 ? (
                <h1 className="text-center w-full pb-[100px] text-[20px]">
                  No products Found!
                </h1>
              ) : null}
            </div>
            {data.length > productsPerPage && (
              <div className="flex justify-center mt-5 mb-5">
                <button
                  className={`px-4 py-2 bg-black my-3 flex items-center justify-center rounded-xl text-[#fff] ${
                    currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className={`px-4 py-2 ml-4 bg-black my-3 flex items-center justify-center rounded-xl text-[#fff] ${
                    currentPage === totalPages
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}
            <Footer />
          </div>
        </Helmet>
      )}
    </>
  );
};

export default ProductsPage;
