import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import LoadingOverlay from "../Layout/LoadingOverlay";
import { showToastError, showToastSuccess } from "../../static/toastMessage";
import Helmet from "../../helmet/helmet";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (newPassword !== confirmPassword) {
      showToastError("Password Doesn't Match");
      setIsLoading(false);
    } else {
      var currentURL = document.URL;

      const params = new URLSearchParams(currentURL.split("?")[1]);

      const token = params.get("token");
      const userId = params.get("id");

      const body = {
        newPassword,
        confirmPassword,
        token,
        userId,
      };

      try {
        const resetPassword = await axios.post(
          `${server}/user/reset-password`,
          body
        );

        if (resetPassword.status === 200) {
          showToastSuccess(`Password has been resetted successfully!`);
          setIsLoading(false);
          navigate("/login");
        } else {
          showToastError("Contact Support!");
          setIsLoading(false);
        }
      } catch (error) {
        showToastError(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <Helmet title="Reset Password">
      {isLoading && <LoadingOverlay />}
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Change Password
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Enter your new password
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    name="password"
                    autoComplete="password"
                    required
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm new password
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    name="password"
                    autoComplete="password"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Submit
                </button>
              </div>
              <div className={`${styles.noramlFlex} w-full`}>
                <h4>Don't have an account?</h4>
                <Link to="/sign-up" className="text-blue-600 pl-2">
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Helmet>
  );
};

export default ResetPassword;
