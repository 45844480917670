import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Fonepay from "../components/Payment/Fonepay";

const FonepayPaymentPage = () => {
  return (
    <div className="w-full min-h-screen bg-[#f6f9fc]">
      <Header />
      <br />
      <br />
      <Fonepay />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default FonepayPaymentPage;
