import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";

const FeaturedProduct = () => {
  const { allProducts } = useSelector((state) => state.products);
  const [data, setData] = useState([]);

  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.sort((a, b) => b.sold_out - a.sold_out);
    const firstFive = sortedData && sortedData.slice(0, 5);
    setData(firstFive);
  }, [allProducts]);

  const relatedProducts =
    allProducts &&
    allProducts.filter((product) => {
      return product.featured === "YES";
    });

  return (
    <div>
      <div className={`${styles.section}`}>
        <div className={`${styles.heading}`}>
          <h1>Latest Products</h1>
        </div>
        {relatedProducts && relatedProducts.length !== 0 && (
          <div className="grid grid-cols-1 min-[500px]:grid-cols-2 gap-[20px] md:grid-cols-3 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0">
            {relatedProducts &&
              relatedProducts.map((i, index) => (
                <ProductCard data={i} key={index} />
              ))}
          </div>
        )}
        {allProducts?.length === 0 && (
          <>
            <h4 className="pb-4">
              No Featured Products have been added currently!
            </h4>
          </>
        )}
      </div>
    </div>
  );
};

export default FeaturedProduct;
