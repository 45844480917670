import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { BsFillBagFill } from "react-icons/bs";
import { AiFillStar, AiOutlineMessage, AiOutlineStar } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { backend_url, server } from "../../server";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import UserHelmet from "../../helmet/userHelmet";
import PageNotFound from "../../pages/PageNotFound";
import Loader from "../Layout/Loader";
import LoadingOverlay from "../Layout/LoadingOverlay";
import { showToastError, showToastSuccess } from "../../static/toastMessage";

const OrderDetails = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [pinsOpen, setPINSOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [pinsItem, setPINSItem] = useState(null);
  const [rating, setRating] = useState(1);

  const [pinsOrders, setPinsOrders] = useState([]);
  const [isLoadingPins, setIsLoadingPins] = useState(false);
  const [copiedStates, setCopiedStates] = useState([]);
  const [copiedSNStates, setCopiesSNStates] = useState([]);

  const [dataFetched, setDataFetched] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id))
      .then(() => {
        setDataFetched(true);
      })
      .catch((error) => {
        setDataFetched(true);
      });
  }, [dispatch]);

  const data = orders && orders.find((item) => item._id === id);

  useEffect(() => {
    if (dataFetched && !data) {
      window.location.href = "/404";
    }
  }, [dataFetched, data]);

  if (!dataFetched) {
    return <Loader />;
  }

  const fetchPinsOrders = async () => {
    try {
      setIsLoadingPins(true);

      const body = {
        orderId: data?.orderId,
      };

      const response = await axios.post(
        `${server}/order/get-pins-order`,
        body,
        { withCredentials: true }
      );

      setPinsOrders(response.data.data.pins);

      setIsLoadingPins(false);
    } catch (error) {
      setIsLoadingPins(false);
      showToastError(error.response.data.message);
    }
  };

  const reviewHandler = async (e) => {
    await axios
      .put(
        `${server}/product/create-new-review`,
        {
          user,
          rating,
          comment,
          productId: selectedItem?._id,
          orderId: id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        showToastSuccess(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
        setComment("");
        setRating(null);
        setOpen(false);
      })
      .catch((error) => {
        showToastError(error);
      });
  };

  const refundHandler = async (e) => {
    await axios
      .put(`${server}/order/order-refund/${id}`, {
        status: "Initiating Refund",
      })
      .then((res) => {
        showToastSuccess(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
      })
      .catch((error) => {
        showToastError(error.response.data.message);
      });
  };

  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(date).toLocaleString("en-NP", options);
  };

  const statusInformation =
    data &&
    data.cart &&
    data.cart.flatMap((s) => {
      s.formFields?.map((e) => {
        return e.status;
      });
    });

  const isStatus = statusInformation?.some((status) => status);

  const handleCopyToClipboard = async (pinCode, index) => {
    const tempInput = document.createElement("input");
    tempInput.style.position = "absolute";
    tempInput.style.left = "-1000px";
    tempInput.value = pinCode;

    document.body.appendChild(tempInput);

    tempInput.select();
    tempInput.setSelectionRange(0, 99999);

    document.execCommand("copy");

    document.body.removeChild(tempInput);

    setCopiedStates((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = true;
      return updatedStates;
    });

    setTimeout(() => {
      setCopiedStates((prevState) => {
        const updatedStates = [...prevState];
        updatedStates[index] = false;
        return updatedStates;
      });
    }, 3000);
  };

  const handleCopyToClipboardSN = async (serialNumber, index) => {
    const tempInput = document.createElement("input");
    tempInput.style.position = "absolute";
    tempInput.style.left = "-1000px";
    tempInput.value = serialNumber;

    document.body.appendChild(tempInput);

    tempInput.select();
    tempInput.setSelectionRange(0, 99999);

    document.execCommand("copy");

    document.body.removeChild(tempInput);

    setCopiesSNStates((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = true;
      return updatedStates;
    });

    setTimeout(() => {
      setCopiesSNStates((prevState) => {
        const updatedStates = [...prevState];
        updatedStates[index] = false;
        return updatedStates;
      });
    }, 3000);
  };

  const tags =
    data &&
    data.cart?.map((t) => {
      return t.tags;
    });

  return (
    <UserHelmet title={user?.name} page={`Order Details - ${data?.status}`}>
      <div className={`py-4 min-h-screen ${styles.section}`}>
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center">
            <BsFillBagFill size={30} color="crimson" />
            <h1 className="pl-2 text-[25px]">Order Details</h1>
          </div>
        </div>

        <div className="w-full flex items-center justify-between pt-6">
          <h5 className="text-[#00000084]">
            Order ID:{" "}
            <strong>
              <span>{data?.orderId}</span>
            </strong>
          </h5>
          <h5 className="text-[#00000084]">
            Placed on: <span>{formatDate(data?.createdAt)}</span>
          </h5>
        </div>

        <br />
        <br />

        {/* Ordered Items */}
        {data &&
          data?.cart.map((item, index) => {
            return (
              <div className="w-full flex items-start mb-5">
                <img
                  src={`${backend_url}/${item.images[0]}`}
                  alt=""
                  className="w-[80x] h-[80px] rounded-md"
                />
                <div className="w-full">
                  <h5 className="pl-3 text-[20px]">
                    {item.name} - {item.productType}
                  </h5>
                  <h5 className="pl-3 text-[20px] text-[#00000091]">
                    Rs {item.originalPrice} x {item.qty}
                  </h5>
                </div>
                {!item.isReviewed && data?.status === "Delivered" ? (
                  <div
                    className={`${styles.button} text-[#fff]`}
                    onClick={() => setOpen(true) || setSelectedItem(item)}
                  >
                    Write a review
                  </div>
                ) : null}
              </div>
            );
          })}

        {/* Review Pop-Up */}

        {open && (
          <div className="w-full fixed top-0 left-0 h-screen bg-[#0005] z-50 flex items-center justify-center">
            <div className="w-[50%] h-min bg-[#fff] shadow rounded-md p-3">
              <div className="w-full flex justify-end p-3">
                <RxCross1
                  size={30}
                  onClick={() => setOpen(false)}
                  className="cursor-pointer"
                />
              </div>
              <h2 className="text-[30px] pb-2 font-[500] font-Poppins text-center">
                Give a Review
              </h2>
              <br />
              <div className="w-full flex">
                <img
                  src={`${backend_url}/${selectedItem?.images[0]}`}
                  alt=""
                  className="w-[80px] h-[80px]"
                />
                <div>
                  <div className="pl-3 text-[20px]">{selectedItem?.name}</div>
                  <h4 className="pl-3 text-[20px]">
                    Rs {selectedItem?.discountPrice} x {selectedItem?.qty}
                  </h4>
                </div>
              </div>

              <br />
              <br />

              {/* Ratings System */}
              <h5 className="pl-3 text-[20px] font-[500]">
                Give a Rating <span className="text-red-500">*</span>
              </h5>
              <div className="flex w-full ml-2 pt-1">
                {[1, 2, 3, 4, 5].map((i) =>
                  rating >= i ? (
                    <AiFillStar
                      key={i}
                      className="mr-1 cursor-pointer"
                      color="rgb(246,186,0)"
                      size={25}
                      onClick={() => setRating(i)}
                    />
                  ) : (
                    <AiOutlineStar
                      key={i}
                      className="mr-1 cursor-pointer"
                      color="rgb(246,186,0)"
                      size={25}
                      onClick={() => setRating(i)}
                    />
                  )
                )}
              </div>
              <br />

              {/* Comment System */}
              <div className="w-full ml-3">
                <label className="block text-[20px] font-[500]">
                  Write a comment
                  <span className="ml-1 font-[400] text-[16px] text-[#00000052]">
                    (optional)
                  </span>
                </label>
                <textarea
                  name="comment"
                  id=""
                  cols="20"
                  rows="5"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder={`Did you feel satisfied with ${selectedItem?.name} and delivery experience?`}
                  className="mt-2 w-[95%] border p-2 outline-none"
                ></textarea>
              </div>
              <div
                className={`${styles.button} text-white text-[20px] ml-3`}
                onClick={rating > 1 ? reviewHandler : null}
              >
                Submit
              </div>
            </div>
          </div>
        )}

        <div className="border-t w-full text-right">
          <h5 className="pt-3 text-[18px]">
            Total Price: <strong>Rs {data?.totalPrice}</strong>
          </h5>
        </div>

        <br />
        <br />

        {/* Shipping and Payment Info */}

        <div className="w-full 800px:flex items-center">
          <div className="w-full 800px:w-[60%]">
            <h4 className="pt-3 text-[20px] font-[600]">Shipping Address:</h4>
            <h4 className="pt-3 text-[20px]">Name - {data?.user.name}</h4>
            <h4 className="text-[20px]">
              Country -{" "}
              {data?.shippingAddress.country === ""
                ? "Nepal"
                : data?.shippingAddress.country}
            </h4>
            <h4 className="text-[20px]">
              Phone Number - + {data?.user?.phoneNumbers}
            </h4>
          </div>
          <div className="w-full 800px:w-[40%]">
            <h4 className="pt-3 text-[20px] font-[600]">Payment Info:</h4>
            <h4 className="pt-3">
              Paid Via -{" "}
              <span className="uppercase">
                <strong>{data?.paymentInfo?.type}</strong>
              </span>
            </h4>
            <h4>
              Status -{" "}
              <span className="uppercase">
                <strong>
                  {data?.paymentInfo?.status
                    ? data?.paymentInfo?.status
                    : "Not Paid"}
                </strong>
              </span>
            </h4>
            <br />
            {/* {data?.status === "Delivered" && (
              <div
                className={`${styles.button} text-white`}
                onClick={refundHandler}
              >
                Ask For Refund
              </div>
            )} */}
          </div>
        </div>

        <br />
        <br />
        <div className="w-full 800px:flex items-center">
          <div className="w-full 800px:w-[40%]">
            <h4 className="pt-3 text-[20px] font-[600]">
              Remarks and/or Code:
            </h4>
            <input
              type="text"
              name="name"
              disabled={true}
              className="mt-3 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] bg-white placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={data?.remarks}
            />
          </div>
        </div>
        {data && data?.status === "Delivered" && (
          <>
            {tags.includes("API") && !tags.includes("Validation") && (
              <>
                {data &&
                  data?.cart.map((item, index) => {
                    return (
                      <div className="w-full 800px:flex items-center">
                        <div className="w-full 800px:w-[40%]">
                          <div
                            className={`${styles.button} !w-[245px] text-white !text-[14px] !font-[600]`}
                            onClick={() =>
                              setPINSOpen(true) ||
                              setPINSItem(item) ||
                              fetchPinsOrders()
                            }
                          >
                            Check {item?.name} PINS
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </>
        )}

        {pinsOpen && (
          <div className="w-full fixed top-0 left-0 h-screen bg-[#0005] z-50 flex items-center justify-center">
            {isLoadingPins ? (
              <LoadingOverlay />
            ) : (
              <div
                className={`w-[40%] ${
                  pinsOrders.length > 4 ? "h-[80%]" : "h-min"
                }  bg-[#fff] shadow rounded-md p-3 overflow-y-auto`}
              >
                <div className="max-[799px]:hidden w-full 800px:flex items-center">
                  <div className="w-full 800px:w-[500%]">
                    <h2 className="text-[30px] pb-2 font-[500] font-Poppins text-center">
                      PINS For {pinsItem.name}
                    </h2>
                  </div>
                  <div className="w-full 800px:w-[40%]">
                    <div className="w-full flex justify-end p-3">
                      <RxCross1
                        size={30}
                        onClick={() => setPINSOpen(false)}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                </div>

                {/* Mobile */}

                <div className="max-[799px]:block 800px:hidden w-full items-center">
                  <div className="w-full min-[799px]:w-[40%]">
                    <div className="w-full flex justify-end p-3">
                      <RxCross1
                        size={30}
                        onClick={() => setPINSOpen(false)}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                  <div className="w-full min-[799px]:w-[500%]">
                    <h2 className="text-[18px] pb-2 font-[500] font-Poppins text-center">
                      PINS For {pinsItem.name}
                    </h2>
                  </div>
                </div>

                <br />
                {pinsOrders.map((pin, index) => (
                  <div key={index} className="w-full items-center">
                    <div
                      align="center"
                      className="border-solid border-[1px] border-[#3957db] rounded font-semibold max-[799px]:!text-xs text-xl text-[#3957db] !leading-[20px] p-[10px]"
                      onClick={() => handleCopyToClipboard(pin.pinCode, index)}
                    >
                      {copiedStates[index] ? "Copied!" : `PIN ${pin.pinCode}`}
                    </div>
                    <div align="right" className="pb-[15px]">
                      <span
                        className="font-normal max-[799px]:!text-[10px] max-[799px]:!leading-[2px] text-xs !leading-[18px] text-[#9a9a9a]
                      "
                        onClick={() =>
                          handleCopyToClipboardSN(pin.serialNumber, index)
                        }
                      >
                        S/N:{" "}
                        {copiedSNStates[index] ? (
                          <strong>Copied!</strong>
                        ) : (
                          <strong>CARD {pin.serialNumber}</strong>
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </UserHelmet>
  );
};
export default OrderDetails;
