import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/24151-ecommerce-animation.json";
import Helmet from "../../helmet/helmet";

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Helmet title="Loading.....">
      <div className="w-full h-[88vh] flex items-center justify-center">
        <Lottie options={defaultOptions} width={300} height={300} />
      </div>
    </Helmet>
  );
};

export default Loader;
