import React, { useEffect } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import { AiOutlineClockCircle } from "react-icons/ai";
import Helmet from "../helmet/helmet";

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Helmet title="Privacy Policy">
      <div>
        <Header />
        <Privacy />
        <Footer />
      </div>
    </Helmet>
  );
};

const Privacy = () => {
  return (
    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h2>

      <div className="mx-auto space-y-4">
        {/* Privacy Policy for Website */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Privacy Policy for{" "}
              <Link to="https://blankgamingstore.com">
                <strong>blankgamingstore.com</strong>
              </Link>
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              At this web-site registered under the name Manjushree Pustak Girha
              a.k.a <strong>Blank Gaming Store</strong>, accessible from{" "}
              <Link to="https://blankgamingstore.com">
                <strong>https://www.blankgamingstore.com</strong>
              </Link>
              , one of our main priorities is the privacy of our visitors. This
              Privacy Policy document contains types of information that is
              collected and recorded by blankgamingsore.com and how we use it.
              <br />
              <br />
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>
          </div>
        </div>

        {/* Information We Collect */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Information We Collect
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              We value your trust in providing us your personal information.
              Blank Gaming Store does not share, disclose or sell any
              personally-identifiable information (such as your name, address,
              telephone number or e-mail address) collected online on Blank
              Gaming Store's sites with other unaffiliated companies or
              organizations for non-Blank Gaming Store marketing purposes.
              Because this information is a critical part of our business, it
              would be treated like our other assets in the context of a merger,
              sale or other corporate reorganization or legal proceeding.
            </p>
          </div>
        </div>

        {/* Why We Collect Data From You */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Why We Collect Data From You?
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              Blank Gaming Store collects data from you for the purposes stated
              below:
              <br />
              <br />
              <p>
                <li className="px-4">
                  Where it is necessary for the performance of our agreement
                  with you to provide and deliver associated content and
                  Services
                </li>
                <li className="px-4">
                  Where it is necessary for compliance with legal obligations
                  that we may be subjected to and/or
                </li>
                <li className="px-4">
                  Where you have given consent to the same.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Children's Information */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Children's Information
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
              <br />
              <br />
              Blank Gaming Store does not knowingly collect any Personal
              Identifiable Information from children under the age of 13. If you
              think that your child provided this kind of information on our
              website, we strongly encourage you to contact us immediately and
              we will do our best efforts to promptly remove such information
              from our records.
            </p>
          </div>
        </div>

        {/* When Can We Collect Your Information */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              When Can We Collect Your Information?
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              We may collect personal information directly from you when you:
              <br />
              <br />
              <p>
                <li className="px-4">
                  Communicate with us (for example when you submit an
                  application form to become our customer, or when you contact
                  us for any enquiries including by calling our customer
                  service)
                </li>
                <li className="px-4">
                  Register or subscribe for a specific Product and/or Service or
                  our publications (for example, newsletters)
                </li>
                <li className="px-4">
                  Participate in any of our surveys enter into or participate in
                  any competitions, contests or loyalty programs run/organized
                  by Blank Gaming Store
                </li>
                <li className="px-4">
                  Register interest and/or request for information of (through
                  our online portals or other available channels) or subscribe
                  to our Products and/or Services
                </li>
                <li className="px-4">
                  Respond to any marketing materials we send out
                </li>
                <li className="px-4">Visit or browse our websites</li>
                <li className="px-4">Lodge a complaint with us</li>
                <li className="px-4">Provide feedback to us in any way</li>
                <li className="px-4">
                  Submit your personal data to us for any reason
                </li>
              </p>
              <br />
              Other than personal information obtained from you directly (as
              detailed above), we may also obtain your personal information from
              third parties we deal with or are connected with you (credit
              reference agencies or financial institutions), and from such other
              sources where you have given your consent for the disclosure of
              information relating to you, and/or where otherwise lawfully
              permitted.
            </p>
          </div>
        </div>

        {/* How Can We Collect Your Personal Information */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              How Can We Collect Your Personal Information?
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              Blank Gaming Store collects information online by asking you for
              it, for example, when you order a service, apply for a job, or
              respond to a survey, offer or other promotion. A customer's name,
              address (postal and e-mail) and telephone number are the most
              important pieces of information, but we might request other
              information, based on, for example, the service(s) being ordered
              or promoted.
              <br />
              <br />
              <p>
                <li className="px-4">
                  <strong>Cookies:</strong>
                  <br />
                  <span className="px-12">
                    Blank Gaming Store may also use “cookies” and similar
                    technology to obtain information about your visits to our
                    sites or your responses to e-mail from us - both
                    individually and in aggregated data that does not identify
                    you. These reporting tools tell us the parts of our web
                    sites or e-mails in which you showed an interest or took
                    some action, and help us improve the quality and usefulness
                    of our sites.
                  </span>
                </li>
                <br />
                <li className="px-4">
                  <strong>Content:</strong>
                  <br />
                  <span className="px-12">
                    You also provide us information in content you post to the
                    website. Your content (such as reviews, photos and videos)
                    and metadata about your content will be viewed by other
                    users. Blank Gaming Store can, but has no obligation, to
                    monitor your content you post on the website. We can remove
                    any information you post for any reason or no reason.
                  </span>
                </li>
                <br />
                <li className="px-4">
                  <strong>Topup via Login:</strong>
                  <br />
                  <span className="px-12">
                    If you choose topup via Login, then you need to provide your
                    in game or app email or phone, Password and User ID. We need
                    to access your account to topup your account.
                  </span>
                </li>
                <br />
                <li className="px-4">
                  <strong>Topup via UID:</strong>
                  <br />
                  <span className="px-12">
                    If you choose topup via UID, you need to provide your
                    UserID. We need to your User ID to topup on your account.
                  </span>
                </li>
                <br />
                <li className="px-4">
                  <strong>Payment Method:</strong>
                  <br />
                  <span className="px-12">
                    You will also be asked to submit Personal Information
                    depending on the payment method you choose when accessing,
                    purchasing or subscribing to one of our products or
                    services. For example, if you choose to pay for a
                    product/service by credit card, you will be required to
                    provide us the name of your credit card issuer, credit card
                    number, billing address, CVV number, and expiration date.
                  </span>
                </li>
                <br />
                <li className="px-4">
                  <strong>Log Files:</strong>
                  <br />
                  <span className="px-12">
                    When you use our website, our servers automatically record
                    certain information sent by your web browser. These server
                    logs may include information such as your web request,
                    Internet Protocol (“IP”) address, browser type, referring /
                    exit pages and URLs, number of clicks and how you interact
                    with links on the website, domain names, landing pages,
                    pages viewed, mobile carrier, and other such information.
                  </span>
                </li>
                <br />
                <li className="px-4">
                  <strong>Geo-Location Information:</strong>
                  <br />
                  <span className="px-12">
                    When you use the website by or through a mobile device, we
                    may access, collect, monitor and/or remotely store “location
                    data,” which may include GPS coordinates (e.g. latitude
                    and/or longitude) or similar information regarding the
                    location of your mobile device.
                  </span>
                </li>
                <br />
                <li className="px-4">
                  <strong>Device:</strong>
                  <br />
                  <span className="px-12">
                    When you use Blank Gaming Store, we may collect information
                    about your devices such as device type, device hardware
                    information, operating system type and version, device time
                    and timezone, and other information necessary.
                  </span>
                </li>
                <br />
                <li className="px-4">
                  <strong>Third Party Tools:</strong>
                  <br />
                  <span className="px-12">
                    Blank Gaming Store uses third party analytics tools, to help
                    understand use of the website. Many of these tools collect
                    the information sent by your browser as part of a web page
                    request, including cookies and your IP address. These
                    analytics tools also receive this information and their use
                    of it is governed by their privacy policy.
                  </span>
                </li>
                <br />
                <li className="px-4">
                  <strong>Other Data You Unequivocally Submit:</strong>
                  <br />
                  <span className="px-12">
                    When you post, comment or follow any of our content and
                    services, or send messages through our built-in online chat
                    system, or provide us information in respect of any request
                    or support required from us or for the purchase of our
                    products and services, or participate in our competitions,
                    contests, and tournaments, we may collect such information
                    from you.
                  </span>
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* How Can We Use Your Information */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              How Can We Use Your Information?
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              Blank Gaming Store uses the personally-identifiable information
              collected online principally to provide you with the following
              service(s):
              <br />
              <br />
              <p>
                <li className="px-4">
                  Processing order(s) or transaction(s) you have made
                </li>
                <li className="px-4">
                  Managing and administering your use of our Services including
                  but not limited to remembering your preferences
                </li>
                <li className="px-4">
                  For identification, verification, due diligence or known your
                  customer purposes (a.k.a KYC)
                </li>
                <li className="px-4">
                  Deal with or facilitate customer service, carry out your
                  instructions, deal with or respond to enquiries made by you or
                  on your behalf
                </li>
                <li className="px-4">
                  Contact you via voice call, text message, email and/or post or
                  otherwise for the purpose of managing your relationship with
                  us or your use of our Services.
                </li>
                <li className="px-4">
                  Conduct research, analysis and development activities
                  (including but not limited to data analytics, surveys,
                  products and service development and/or profiling) to analyze
                  how you use our Services, to recommend products and/or
                  services relevant to your interest, to improve our Services
                  and/or to enhance your customer experience
                </li>
                <li className="px-4">
                  Conduct research, analysis and development activities
                  (including but not limited to data analytics, surveys,
                  products and service development and/or profiling) to analyze
                  how you use our Services, to recommend products and/or
                  services relevant to your interest, to improve our Services
                  and/or to enhance your customer experience
                </li>
                <li className="px-4">
                  Respond to legal processes or to comply with or as required by
                  any applicable laws, governmental or regulatory requirements
                  of any relevant jurisdiction, or where we have good faith to
                  believe that such disclosure is necessary under any applicable
                  law. This includes but not limited to in response to subpoenas
                  and other legal demands, and where we believe that disclosing
                  this information is necessary to identify, contact or bring
                  legal action against individuals who may be endangering public
                  safety or interfering with Blank Gaming Store services, or
                  with our customer's or other's use of them.
                </li>
                <li className="px-4">
                  Deal with and/or facilitate asset sale transaction or
                  potential asset sale transaction, where such transaction
                  involves Blank Gaming Store as a participant or involves a
                  related company or affiliate of Blank Gaming Store as a
                  participant, and there may be other third party organizations
                  who are participants to such transaction. An ‘asset sale
                  transaction’ refers to the purchase, sale, lease, merger,
                  amalgamation or any other acquisition, disposal or financing
                  of an organization or a portion of an organization or of any
                  of the business or assets of an organization.
                </li>
                <li className="px-4">
                  Necessary for the detection, investigation, and prevention of
                  fraud, cheating and other violations. This data is used only
                  for the purposes of detection, investigation, prevention, and
                  where applicable, action on such violations, and is stored for
                  the time necessary for this purpose. If such data displays
                  signs of violation, we will store such data for the purpose of
                  establishing, exercising or defending legal claims, where
                  necessary.
                </li>
                <li className="px-4">
                  Any other purposes which we notify you of at the time of
                  obtaining your consent
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* What We Request From You? */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              What We Request From You?
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              We request you to provide us accurate and complete information
              about yourself and any other person whose personal information you
              provide us and for updating when it becomes incorrect or out of
              date by contacting us. This obligation is a condition to the
              provision of our Products and/or Services to you and/or any other
              person authorized or permitted by you or your organization/company
              to use the Products and/or Services.
            </p>
          </div>
        </div>

        {/* How Long We Store Your Data? */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              How Long We Store Your Data?
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              We will keep your information for as long as is required to fulfil
              the purpose for which it has been collected and processed or if
              any applicable legislation requires longer storage as required by
              law.
            </p>
          </div>
        </div>

        {/* Users Can Request Data Deletion */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Users Can Request Data Deletion
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              If you terminate your account with us, all personal data will be
              deleted except if it is required by legal process or any other
              legitimate reason as Blank Gaming Store deems necessary. If you
              remove the consent of processing personal data of your child, we
              will delete the personal data without delay based on the consent
              you have given. If you choose to object to the processing of your
              personal data, we will delete your data within a reasonable
              period, except if it is required by law to retain the same.
            </p>
          </div>
        </div>

        {/* We Promise Privacy & Security */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              We Promise Privacy & Security
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              Both of us together play an important role in protecting against
              online fraud. You should be careful that any information including
              your User ID, Password, Security Device and/or Security Code are
              not compromised by ensuring that you do not knowingly or
              accidentally share, provide or facilitate unauthorized use of it.
              Do not share your User ID, Password, Security Device and/or
              Security Code or allow access or use of it/them by others.
              <br />
              <br />
              At Blank Gaming Store, we use encryption, firewalls and other
              technology and security procedures to help protect the accuracy
              and security of your personal information and prevent unauthorized
              access or improper use. For example, you will note that while
              using some features of the Blank Gaming Store's website you will
              need to submit a password or some other type of authenticating
              information.
              <br />
              <br />
              If you click a link to a third-party site, you will be leaving our
              Platform and we don't control or endorse what is on third-party
              sites.
            </p>
          </div>
        </div>

        {/* European Economic Area (EEA) */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              European Economic Area (EEA) Rights
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              If you are a resident of the European Economic Area (EEA), you
              have certain data protection rights. If you wish to be informed
              what Personal Information we hold about you and if you want it to
              be removed from our systems, please contact us.
              <br />
              <br />
              In certain circumstances, you have the following data protection
              rights:
              <br />
              <br />
              <p>
                <li className="px-4">
                  The right to access, update or to delete the information we
                  have on you
                </li>
                <li className="px-4">The right of rectification</li>
                <li className="px-4">The right to object</li>
                <li className="px-4">The right of restriction</li>
                <li className="px-4">The right to data portability</li>
                <li className="px-4">The right to withdraw consent</li>
              </p>
            </p>
          </div>
        </div>

        {/* European Economic Area (EEA) */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Disclosure of Your Information (If Needed)
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              When you have provided Personal Information to us for a particular
              purpose, we may disclose your information to other companies that
              we have engaged to assist us in fulfilling your request. This may
              include, but is not limited to, fulfillment houses, billing
              services, transaction managers, credit verification services, and
              other third-party service providers. We may also disclose any of
              your Personal Information to law enforcement or other appropriate
              third parties in connection with criminal investigations,
              investigation of fraud, infringement of intellectual property
              rights, or other suspected illegal activities, or as otherwise may
              be required by applicable law, or, as we deem necessary in our
              sole discretion, in order to protect the legitimate legal
              interests of Blank Gaming Store.
            </p>
          </div>
        </div>

        {/* Notices to the Changes in Privacy Policies */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Notices to the Changes in Privacy Policies
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              Blank Gaming Store reserves the right to change this Privacy
              Policy at any time by notifying registered users via e-mail of the
              existence of a new Privacy Policy and/or posting the new Privacy
              Policy on Blank Gaming Store. All changes to the Privacy Policy
              will be effective when posted, and your continued use of Blank
              Gaming Store, product or service after the posting will constitute
              your acceptance of, and agreement to be bound by, those changes.
            </p>
          </div>
        </div>

        <div>
          <h2 className="text-1xl ext-gray-900 mb-8">
            For Further Questions and Queries. Contact us at:{" "}
            <a href="mailto:support@blankgamingstore.com">
              <strong>support@blankgamingstore.com</strong>
            </a>
          </h2>
          <div className="flex justify-end">
            <AiOutlineClockCircle className="mt-1 mr-1" />
            <h2 className="flex justify-end">Last Updated: May 30, 2023</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
