import React, { useEffect, useState } from "react";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLoading,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server.js";
import styles from "../../styles/styles.js";
import { showToastError, showToastSuccess } from "../../static/toastMessage";
import Helmet from "../../helmet/helmet";
import { countryPhoneCodes } from "../../static/data.js";
import FlagsSelect from "react-flags-select";
import { MD5 } from "crypto-js";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const [verificationStatus, setVerificationStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [verificationCode, setVerificationCode] = useState("");

  const navigate = useNavigate();

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  const generateAvatarFromName = (name) => {
    const hash = MD5(name).toString();
    const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?d=identicon`;
    return gravatarUrl;
  };

  const sortedPhoneCodes = countryPhoneCodes.sort((a, b) => {
    const codeA = parseInt(a.dial_code);
    const codeB = parseInt(b.dial_code);

    if (codeA < codeB) {
      return -1;
    } else if (codeA > codeB) {
      return 1;
    } else {
      return 0;
    }
  });

  const handlePhoneVerification = async (e) => {
    e.preventDefault();

    try {
      if (countryCode === "") {
        showToastError("Please select Your Country Code");
      } else if (phoneNumber === "") {
        showToastError("Please Enter your valid Phone Number");
      } else {
        setIsLoading(true);
        const body = {
          Code: countryCode,
          Number: phoneNumber,
        };

        const twilioResponse = await axios.post(`${server}/user/sendOTP`, body);

        setVerificationStatus(twilioResponse.data.otpResponse.status);

        if (verificationStatus === "pending") {
          setIsLoading(false);
        }
      }
    } catch (error) {
      showToastError(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (verificationCode === "") {
        showToastError("Please Verify your phone number first");
      } else {
        setIsSubmitting(true);

        setTimeout(async () => {
          if (verificationStatus === "pending") {
            const body = {
              Code: countryCode,
              Number: phoneNumber,
              OTP: verificationCode,
            };

            const twilioVerifiedResponse = await axios.post(
              `${server}/user/verifyOTP`,
              body
            );

            const dataResponse =
              twilioVerifiedResponse.data.verifiedResponse.status;

            if (dataResponse === "approved") {
              const config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };

              const phoneNumbers = countryCode + phoneNumber;

              const newForm = new FormData();

              if (avatar) {
                newForm.append("file", avatar);
              } else {
                const url = generateAvatarFromName(name);
                newForm.append("avatarURL", url);
              }

              newForm.append("name", name);
              newForm.append("email", email);
              newForm.append("password", password);
              newForm.append("phoneNumbers", phoneNumbers);
              newForm.append("phoneNumber", phoneNumber);

              axios
                .post(`${server}/user/create-user`, newForm, config)
                .then((res) => {
                  showToastSuccess(res.data.message);
                  setName("");
                  setEmail("");
                  setPhoneNumber("");
                  setPassword("");
                  setAvatar(null);
                  navigate("/login");
                })
                .catch((error) => {
                  showToastError(error.response.data.message);
                  setIsSubmitting(false);
                });
            } else {
              showToastError("Verification of Code Failed");
              setIsSubmitting(false);
            }
          }
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      showToastError(error);
    }
  };

  function numberOnly(id) {
    var element = document.getElementById(id);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }

  return (
    <Helmet title="Sign Up">
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Register as a new user
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="fullname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="text"
                    autoComplete="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <div className="mt-1 w-full 800px:flex items-center">
                  <div className="w-full 800px:w-[18%]">
                    <select
                      value={countryCode}
                      required
                      onChange={(e) => setCountryCode(e.target.value)}
                      className="appearance-none block w-full pl-3 py-2 border border-gray-300 rounded-md rounded-r-none shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 max-[795px]:rounded-md max-[795px]:mb-2 sm:text-sm max-[795px]:!text-sm cursor-pointer"
                    >
                      <option className="block pb-2" value="">
                        Select Your Country Code
                      </option>
                      {sortedPhoneCodes &&
                        sortedPhoneCodes.map((item) => (
                          <option
                            className="block pb-2"
                            key={item.name}
                            value={item.dial_code}
                          >
                            + {item.dial_code}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="w-full 800px:w-[60%]">
                    <input
                      type="number"
                      name="number"
                      autoComplete="number"
                      required
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md rounded-l-none shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm max-[795px]:rounded-md max-[795px]:mb-2"
                    />
                  </div>
                  <div className="w-full 800px:w-[20%]">
                    {verificationStatus === "pending" ? (
                      <div>
                        <input
                          name="verification-code"
                          type="number"
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 5))
                          }
                          value={verificationCode}
                          onChange={(e) => setVerificationCode(e.target.value)}
                          className="appearance-none max-[795px]:ml-0 ml-3 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm max-[795px]:rounded-md max-[795px]:mb-2"
                          placeholder="Code...."
                        />
                      </div>
                    ) : (
                      <button
                        type="button"
                        disabled={isLoading}
                        onClick={handlePhoneVerification}
                        className="max-[795px]:ml-0 py-2 ml-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                      >
                        {isLoading ? (
                          <>
                            <AiOutlineLoading className="animate-spin" />
                          </>
                        ) : (
                          "Verify"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="avatar"
                  className="block text-sm font-medium text-gray-700"
                ></label>
                <div className="mt-2 flex items-center">
                  <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                    {avatar ? (
                      <img
                        src={URL.createObjectURL(avatar)}
                        alt="avatar"
                        className="h-full w-full object-cover rounded-full"
                      />
                    ) : (
                      <img
                        src={generateAvatarFromName(name)}
                        alt="avatar"
                        className="h-full w-full object-cover rounded-full"
                      />
                    )}
                  </span>
                  <label
                    htmlFor="file-input"
                    className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
                  >
                    <span>Upload a File</span>
                    <input
                      type="file"
                      name="avatar"
                      id="file-input"
                      accept=".jpg,.jpeg,.png,.webp"
                      onChange={handleFileInputChange}
                      className="sr-only"
                    />
                  </label>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  {isSubmitting ? (
                    <>
                      <AiOutlineLoading className="animate-spin" />
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
              <div className={`${styles.noramlFlex} w-full`}>
                <h4>Already have an account?</h4>
                <Link to="/login" className="text-blue-600 pl-2">
                  Sign In
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Helmet>
  );
};

export default Signup;
