import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineLoading,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { GiUsaFlag } from "react-icons/gi";
import { FaBolt, FaClock, FaGlobe } from "react-icons/fa";
import { RiShoppingBasketFill } from "react-icons/ri";
import { MdAccountBox } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { backend_url, server } from "../../server";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addToCart } from "../../redux/actions/cart";
import { showToastError, showToastSuccess } from "../../static/toastMessage";
import Ratings from "./Ratings";
import axios from "axios";
import Helmet from "../../helmet/helmet";
import googlePlayStore from "../../assets/images/Google Play Store.png";
import EditorComponent from "../Editor/EditorComponent";
import Loader from "../Layout/Loader";

const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);

  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [messages, setMessages] = useState([]);
  const [price, setPrice] = useState("");
  const [token, setToken] = useState();
  const [select, setSelect] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [cartLoader, setCartLoader] = useState(false);
  const [manualPriceError, setManualPriceError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const product = useSearchParams();

  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist]);

  if (!data) {
    return <Loader />;
  }

  // if (!user) {
  //   return <Loader />;
  // }

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  const incrementCount = () => {
    if (count < 10) {
      setCount(count + 1);
    } else {
      showToastError("Only 10 items are allowed per order.");
    }
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    } else {
      showToastError("At least 1 items should be ordered.");
    }
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const activeEnterInformation =
    data &&
    data.formFields.map((p) => {
      return p.required;
    });

  const statusInformation =
    data &&
    data?.formFields?.map((s) => {
      return s.status;
    });

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    showToastSuccess(
      `${data.name} has been removed from wishlist successfully!`
    );
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    if (price === "") {
      showToastError("Please Select a Price!");
    } else {
      const newPrice = price.replace(/^[A-Za-z]+|\(.*?\)/g, "");
      const detailsWishlist = { ...data, originalPrice: newPrice };

      setClick(!click);
      showToastSuccess(`${data.name} has been added to wishlist successfully!`);
      dispatch(addToWishlist(detailsWishlist));
    }
  };

  const isStatus = statusInformation?.some((status) => status);

  const isRequired = activeEnterInformation?.some((required) => required);

  const dataTags = data?.tags.split(",").map((tag) => tag.trim());

  const buyNowButton = async (id) => {
    if (cartLoader) {
      return;
    }

    const isFormValuesEmpty = Object.values(formValues).every(
      (value) => !value
    );

    if (price === "") {
      showToastError("Please Select a Price!");
    } else if (isFormValuesEmpty && isRequired === true) {
      showToastError("Please enter the required fields!");
    } else {
      try {
        setCartLoader(true);

        setTimeout(async () => {
          if (cart.length > 0) {
            showToastError("Cart is limited to one item at a time currently.");
            navigate("/review-order");
          } else {
            const razerToken = await axios.post(
              `${server}/razer/generate-token`
            );

            if (razerToken.status === 200 || razerToken.status === 201) {
              let selectedPriceData;
              let newPrice;
              let itemInsideBracket;

              const isItemExist = cart && cart.find((i) => i._id === id);

              const tags = data.tags.split(",").map((tag) => tag.trim());

              if (tags.includes("Razer_Direct")) {
                const priceNPR = price * 145;

                const altData = data.priceData.find(
                  (item) => item.cost === "Rs 0 (0 USD)"
                );

                selectedPriceData = {
                  cost: `Rs ${priceNPR} (${price} ${altData.currency})`,
                  currency: altData.currency,
                  currencyPrice: price,
                  sku: altData.sku,
                  _id: altData._id,
                };

                newPrice = selectedPriceData.cost.replace(
                  /^[A-Za-z]+|\(.*?\)/g,
                  ""
                );

                const regex = /\(([^)]+)\)/;
                const matches = regex.exec(selectedPriceData.cost);
                itemInsideBracket = matches ? matches[1] : "";
              } else {
                selectedPriceData = data.priceData.find(
                  (item) => item.cost === price
                );

                const regex = /\(([^)]+)\)/;
                const matches = regex.exec(price);
                itemInsideBracket = matches ? matches[1] : "";
                newPrice = price.replace(/^[A-Za-z]+|\(.*?\)/g, "");
              }

              const availableAtOnce = 10;

              if (isItemExist) {
                showToastError(`${data.name} is already in the cart!`);
              } else {
                if (data.stock < count) {
                  showToastError(`${data.stock} are only in stocks!`);
                } else if (availableAtOnce < count) {
                  showToastError(
                    `${availableAtOnce} items can only be done in one checkout!`
                  );
                } else {
                  if (tags.includes("API")) {
                    const generateRazerReferenceID = await axios.get(
                      `${server}/razer/generate-razer-order-id`
                    );

                    const getIPResponse = await axios.get(
                      `${server}/user/getIP`,
                      {
                        withCredentials: true,
                      }
                    );

                    const razerReferenceID = `${generateRazerReferenceID.data.orderId}SKU${selectedPriceData.sku}`;

                    if (tags.includes("Validation_Required")) {
                      const cartData = {
                        ...data,
                        originalPrice: newPrice,
                        razerReferenceID: razerReferenceID,
                        productType: itemInsideBracket,
                        formValues: isStatus === true ? formValues : {},
                        priceFieldsData: selectedPriceData,
                        qty: count,
                      };

                      const customValueFields = [];

                      data?.formFields.forEach((field) => {
                        const fieldName = field.name;
                        const valueCode = formValues[fieldName];

                        customValueFields.push({
                          field_name: fieldName,
                          value_code: valueCode,
                        });
                      });

                      const productData = {
                        sku: selectedPriceData.sku,
                        custom_value: customValueFields,
                      };

                      const amountVerification =
                        selectedPriceData.currencyPrice;

                      const body = {
                        token: razerToken.data.data,
                        razerReferenceID: razerReferenceID,
                        currency: selectedPriceData.currency,
                        amount: amountVerification,
                        product: productData,
                        consumer_ip: getIPResponse.data.data.userIP,
                        consumer_country_code:
                          getIPResponse.data.data.countryCode,
                      };

                      const razerValidation = await axios.post(
                        `${server}/razer/validateAccount`,
                        body
                      );

                      if (
                        razerValidation.data.data.data.validate_status ===
                        "success"
                      ) {
                        dispatch(addToCart(cartData));
                        navigate("/review-order");
                      } else {
                        showToastError("Invalid Account");
                      }
                    } else {
                      const cartData = {
                        ...data,
                        originalPrice: newPrice,
                        productType: itemInsideBracket,
                        razerReferenceID: razerReferenceID,
                        formValues: isStatus === true ? formValues : {},
                        priceFieldsData: selectedPriceData,
                        qty: count,
                      };

                      const productData = [
                        {
                          sku: selectedPriceData.sku,
                          quantity: count,
                        },
                      ];

                      const body = {
                        razerReferenceID: razerReferenceID,
                        currency: selectedPriceData.currency,
                        product: productData,
                        consumer_ip: getIPResponse.data.data.userIP,
                        consumer_country_code:
                          getIPResponse.data.data.countryCode,
                      };

                      const pinOrderData = await axios.post(
                        `${server}/razer/pinData`,
                        body
                      );

                      if (pinOrderData.status === 201) {
                        dispatch(addToCart(cartData));
                        navigate("/review-order");
                      } else {
                        showToastError("Contact Support");
                      }
                    }
                  } else {
                    const cartData = {
                      ...data,
                      originalPrice: newPrice,
                      productType: itemInsideBracket,
                      formValues: isStatus === true ? formValues : {},
                      priceFieldsData: selectedPriceData,
                      qty: count,
                    };

                    dispatch(addToCart(cartData));
                    navigate("/review-order");
                  }
                }
              }
            } else {
              showToastError("API Error, Refresh and Try Again!");
            }
          }

          setCartLoader(false);
        }, 2000);
      } catch (error) {
        showToastError("Contact Admin/Seller!");
      }
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const gravatarDynamicPart = user?.avatar.split("/avatar/")[1];

  let avatarUrl;
  if (user?.avatar && user.avatar.includes("gravatar.com")) {
    avatarUrl = user.avatar.replace(backend_url, "");
  } else {
    avatarUrl = `${backend_url}${user?.avatar}`;
  }

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data?.shop._id;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          showToastError(error.response.data.message);
        });
    } else {
      showToastError("Please login to create a conversation.");
    }
  };

  return (
    <div className="bg-white">
      {data ? (
        <Helmet title={data.name}>
          <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
            <div className="w-full py-5">
              <div className="block w-full 800px:flex">
                <div className="w-full 800px:w-[50%]">
                  <img
                    src={`${backend_url}${data && data.images[select]}`}
                    alt=""
                    className="w-[80%] rounded-md"
                  />
                </div>
                <div className="w-full 800px:w-[50%] pt-5">
                  <div className="w-full">
                    <h1 className={`${styles.productTitle}`}>{data?.name}</h1>
                    {dataTags?.includes("Instant_Delivery") ||
                    dataTags?.includes("Global") ||
                    dataTags?.includes("Manual") ||
                    dataTags?.includes("USA") ? (
                      <>
                        <ul class="feature">
                          {dataTags?.includes("Global") && (
                            <li>
                              <div class="T">
                                <span region="global">
                                  <FaGlobe />
                                </span>
                              </div>
                              <div class="C">
                                <b>Global</b>
                              </div>
                            </li>
                          )}
                          {dataTags?.includes("USA") && (
                            <li>
                              <div class="T">
                                <span region="global">
                                  <GiUsaFlag />
                                </span>
                              </div>
                              <div class="C">
                                <b>United States of America (USA)</b>
                              </div>
                            </li>
                          )}
                          {dataTags?.includes("Manual") && (
                            <li>
                              <div class="T">
                                <span region="offline_bolt">
                                  <FaClock />
                                </span>
                              </div>
                              <div class="C">
                                <b>5-30 mins</b>
                              </div>
                            </li>
                          )}
                          {dataTags?.includes("Instant_Delivery") && (
                            <li>
                              <div class="T">
                                <span icon="offline_bolt">
                                  <FaBolt />
                                </span>
                              </div>
                              <div class="C">
                                <b>Instant Delivery</b>
                              </div>
                            </li>
                          )}
                        </ul>
                      </>
                    ) : null}
                  </div>
                  <EditorComponent description={data?.quickDescription} />
                  <div className="w-full pt-5">
                    {data.tags.includes("API") &&
                    data.tags.includes("Validation_Required") &&
                    data.tags.includes("Razer_Direct") ? (
                      <div className="flex items-center mt-4 justify-between pr-8">
                        <label className="block pb-2 font-[500] cursor-text">
                          Enter Price for {data?.name}
                        </label>
                        <div>
                          <div className="currency-input-icon">
                            <input
                              type="number"
                              onChange={(e) => {
                                const inputPrice = e.target.value;
                                setPrice(inputPrice);

                                if (inputPrice <= 0) {
                                  setManualPriceError(
                                    "Price must be a positive value"
                                  );
                                  setPrice("");
                                } else {
                                  setManualPriceError("");
                                }
                              }}
                              onWheel={(e) => e.target.blur()}
                              className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px] rounded-l shadow-lg ml-1"
                              placeholder="Enter in USD"
                            />
                            <i>$</i>
                          </div>
                          <div>
                            {manualPriceError && (
                              <span className="text-red-500">
                                {manualPriceError}
                              </span>
                            )}
                            {price && (
                              <span className="text-base font-bold pl-2 text-green-500">
                                NPR {price * 145}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <label className="block pb-2 font-[500] cursor-text">
                          Select Amount of {data?.name}
                        </label>
                        <select
                          name=""
                          id=""
                          required
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          className="w-[95%] border h-[40px] rounded-[5px] cursor-pointer"
                        >
                          <option className="block border pb-2">
                            Select an amount of {data?.name} you want
                          </option>
                          {data?.priceData.map((i, index) => {
                            return (
                              <option
                                className="block pb-2"
                                key={index}
                                value={i.cost}
                              >
                                {i.cost}
                              </option>
                            );
                          })}
                        </select>
                      </>
                    )}
                  </div>

                  {data.tags.includes("API") &&
                    !data.tags.includes("Validation_Required") && (
                      <div className="flex items-center mt-4 justify-between pr-8">
                        <label className="block pb-2 font-[500] cursor-text">
                          Select Quantity for {data?.name}
                        </label>
                        <div>
                          <button
                            className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                            onClick={decrementCount}
                          >
                            -
                          </button>
                          <span className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px]">
                            {count}
                          </span>
                          <button
                            className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                            onClick={incrementCount}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    )}

                  {isStatus === true ? (
                    <>
                      <hr className="h-px mt-8 w-[95%] bg-gray-200 border-0 dark:bg-gray-700" />
                      <div className="w-full pt-5">
                        <label className="block pb-2 font-[500] cursor-text">
                          Enter Details For {data?.name}
                        </label>
                        {data.formFields.map((field, index) => (
                          <div key={index}>
                            {field.type === "select" && (
                              <select
                                name={field.name}
                                required={field.required}
                                value={formValues[field.name] || ""}
                                onChange={(event) =>
                                  handleInputChange(event, field.placeholder)
                                }
                                className="mt-2 w-[95%] border h-[35px] rounded-[5px] cursor-pointer"
                              >
                                <option value="" disabled>
                                  Choose an {field.placeholder}
                                </option>
                                {field.options.map((option) => (
                                  <option key={option._id} value={option.value}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            )}
                            {field.type !== "select" && (
                              <input
                                type={field.type}
                                name={field.name}
                                placeholder={field.placeholder}
                                required={field.required}
                                value={formValues[field.name] || ""}
                                onChange={(event) =>
                                  handleInputChange(event, field.placeholder)
                                }
                                onWheel={(e) => e.target.blur()}
                                className="mt-2 appearance-none block w-[95%] px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}

                  <div className="flex items-center mt-4 justify-between pr-6">
                    <div className="flex" disabled={cartLoader}>
                      <div
                        className={`${styles.button} !rounded mr-4 !h-11 flex items-center`}
                        onClick={() => buyNowButton(data._id)}
                      >
                        <span className="text-white flex items-center">
                          {cartLoader ? (
                            <>
                              <AiOutlineLoading className="animate-spin text-white ml-1" />
                            </>
                          ) : (
                            <>
                              Buy Now <RiShoppingBasketFill className="ml-1" />
                            </>
                          )}
                        </span>
                      </div>
                    </div>

                    <div>
                      {click ? (
                        <AiFillHeart
                          size={30}
                          className="cursor-pointer"
                          onClick={() => removeFromWishlistHandler(data)}
                          color={click ? "red" : "#333"}
                          title="Remove from wishlist"
                        />
                      ) : (
                        <AiOutlineHeart
                          size={30}
                          className="cursor-pointer"
                          onClick={() => addToWishlistHandler(data)}
                          color={click ? "red" : "#333"}
                          title="Add to wishlist"
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex items-center pt-8">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <img
                        src={`${backend_url}${data?.shop.avatar}`}
                        alt=""
                        className="w-[50px] h-[50px] rounded-full mr-2"
                      />
                    </Link>
                    <div className="pr-8">
                      <Link to={`/shop/preview/${data.shop._id}`}>
                        <h3 className={`${styles.shop_name} pb-1 pt-1`}>
                          {data?.shop.name}
                        </h3>
                      </Link>

                      <h5 className="pb-3 text-[15px]">
                        ({averageRating}/5) Ratings
                      </h5>
                    </div>
                    {user && (
                      <div
                        className={`${styles.button} bg-[#6443d1] mt-4 !rounded !h-11`}
                        onClick={handleMessageSubmit}
                      >
                        <span className="text-white flex items-center">
                          Send Message <AiOutlineMessage className="ml-1" />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <ProductDetailsInfo
              data={data}
              avatarUrl={avatarUrl}
              products={products}
              totalReviewsLength={totalReviewsLength}
              averageRating={averageRating}
            />
            <br />
            <br />
          </div>
        </Helmet>
      ) : null}
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  seller,
  products,
  totalReviewsLength,
  averageRating,
  avatarUrl,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className="bg-[#f5f6fb] px-3 800px:px-10 py-2 rounded">
      <div className="w-full flex justify-between border-b pt-10 pb-2">
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(1)}
          >
            Product Details
          </h5>
          {active === 1 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(2)}
          >
            Product Reviews
          </h5>
          {active === 2 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(3)}
          >
            Seller Information
          </h5>
          {active === 3 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
      </div>
      {active === 1 ? (
        <>
          <div className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
            <EditorComponent description={data.description} />
          </div>
        </>
      ) : null}

      {active === 2 ? (
        <div className="w-full min-h-[40vh] flex flex-col items-center py-3 overflow-y-auto">
          {data &&
            data.reviews.map((item, index) => (
              <div className="w-full flex my-2">
                {avatarUrl.includes("gravatar.com") ? (
                  <img
                    src={avatarUrl}
                    className="w-[35px] h-[35px] rounded-full"
                    alt="User Profile"
                  />
                ) : (
                  <img
                    src={avatarUrl}
                    className="w-[35px] h-[35px] rounded-full"
                    alt="User Profile"
                  />
                )}
                <div className="pl-2 ">
                  <div className="w-full flex items-center">
                    <h1 className="font-[500] mr-3">{item.user.name}</h1>
                    <Ratings rating={data?.ratings} />
                  </div>
                  <p>{item.comment}</p>
                </div>
              </div>
            ))}

          <div className="w-full flex justify-center">
            {data && data.reviews.length === 0 && (
              <h5>No one has Reviewed this Product Yet!</h5>
            )}
          </div>
        </div>
      ) : null}

      {active === 3 && (
        <div className="w-full block 800px:flex p-5">
          <div className="w-full 800px:w-[50%]">
            <div className="flex items-center">
              <Link to={`/shop/preview/${data.shop._id}`}>
                <img
                  src={`${backend_url}${data?.shop?.avatar}`}
                  className="w-[50px] h-[50px] rounded-full"
                  alt=""
                />
              </Link>
              <div className="pl-3">
                <Link to={`/shop/preview/${data.shop._id}`}>
                  <h3 className={`${styles.shop_name}`}>{data?.shop.name}</h3>
                </Link>
                <h5 className="pb-2 text-[15px]">
                  ({averageRating}/5) Ratings
                </h5>
              </div>
            </div>
            <p className="pt-2">{seller?.description}</p>
          </div>
          <div className="w-full 800px:w-[50%] mt-5 800px:mt-0 800px:flex flex-col items-end">
            <div className="text-left">
              <h5 className="font-[600]">
                Joined on:{" "}
                <span className="font-[500]">
                  {data.shop?.createdAt?.slice(0, 10)}
                </span>
              </h5>
              <h5 className="font-[600] pt-3">
                Total Products:{" "}
                <span className="font-[500]">
                  {products && products.length}
                </span>
              </h5>
              <h5 className="font-[600] pt-3">
                Total Reviews:{" "}
                <span className="font-[500]">{totalReviewsLength}</span>
              </h5>
              <Link to={`/shop/preview/${data.shop._id}`}>
                <div
                  className={`${styles.button} !rounded-[4px] !h-[39.5px] mt-3`}
                >
                  <h4 className="text-white">Visit Shop</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
