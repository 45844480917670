import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { backend_url } from "../../../server";
import styles from "../../../styles/styles";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFromWishlist,
  addToWishlist,
} from "../../../redux/actions/wishlist";
import { addToCart } from "../../../redux/actions/cart";
import Ratings from "../../Products/Ratings";
import { FaStar } from "react-icons/fa";
import { showToastError, showToastSuccess } from "../../../static/toastMessage";
import Loader from "../../Layout/Loader";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  if (!data) {
    <Loader />;
  }

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    showToastError(`${data.name} has been removed from wishlist successfully!`);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    showToastSuccess(`${data.name} has been added to wishlist successfully!`);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExist = cart && cart.find((i) => i._id === id);
    if (isItemExist) {
      showToastError(`${data.name} is already in the cart!`);
    } else {
      if (data.stock < 1) {
        showToastError(`${data.stock} are only in stocks!`);
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addToCart(cartData));
        showToastSuccess(`${data.name} added to Cart Succesfully!`);
      }
    }
  };

  const prices = data.priceData.map((item) => {
    const numericValue = parseInt(
      item.cost.replace(/^[A-Za-z]+|\(.*?\)/g, "").match(/\d+/)[0]
    );
    return numericValue;
  });

  const dataTags = data?.tags.split(",").map((tag) => tag.trim());

  let lowestPrice;
  let highestPrice;

  if (dataTags.includes("Razer_Direct")) {
    lowestPrice = "0.01";
    highestPrice = "♾";
  } else {
    lowestPrice = Math.min(...prices);
    highestPrice = Math.max(...prices);
  }

  return (
    <>
      {data.status === "ON" ? (
        <>
          <div className="w-full h-[320px] bg-white rounded-lg shadow-md p-3 relative cursor-pointer hover:shadow-xl">
            <Link
              to={`${
                isEvent === true
                  ? `/product/${data._id}?isEvent=true`
                  : `/product/${data._id}`
              }`}
              reloadDocument
            >
              <div className="flex items-center justify-center">
                <img
                  src={`${backend_url}${data.images && data.images[0]}`}
                  alt=""
                  className="h-[170px] object-contain rounded-lg"
                />
              </div>
              {data.featured === "YES" && data.sales === "YES" ? (
                <>
                  <div className="absolute top-0 right-0 flex justify-between">
                    <span className="text-[#fff] font-[500] bg-[#178e58] rounded-bl-md rounded-tr-md text-[13px] py-[2px] pr-[6px] pl-[6px] ml-2 uppercase">
                      On Sale!
                    </span>
                  </div>

                  <div className="absolute top-0 w-full right-2 flex justify-between">
                    <span className="text-[#fff] font-[500] bg-[#e06500] rounded-br-md rounded-tl-md text-[13px] py-[2px] pr-[6px] pl-[6px] ml-2 uppercase">
                      Featured!
                    </span>
                  </div>
                </>
              ) : data.sales === "YES" ? (
                <div className="absolute top-0 w-full right-2 flex justify-between">
                  <span className="text-[#fff] font-[500] bg-[#178e58] rounded-br-md rounded-tl-md text-[13px] py-[2px] pr-[6px] pl-[6px] ml-2 uppercase">
                    On Sale!
                  </span>
                </div>
              ) : data.featured === "YES" ? (
                <div className="absolute top-0 w-full right-2 flex justify-between">
                  <span className="text-[#fff] font-[500] bg-[#e06500] rounded-br-md rounded-tl-md text-[13px] py-[2px] pr-[6px] pl-[6px] ml-2 uppercase">
                    Featured!
                  </span>
                </div>
              ) : null}
            </Link>
            <h5 className={`${styles.shop_name}`}></h5>
            <Link
              to={`${
                isEvent === true
                  ? `/product/${data._id}?isEvent=true`
                  : `/product/${data._id}`
              }`}
              reloadDocument
            >
              <h4 className="pb-3 font-[500]">
                {data.name.length > 40
                  ? data.name.slice(0, 40) + "..."
                  : data.name}
              </h4>

              <div className="flex">
                <Ratings rating={data?.ratings} />
              </div>

              <div className="py-2 flex items-center justify-between">
                <div className="flex">
                  <h5 className={`${styles.productDiscountPrice}`}>
                    Rs {lowestPrice} - Rs {highestPrice}
                  </h5>
                </div>
                <span
                  className={`font-[400] text-[12px] text-[#68d284 ${
                    data?.stock === 0 ? "text-[#d55b45]" : "text-[#68d284]"
                  }`}
                >
                  {data.stock} Stock
                </span>
              </div>
            </Link>

            {/* side options */}
            <div>
              {click ? (
                <AiFillHeart
                  size={22}
                  className="cursor-pointer absolute right-2 top-5"
                  onClick={() => removeFromWishlistHandler(data)}
                  color={click ? "red" : "#333"}
                  title="Remove from wishlist"
                />
              ) : (
                <AiOutlineHeart
                  size={22}
                  className="cursor-pointer absolute right-2 top-5"
                  onClick={() => addToWishlistHandler(data)}
                  color={click ? "red" : "#333"}
                  title="Add to wishlist"
                />
              )}
              <AiOutlineEye
                size={22}
                className="cursor-pointer absolute right-2 top-14 max-md:hidden max-sm:hidden"
                onClick={() => setOpen(!open)}
                color="#333"
                title="Quick view"
              />
              {open ? (
                <ProductDetailsCard setOpen={setOpen} data={data} />
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <h4>No Products found!</h4>
        </>
      )}
    </>
  );
};

export default ProductCard;
