import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";

const Hero = () => {
  const slides = [
    {
      url: "https://cdn.blankgamingstore.com/hero-images/hero-img-01.webp",
    },
    {
      url: "https://cdn.blankgamingstore.com/hero-images/hero-img-02.webp",
    },
    {
      url: "https://cdn.blankgamingstore.com/hero-images/hero-img-03.webp",
    },

    {
      url: "https://cdn.blankgamingstore.com/hero-images/hero-img-04.webp",
    },
    {
      url: "https://cdn.blankgamingstore.com/hero-images/hero-img-05.webp",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div
      className={`justify-center max-[386px]:h-[18vh] max-[430px]:h-[26vh] max-[723px]:h-[40vh] max-[1100px]:h-[50vh] 1100px:h-[70vh] w-full mt-2 m-auto pt-1 mb-1 px-8 relative group`}
    >
      <div
        style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
        className="w-full h-full rounded-2xl max-[386px]:h-[100px] max-[430px]:h-[150px] max-[723px]:h-[250px] max-[1100px]:w-full max-[1100px]:h-[300px] bg-center bg-cover duration-500"
      >
        <div className="flex bottom-7 left-[50%] absolute">
          {slides.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className="text-2xl cursor-pointer relative left-[-50%]"
            >
              <RxDotFilled color="white" />
            </div>
          ))}
        </div>
      </div>
      {/* Left Arrow */}
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
    </div>
  );
};

export default Hero;
