import axios from "axios";
import { server } from "../../server";

// Create Product
export const createProduct = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "productCreateRequest",
    });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${server}/product/create-product`,
      newForm,
      config
    );
    dispatch({
      type: "productCreateSuccess",
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: "productCreateFail",
      payload: error.response.data.message,
    });
  }
};

// Get All Products of The Shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });

    const { data } = await axios.get(
      `${server}/product/get-all-products-shop/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Product of The Shop
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteProductRequest",
    });

    const { data } = await axios.delete(
      `${server}/product/delete-shop-product/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteProductSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });
  }
};

// Get All Products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });

    const { data } = await axios.get(`${server}/product/get-all-products`);
    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};

// Get All Products For Admin
export const getAllProductsAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: "adminAllProductsRequest",
    });

    const { data } = await axios.get(`${server}/product/admin-all-products`, {
      withCredentials: true,
    });

    dispatch({
      type: "adminAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "adminAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Product of The Shop
export const editProduct = (id, updatedForm) => async (dispatch) => {
  const boundary = Math.random().toString().substr(2);

  try {
    dispatch({
      type: "editProductRequest",
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data; boundary=${boundary}",
      },
      withCredentials: true,
    };

    const { data } = await axios.put(
      `${server}/product/edit-product/${id}`,
      updatedForm,
      config
    );

    dispatch({
      type: "editProductSuccess",
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: "editProductFail",
      payload: error.response.data.message,
    });
  }
};
