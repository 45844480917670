import React, { useEffect } from "react";
import {
  Editor,
  EditorState,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import draftToHtml from "draftjs-to-html";

const LinkDecorator = {
  strategy: (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  },
  component: (props) => {
    const { url, targetOption } = props.contentState
      .getEntity(props.entityKey)
      .getData();
    return (
      <a href={url} target={targetOption} rel="noopener noreferrer">
        {props.children}
      </a>
    );
  },
};

const EditorComponent = ({ description }) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  useEffect(() => {
    const parsedContent = convertFromRaw(JSON.parse(description));
    const decorator = new CompositeDecorator([LinkDecorator]);
    const newEditorState = EditorState.createWithContent(
      parsedContent,
      decorator
    );
    setEditorState(newEditorState);
  }, [description]);

  const customStyleMap = {
    "color-rgb(65,168,95)": {
      color: "rgb(65, 168, 95)",
    },
    "color-rgb(44,130,201)": {
      color: "rgb(44, 130, 201)",
    },
    UNDERLINE: {
      textDecoration: "underline",
    },
  };

  const readOnlyProps = {
    readOnly: true,
    handleKeyCommand: () => null,
    onFocus: () => null,
    onBlur: () => null,
    customStyleMap: customStyleMap,
  };

  return (
    <div>
      <Editor editorState={editorState} {...readOnlyProps} />
    </div>
  );
};

export default EditorComponent;
