import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ResetPassword from "../components/Login/ResetPassword";

const ResetPasswordPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <ResetPassword />
    </div>
  );
};

export default ResetPasswordPage;
