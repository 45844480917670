import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BsPencil } from "react-icons/bs";
import EditProduct from "./EditProduct";
import { getAllProductsShop } from "../../redux/actions/product";
import { deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import ShopHelmet from "../../helmet/shopHelmet";

const AllProducts = () => {
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [editProductData, setEditProductData] = useState();

  const handleDelete = (id) => {
    dispatch(deleteProduct(id));
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  const columns = [
    {
      field: "id",
      headerAlign: "center",
      headerName: "Product Id",
      align: "center",
      minWidth: 120,
      flex: 1.0,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      minWidth: 180,
      flex: 1.1,
    },
    {
      field: "price",
      headerName: "Price (Rs)",
      headerAlign: "center",
      align: "center",
      minWidth: 40,
      flex: 0.7,
    },
    {
      field: "stock",
      headerName: "Stock",
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 40,
      flex: 0.7,
    },

    {
      field: "sold",
      headerName: "Sold Out",
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 20,
      flex: 0.6,
    },
    {
      field: "preview",
      flex: 0.5,
      minWidth: 40,
      headerName: "Preview",
      headerAlign: "center",
      align: "center",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "edit",
      flex: 0.4,
      minWidth: 40,
      headerName: "Edit",
      headerAlign: "center",
      align: "center",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => setOpen(true) || setEditProductData(params)}>
              <BsPencil size={18} />
            </Button>
          </>
        );
      },
    },
    {
      field: "delete",
      flex: 0.5,
      minWidth: 40,
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 50,
      hide: true,
    },
    {
      field: "featured",
      headerName: "Featured",
      width: 50,
      hide: true,
    },
    {
      field: "originalPrice",
      headerName: "Original Price",
      width: 50,
      hide: true,
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 50,
      hide: true,
    },
    {
      field: "discountPrice",
      headerName: "Discount Price",
      width: 50,
      hide: true,
    },
    {
      field: "sellerId",
      headerName: "Seller Id",
      width: 50,
      hide: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: 50,
      hide: true,
    },
    {
      field: "sales",
      headerName: "Sales",
      width: 50,
      hide: true,
    },
    {
      field: "playStoreLink",
      headerName: "Play Store Link",
      width: 50,
      hide: true,
    },
    {
      field: "appStoreLink",
      headerName: "App Store Link",
      width: 50,
      hide: true,
    },
    {
      field: "quickDescription",
      headerName: "Quick Description",
      width: 50,
      hide: true,
    },
  ];

  const row = [];

  products &&
    products.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: item.priceData[0].cost,
        stock: item.stock,
        sales: item.sales,
        status: item.status,
        playStoreLink: item.playStoreLink,
        appStoreLink: item.appStoreLink,
        sold: item.sold_out,
        originalPrice: item.priceData,
        description: item.description,
        quickDescription: item.quickDescription,
        featured: item.featured,
        tags: item.tags,
        discountPrice: item.discountPrice,
        category: item.category,
        sellerId: item.shopId,
        formFields: item.formFields,
        images: item.images,
      });
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ShopHelmet title={seller.name} page="All Products">
            <div className="w-full mx-8 pt-1 mt-10 bg-white">
              <DataGrid
                rows={row}
                columns={columns}
                pageSize={10}
                disableSelectionOnClick
                autoHeight
              />
            </div>
          </ShopHelmet>
          {open && (
            <ShopHelmet title={seller.name} page="Edit Product">
              <EditProduct setOpen={setOpen} data={editProductData} />
            </ShopHelmet>
          )}
        </>
      )}
    </>
  );
};

export default AllProducts;
