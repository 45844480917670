import React, { useEffect } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import Helmet from "../helmet/helmet";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Helmet title="Contact Us">
      <div>
        <Header />
        <Contact />
        {/* <ContactForm /> */}
        <Footer />
      </div>
    </Helmet>
  );
};

const Contact = () => {
  return (
    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Contact Us</h2>

      <div className="flex mx-[12px] mt-[12px]">
        <h2 className="text-2xl text-gray-900 mb-8">
          Email:{" "}
          <a href="mailto:support@blankgamingstore.com">
            <strong>support@blankgamingstore.com</strong>
          </a>
        </h2>
      </div>

      <div className="flex mx-[12px]">
        <h2 className="text-2xl text-gray-900 mb-8">
          Phone: <strong>+977 9863481705</strong>
        </h2>
      </div>

      <div className="flex mx-[12px]">
        <h2 className="text-2xl text-gray-900 mb-8">
          Live Chat:{" "}
          <Link to="/inbox">
            <strong>Chat with Seller/Admin</strong>
          </Link>
        </h2>
      </div>
    </div>
  );
};

export default ContactUsPage;
