import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineEye } from "react-icons/ai";
import { getAllEventsAdmin } from "../../redux/actions/event";
import Loader from "../Layout/Loader";
import UserHelmet from "../../helmet/userHelmet";

const AllEvents = () => {
  const { adminEvents, adminEventLoading } = useSelector(
    (state) => state.events
  );

  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEventsAdmin());
  }, []);

  const columns = [
    { field: "id", headerName: "Event Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Stock",
      headerName: "Stock",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "sold",
      headerName: "Sold out",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.id}?isEvent=true`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  adminEvents &&
    adminEvents.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: "Rs " + item.discountPrice,
        Stock: item.stock,
        sold: item.sold_out,
      });
    });

  return (
    <>
      {adminEventLoading ? (
        <Loader />
      ) : (
        <UserHelmet title={user?.name} page="All Events">
          <div className="w-full min-h-[45vh] pt-5 rounded flex justify-center">
            <div className="w-[97%]">
              <h3 className="text-[22px] font-Poppins pb-2">All Events</h3>
              <div className="w-full min-h-[45vh] bg-white rounded">
                <DataGrid
                  rows={row}
                  columns={columns}
                  pageSize={10}
                  disableSelectionOnClick
                  autoHeight
                />
              </div>
            </div>
          </div>
        </UserHelmet>
      )}
    </>
  );
};

export default AllEvents;
