import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import EventCard from "./EventCard";

const Events = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);

  return (
    <div>
      {!isLoading && (
        <>
          {allEvents.length !== 0 && (
            <div className="w-full grid">
              <div className={`${styles.section}`}>
                <div className={`${styles.heading}`}>
                  <h1>Popular Events</h1>
                </div>
                <EventCard data={allEvents && allEvents[0]} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Events;
