import React, { useEffect, useState } from "react";
import Helmet from "../../helmet/helmet";
import axios from "axios";
import { server } from "../../server";
import FonepayPaymentQr from "../../assets/images/Payments/FonepayPaymentQr.png";
import { RxAvatar } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { fonepayPaymentImage } from "../../redux/actions/payment";
import { showToastError } from "../../static/toastMessage";
import LoadingOverlay from "../Layout/LoadingOverlay";

const Fonepay = () => {
  const [userNumber, setUserNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [orderId, setOrderId] = useState("");
  const [images, setImages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      var currentURL = document.URL;

      const esewaTransId = currentURL.split("?")[1].split("=")[1];

      const body = {
        transId: esewaTransId,
      };

      const fonepayInfo = axios
        .post(`${server}/payment/esewa/getInfo`, body)
        .then((res) => {
          setUserNumber(res.data.esewaPayment.userPhone);
          setAmount(res.data.esewaPayment.totalPrice);
          setTransactionId(res.data.esewaPayment.transactionId);
          setOrderId(res.data.esewaPayment.orderId);
          setCurrentTime(res.data.esewaPayment.timestamp);
        });
    } catch (error) {
      showToastError(error);
    }
  });

  const handleImageChange = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      var currentURL = document.URL;

      const transactionId = currentURL.split("?")[1].split("=")[1];

      const newForm = new FormData();

      const config = { headers: { "Content-Type": "multipart/form-data" } };

      if (images.length === 0) {
        showToastError("Please Upload The Transaction Image!");
        setIsLoading(false);
      } else if (images.length > 1) {
        showToastError("Please Upload Only One Image!");
        setIsLoading(false);
      } else {
        setIsLoading(true);
        images.forEach((image) => {
          newForm.append("images", image);
        });
        newForm.append("transactionId", transactionId);

        const res = await axios.post(
          `${server}/payment/esewa/confirmPayment`,
          newForm,
          config
        );

        window.location.href = `${res.data.successURL}${res.data.esewaPayment.transactionId}`;
      }
    } catch (error) {
      showToastError(error);
      setIsLoading(false);
    }
  };

  const handleCancelPayment = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      var currentURL = document.URL;

      const esewaTransId = currentURL.split("?")[1].split("=")[1];

      const body = {
        transId: esewaTransId,
      };

      const resData = await axios.post(
        `${server}/payment/esewa/cancelPayment`,
        body
      );

      window.location.href = `${resData.data.cancelURL}${resData.data.esewaPayment.transactionId}`;
    } catch (error) {
      showToastError(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <Helmet title="Esewa Payment">
        <div>
          <section className="antialiased bg-gray-100 text-gray-600 min-h-screen p-4">
            <div className="h-full">
              <div>
                <div
                  className="relative px-4 sm:px-6 lg:px-8 pb-8 max-w-lg mx-auto"
                  x-data="{ card: true }"
                >
                  <div className="bg-white px-8 pb-6 rounded-b shadow-lg">
                    <div className="text-center mb-6">
                      <h1 className="pt-4 text-xl leading-snug text-gray-800 font-semibold mb-2">
                        <strong>PLEASE FOLLOW THE INSTRUCTION BELOW</strong>
                      </h1>
                      <div className="flex justify-center mt-4">
                        <div className="relative flex w-full p-1 bg-gray-50 rounded">
                          <span className="relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2">
                            OrderID: <strong>{orderId}</strong>
                          </span>
                        </div>
                      </div>
                      <div x-show="card">
                        <div className="mt-4">
                          <div className="mb-1">
                            <div className="font-medium text-sm inline-flex items-center justify-center px-3 py-2 border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out w-full bg-indigo-500 hover:bg-indigo-600 text-white focus:outline-none focus-visible:ring-2">
                              The Amount to be paid is Rs {amount}
                            </div>
                          </div>
                          <div className="text-xs text-gray-500 italic text-center">
                            It can be paid using any Wallet and/or Mobile
                            Banking. You'll need to include your order id or
                            your name in the remarks. Failure to mention any may
                            result in cancellation of order and ineligibility
                            for refund.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-center mb-6">
                      <img
                        className="inline-flex justify-center items-center"
                        src={FonepayPaymentQr}
                        width="200"
                        height="200"
                        alt="User"
                      />
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label className="pb-2">
                          Upload The QR <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="file"
                          name=""
                          id="upload"
                          className="hidden"
                          multiple
                          onChange={handleImageChange}
                        />
                        <div className="w-full flex items-center flex-wrap">
                          <label htmlFor="upload">
                            <AiOutlinePlusCircle
                              size={30}
                              className="mt-3 cursor-pointer"
                              color="#555"
                            />
                          </label>
                          {images &&
                            images.map((i) => (
                              <img
                                src={URL.createObjectURL(i)}
                                key={i}
                                alt=""
                                className="h-[120px] w-[120px] object-cover m-2"
                              />
                            ))}
                        </div>
                      </div>
                      <br />
                      <div>
                        <input
                          type="submit"
                          value="Confirm Now"
                          disabled={isLoading}
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 cursor-pointer"
                        />
                      </div>
                      <br />
                      <div>
                        <input
                          type="button"
                          value="Cancel"
                          disabled={isLoading}
                          onClick={handleCancelPayment}
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 cursor-pointer"
                        />
                      </div>
                    </form>
                    <div className="text-xs mt-6">
                      Payment Requested Time: {currentTime}.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Helmet>
    </>
  );
};

export default Fonepay;
