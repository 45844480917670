import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import { AiOutlineClockCircle } from "react-icons/ai";
import Helmet from "../helmet/helmet";

const TermsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Helmet title="Terms & Conditions">
      <div>
        <Header />
        <Terms />
        <Footer />
      </div>
    </Helmet>
  );
};

const Terms = () => {
  return (
    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">
        Terms and Conditions
      </h2>

      <div className="mx-auto space-y-4">
        {/* Introduction */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Introduction
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              These Website Standard Terms and Conditions written on this
              webpage shall manage your use of our website, Blank Gaming Store
              accessible at{" "}
              <Link to="https://blankgamingstore.com">
                <strong>https://blankgamingstore.com</strong>.
              </Link>
              <br />
              <br />
              These Terms will be applied fully and affect to your use of this
              Website. By using this Website, you agreed to accept all terms and
              conditions written in here. You must not use this Website if you
              disagree with any of these Website Standard Terms and Conditions.
              <br />
              <br />
              Minors or people below 18 years old are not allowed to use this
              Website without proper supervision of parents. In case of
              negligence, Blank Gaming Store isn't liable.
              <br />
              <br />
              The term “Blank Gaming Store” or “Manjushree Pustak Griha” or “we”
              or “our” or “us” refers Blank Gaming Store and the term “you”
              refers to the user or viewer of this Platform and/or Service.
            </p>
          </div>
        </div>

        {/* Definitions */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Definitions
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              In these Terms of Service, the following words and expression
              shall have the following meanings unless the context otherwise
              requires:
              <br />
              <br />
              <p>
                <li className="px-4">
                  <strong>Account</strong>: means your account duly registered
                  with Blank Gaming Store to facilitate you using the Services
                  available on the Platform (or any of them);
                </li>
                <li className="px-4">
                  <strong>Buyer</strong>: means a person who purchases items on
                  the Platform;
                </li>
                <li className="px-4">
                  <strong>Gift Card</strong>: means an electronic gift card
                  purchased on any of the Platform which contains a stored value
                  available for redemption on the Platform;
                </li>
                <li className="px-4">
                  <strong>CD-Key</strong>: means an electronic key which is used
                  to activate a video game;
                </li>
                <li className="px-4">
                  <strong>Mobile Credits</strong>: means mobile phone credits;
                </li>
                <li className="px-4">
                  <strong>Items</strong>: means the goods and services listed by
                  you for sale on the Platform and “Item” shall mean any one of
                  them;
                </li>
                <li className="px-4">
                  <strong>Parties</strong>: means collectively, Blank Gaming
                  Store and you and “Party” shall mean any one of them;
                </li>
                <li className="px-4">
                  <strong>Platform</strong>: means collectively, the web
                  Platform presently known as Blank Gaming Store
                  (https://blankgamingstore.com), and/or such other web Platform
                  of Blank Gaming Store administered and managed by Blank Gaming
                  Store;
                </li>
                <li className="px-4">
                  <strong>Services</strong>: means the services provided by
                  Blank Gaming Store on the Platform, including, without
                  limitation, reselling and retailing online games and related
                  merchandises, games publishing, online marketplace for sale of
                  Items, subject to the conditions in these Terms of Service;
                </li>
                <li className="px-4">
                  <strong>Purchased Item</strong>: means your item listed on the
                  Platform purchased by the Buyer;
                </li>
                <li className="px-4">
                  <strong>BGS Credits</strong>: means the electronic credit
                  value available in the Account at any time;
                </li>
                <li className="px-4">
                  <strong>Cashback</strong>: means a certain percentage of
                  amount credited to your account when a certain product is
                  purchased;
                </li>
                <li className="px-4">
                  <strong>Terms of Service</strong>: means these Terms of
                  Service governing the use of the Services by you as may be
                  amended at any time and from time to time as and when Blank
                  Gaming Store shall in its absolute discretion deems necessary
                  and shall include: (i) any rules, procedures, Terms of Service
                  for products, services or facilities, as determined by Blank
                  Gaming Store from time to time; and (ii) any documents,
                  directives, correspondence and agreements referred to in these
                  Terms of Service and forming a part hereof, together with any
                  amendments made at any time or from time to time to any of the
                  foregoing; and
                </li>
                <li className="px-4">
                  <strong>Virtual Items</strong>: means collectively, virtual
                  in-game items and virtual currency, including, without
                  limitation, virtual coins, tokens or points acquired in the
                  course of playing games on the Platform.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* License to Use */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              License to Use
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              In consideration of you agreeing to these Terms of Service and
              your continuing observance and compliance of these Terms of
              Service, Blank Gaming Store hereby grants you a non-exclusive,
              non-transferable license to access the Platform and use the
              Services upon the terms and subject to the conditions stated
              herein.
            </p>
          </div>
        </div>

        {/* Representations and Warranties */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Representations and Warranties
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              Each time when you access the Platform (or any of them), you
              irrevocably and unconditionally represents and warrants that: you
              are above 13 years old. Should you be less than 13 years old, your
              parents are aware and have consented to you accessing the Platform
              and using the Services;
              <br />
              <br />
              <p>
                <li className="px-4">
                  your personal information and the documentation submitted in
                  this respect, including, without limitation, your full name,
                  telephone number, correspondence address and email address,
                  are true and accurate. You shall forthwith notify us in
                  writing of any changes in your personal information;
                </li>
                <li className="px-4">
                  you shall keep the password to the Account secure and
                  confidential. You shall not at any time and under any
                  circumstances reveal or disclose your password to the Account
                  to any unauthorized party and shall take all steps to prevent
                  the disclosure of the password to the Account to any
                  unauthorized party;
                </li>
                <li className="px-4">
                  you shall not use the Platform or the Services (or any of
                  them) to conduct any fraudulent, immoral or illegal activities
                  or such activities that may infringe the intellectual property
                  rights of third parties;
                </li>
                <li className="px-4">
                  you shall not use any intellectual property belonging to Blank
                  Gaming Store or any publishers listed on the Platform,
                  including, without limitation, trademarks or trade names,
                  whether registered or not, without the prior written consent
                  of Blank Gaming Store;
                </li>
                <li className="px-4">
                  you shall not be disruptive, be offensive or be a nuisance in
                  any manner whatsoever to other users of the Platform or the
                  employees of Blank Gaming Store; and
                </li>
                <li className="px-4">
                  you shall not directly or indirectly recruit and/or poach any
                  customers obtained from the usage of the Platform.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Use of Services */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Use of Services{" "}
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  You shall procure, at your own costs and expenses, the
                  requisite equipment and software to connect and access the
                  Platform and the ensuing use of the Services. You shall bear
                  all charges and fees imposed by third parties in relation to
                  and in connection with you connecting your equipment to the
                  Platform (or any of them).
                </li>
                <br />
                <li className="px-4">
                  It is your primary responsibility to ensure that you are
                  acquainted with the guidelines and procedures for the use of
                  the Services that Blank Gaming Store may issue from time to
                  time. Blank Gaming Store shall not be liable for any errors,
                  losses or damages caused by your use of the Services.
                </li>
                <br />
                <li className="px-4">
                  It is your responsibility to secure the information of your
                  Account. Any notification or confirmation received by Blank
                  Gaming Store from your Account shall be deemed to have been
                  issued by you not withstanding that such notification or
                  confirmation may have been issued by a third party, whether
                  authorized or otherwise, and you shall be bound by such
                  notification or confirmation.
                </li>
                <br />
                <li className="px-4">
                  Blank Gaming Store shall not be liable for acting on the
                  notification or confirmation sent through your Account. Blank
                  Gaming Store shall not be obliged to investigate the
                  authenticity or authority of persons effecting the
                  notification or confirmation or verify the completeness of
                  such notification or confirmation. Such notification or
                  confirmation shall be deemed irrevocable and binding on you
                  upon receipt by Blank Gaming Store. Not-Withstanding any
                  error, fraud, forgery and lack of clarity or misunderstanding
                  in respect of the terms of such notification or confirmation.
                  You shall immediately notify us.
                </li>
                <br />
                <li className="px-4">
                  You shall also immediately notify Blank Gaming Store upon
                  receipt of any data or information which is not intended for
                  you and you shall delete such data or information from your
                  Account.
                </li>
                <br />
                <li className="px-4">
                  You acknowledge and agree that Blank Gaming Store may at its
                  absolute discretion refuse or permit you to use the Services
                  without giving any reason or notice thereof.
                </li>
                <br />
                <li className="px-4">
                  Unless otherwise permitted by Blank Gaming Store in writing,
                  you shall not upload, post, email transmit or otherwise make
                  available any unauthorized or illegal activities on the
                  Platform or directly to other users of the Platform.
                </li>
                <br />
                <li className="px-4">
                  You shall not upload, post, email, transmit or in any other
                  manner whatsoever make available any material that contains
                  software viruses or any other computer code, files or programs
                  designed to interrupt, destroy or limit the functionality of
                  any computer software or hardware or telecommunication
                  equipment, including, without limitation, the Platform.
                </li>
                <br />
                <li className="px-4">
                  You irrevocably and unconditionally allow and permit Blank
                  Gaming Store to send to your Account updates on services and
                  events offered or provided by Blank Gaming Store.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Virtual Items */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Virtual Items
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  All Virtual Items reflected in the Account are not actually
                  owned by you.
                </li>
                <br />
                <li className="px-4">
                  You merely have a licence to use the Virtual Items on the
                  Platform (or any of them) in accordance with such terms as
                  Blank Gaming Store may impose from time to time.
                </li>
                <br />
                <li className="px-4">
                  The value of the Virtual Items reflected in the Account does
                  not represent any credit value in real currency. The Virtual
                  Items cannot be exchanged for real cash.
                </li>
                <br />
                <li className="px-4">
                  Unless otherwise permitted by Blank Gaming Store in writing,
                  you are prohibited from selling, transferring or otherwise
                  dispose of the Virtual Items to any other persons, whether
                  within or outside the confines of the Platform (or any of
                  them).
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Payment */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Payment
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  You may pay for the Services in such currency that is
                  available on the Platform in accordance with the prevailing
                  exchange rate determined by Blank Gaming Store at its absolute
                  discretion.
                </li>
                <br />
                <li className="px-4">
                  You shall be solely liable to make payments and applicable
                  taxes, if any, in relation to and in connection with the usage
                  of the Services (or any of them) through the Account.
                </li>
                <br />
                <li className="px-4">
                  Blank Gaming Store shall have the absolute discretion to
                  cancel any payment request made by you without assigning any
                  reasons whatsoever.
                </li>
                <br />
                <li className="px-4">
                  Blank Gaming Store shall be entitled to vary or modify or
                  remove the modes of payment available on the Platform (or any
                  of them) at any time without prior notice to you.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Availability of Services */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Availability of Services
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  The Services are usually available on a daily basis unless
                  otherwise specified herein.
                </li>
                <br />
                <li className="px-4">
                  There may be certain times that the Services may not be
                  available due to maintenance or malfunction of the Platform or
                  such other reasons beyond the control of Blank Gaming Store.
                  Blank Gaming Store makes no warranty that the Services will be
                  available at the times stated herein.
                </li>
                <br />
                <li className="px-4">
                  Unless otherwise provided in these Terms of Service, Blank
                  Gaming Store shall be entitled at its absolute discretion,
                  without notice to you, to change the procedures, the mode of
                  operation of the Services at any time and from time to time
                  for any reason whatsoever.
                </li>
                <br />
                <li className="px-4">
                  Notwithstanding any provisions to the contrary in these Terms
                  of Service, Blank Gaming Store shall be entitled to:
                  immediately terminate, discontinue, withdraw or suspend your
                  use of the Services without notice; or impose conditions or
                  restrictions on your use of the Service without notice.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Blank Gaming Store Website Wallet (WEBSITE WALLET) */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Blank Gaming Store Website Wallet (WEBSITE WALLET)
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  You must maintain sufficient balance in Blank Gaming Store
                  wallet in the Account before you use the Services.
                </li>
                <br />
                <li className="px-4">
                  Blank Gaming Store Credits can only be topped-up in the
                  Account through the payment methods listed on the Platform. If
                  you use other means than listed, Blank Gaming Store will not
                  be liable.
                </li>
                <br />
                <li className="px-4">
                  Blank Gaming Store wallet can be used to purchase any of the
                  Services or Items.
                </li>
                <br />
                <li className="px-4">
                  If there are insufficient Blank Gaming Store wallet when you
                  purchase any of the Services, Blank Gaming Store shall have
                  the absolute discretion to either: reject or suspend the
                  transaction; or require you to pay the shortfall through any
                  of the other methods of payment available on the Platform.
                </li>
                <br />
                <li className="px-4">
                  Blank Gaming Store shall, as and when it deems fit, amend,
                  modify, remove or add any Blank Gaming Store wallet balance or
                  Blank Gaming Store wallet account without prior notice to you.
                </li>
                <br />
                <li className="px-4">
                  The Blank Gaming Store wallet balance in the Account was very
                  safe and and if comormised the person may be suspended and
                  taken action by us.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Gift Card */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Gift Card
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  Each Gift Card must be redeemed in a single transaction by
                  purchasing any Services on any of the Platform. If the
                  transaction value is more than the Gift Card value, such Gift
                  Card value in excess of the transaction value will be credited
                  into the Account as Blank Gaming Store Credits. If the
                  transaction value is more than the Gift Card value, you are
                  required to settle the shortfall with the Blank Gaming Store
                  Credits available in the Account or such methods of payment
                  available on the Platform.
                </li>
                <br />
                <li className="px-4">
                  Each Gift Card is not transferable and cannot be exchanged
                  with cash.
                </li>
                <br />
                <li className="px-4">
                  Blank Gaming Store shall not be liable for any Gift Card that
                  is stolen, misplaced or misused without authorization. For the
                  avoidance of doubt, Blank Gaming Store shall not be obliged to
                  enquire or verify at the redemption of the Gift Card, if such
                  Gift Card has been properly obtained.
                </li>
                <br />
                <li className="px-4">
                  Notwithstanding Clause "Gift Card" - Third, if Blank Gaming
                  Store has any doubts as to the authenticity of a Gift Card,
                  Blank Gaming Store shall have the absolute discretion to
                  withhold, suspend or cancel such Gift Card without any prior
                  reference to you.
                </li>
                <br />
                <li className="px-4">
                  Each Gift Card shall be subject to such further rules and
                  regulations as Blank Gaming Store may introduce at any time or
                  from time to time.
                </li>
                <br />
                <li className="px-4">
                  Each Gift Card will expire at the given time period in the
                  email. If the time is not mentioned, The Gift Card shall not
                  expire.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Blank Gaming Store Reward Points (BGS Points) */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Blank Gaming Store Reward Points (BGS Points)
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  You will be rewarded with BGS Points for each purchase of
                  goods or services on the Platform (excluding the Items).
                </li>
                <br />
                <li className="px-4">
                  You may redeem the BGS Points for goods or services on the
                  Platform. The BGS Points required to redeem such good or
                  service shall be as stated in the Platform.
                </li>
                <br />
                <li className="px-4">
                  The redemption of the BGS Points shall be subject to such
                  further rules and regulations as Blank Gaming Store may
                  introduce at any time or from time to time.
                </li>
                <br />
                <li className="px-4">
                  BGS Point cannot and will not be exchanged for any real
                  currency. If found to do so, Blank Gaming Store has right to
                  terminate or remove your BGS Points without any prior notice.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Limitation on Liability */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Limitation on Liability
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              You agree and confirm that you shall not hold Blank Gaming Store,
              its employees, agents or licensees, liable for any special,
              incidental or consequential damages arising out of and in relation
              to the Services or these Terms of Service.
            </p>
          </div>
        </div>

        {/* Intellectual Property Rights */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Intellectual Property Rights
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              All copyrights, trademarks, service marks belong to the
              corresponding owners/ publishers/ developers and Blank Gaming
              Store is not related or associated to any of the said
              owners/publishers in any respect. All services offered on the
              Platform are offered by Blank Gaming Store, not the
              owners/publishers/developers.
            </p>
          </div>
        </div>

        {/* Reliability of Platforms */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Reliability of Platforms
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  You are aware that all transactions conducted on the Platform
                  are through telecommunication and data network.
                </li>
                <br />
                <li className="px-4">
                  You are fully aware that your receipt of the notification from
                  Blank Gaming Store and vice versa may be delayed or prevented
                  by factors affecting the relevant service providers and other
                  relevant parties. You accept that Blank Gaming Store cannot
                  guarantee the prompt delivery of such notification or
                  confirmation.
                </li>
                <br />
                <li className="px-4">
                  You acknowledge and confirm that you shall take all steps and
                  measures to check and verify the transaction history of your
                  Account.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Account */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Account
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  You shall immediately notify Blank Gaming Store if you are
                  aware or believe your Account has been hacked or compromised.
                </li>
                <br />
                <li className="px-4">
                  You shall be liable for all transactions conducted through
                  your Account at any time prior to the receipt by Blank Gaming
                  Store of your notification as stated in Clause "Account" -
                  First.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Disclosure of Information */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Disclosure of Information
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  Blank Gaming Store shall be entitled and you irrevocably and
                  unconditionally consents and authorises Blank Gaming Store to
                  the extent permitted by law, to disclose or release any
                  information pertaining to you or your transactions through the
                  Platform to such extent that Blank Gaming Store may at its
                  absolute discretion deem fit to: such persons as Blank Gaming
                  Store may be required to disclose under the applicable law;
                  such other persons or entity pursuant to any governmental
                  directive or order of the court; or any other party whomsoever
                  as Blank Gaming Store deems fit.
                </li>
                <br />
                <li className="px-4">
                  Save as otherwise permitted in Clause "Disclosure of
                  Information" - First, Blank Gaming Store will not disclose
                  your personal information to any other party without prior
                  notification to you.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Applicable Laws and Regulations */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Applicable Laws and Regulations
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  Your use of the Services shall be governed by the jurisdiction
                  where the contracting entity of Blank Gaming Store is
                  domiciled.
                </li>
                <br />
                <li className="px-4">
                  Where required, you shall obtain the approval or consent or
                  permission of the relevant regulatory authorities prior to
                  using the Services.
                </li>
                <br />
                <li className="px-4">
                  For cross-border transactions, you shall not violate the laws
                  existing in the countries involved in the transaction.
                </li>
                <br />
                <li className="px-4">
                  You shall fully indemnify, defend and hold Blank Gaming Store
                  and its related corporations harmless from and against any and
                  all suits, actions, judgements, damages, costs, losses,
                  expenses (including legal fees on a solicitors and client
                  basis) and other liabilities arising from a breach or
                  contravention or non-compliance with any provision of this
                  Clause "Applicable Laws and Regulations".
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Suspension, Termination, Cancellation of Services */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Suspension, Termination, Cancellation of Services
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  The Services (or any part thereof) may be cancelled by Blank
                  Gaming Store at any time without prior notice to you. After
                  cancellation, the Services (or any part thereof) may be
                  reinstated in such manner and on such Terms of Service as
                  Blank Gaming Store may at its absolute discretion determine.
                </li>
                <br />
                <li className="px-4">
                  Blank Gaming Store reserves the right at all times to suspend
                  or block access to and use of the Services (or any part
                  thereof) for any reason whatsoever and for any length of time
                  and upon any conditions that Blank Gaming Store may at its
                  absolute discretion determine.
                </li>
                <br />
                <li className="px-4">
                  Upon cancellation or termination of the Services (or any part
                  thereof):
                  <li className="px-4">
                    all rights granted to you here under shall immediately
                    terminate and shall revert to Blank Gaming Store;
                  </li>
                  <li className="px-4">
                    you shall immediately pay to Blank Gaming Store all
                    outstanding fees and charges due and owing to Blank Gaming
                    Store;
                  </li>
                  <li className="px-4">
                    Blank Gaming Store may at its absolute discretion, decide
                    not to act on any request received by Blank Gaming Store
                    rafter the effective date of termination; and
                  </li>
                  <li className="px-4">
                    Blank Gaming Store may at its absolute discretion, decide
                    not to act on any confirmation or request received by Blank
                    Gaming Store between the date of notice is given to you and
                    the effective date of termination (if there is a lapse of
                    time between the two dates); and you hereby irrevocably and
                    unconditionally authorizes Blank Gaming Store to deduct all
                    money due and owing by you to Blank Gaming Store (if any)
                    from the moneys that are payable by Blank Gaming Store to
                    you in respect of the delivered Purchased Items.
                  </li>
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Notices */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Notices
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  All notices and documents required to be given by you under
                  these Terms of Service to Blank Gaming Store shall be sent to
                  Blank Gaming Store by registered post to the Blank Gaming
                  Store address listed on the Platform.
                </li>
                <br />
                <li className="px-4">
                  Any notice or document sent by you to Blank Gaming Store shall
                  be deemed served when such notice or document is received by
                  Blank Gaming Store.
                </li>
                <br />
                <li className="px-4">
                  All notices and documents required to be given by Blank Gaming
                  Store under these Terms of Service to you shall be sent to you
                  by any one of the following methods: electronic mail to your
                  last known electronic mail address according to Blank Gaming
                  Store records; posting the notice or communication on the
                  Platform; notices placed with or in any of Blank Gaming Store
                  written communications to you; notices placed through any
                  media; or any manner of notification as Blank Gaming Store may
                  at its absolute discretion determine.
                </li>
                <br />
                <li className="px-4">
                  Any notice or document or communication given by Blank Gaming
                  Store to you shall be deemed to be served and received by you
                  on the day following the sending of such notice or document.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Waiver And Severance */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Waiver And Severance
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  Any failure by Blank Gaming Store to enforce at any time or
                  for any period any one or more of these Terms of Service shall
                  not be a waiver of them or of the right at any time
                  subsequently to enforce these Terms of Service.
                </li>
                <br />
                <li className="px-4">
                  In the event that any provisions of these Terms of Service is
                  declared by any judicial or other competent authority to be
                  void, voidable, illegal or otherwise unenforceable Blank
                  Gaming Store shall amend that provision in such reasonable
                  manner as would achieve the intention of Blank Gaming Store or
                  at the discretion of Blank Gaming Store it may be severed from
                  these Terms of Service and the remaining provisions remain in
                  full force and effect.
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Variation */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Variation
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                <li className="px-4">
                  These Terms of Service may be modified, added to, deleted or
                  varied by Blank Gaming Store by way of posting on the Platform
                  or in any such other manner as Blank Gaming Store may in its
                  absolute discretion determine.
                </li>
                <br />
                <li className="px-4">
                  You agree that continued use of the Services shall constitute
                  your acceptance of these Terms of Service (as modified and
                  varied from time to time).
                </li>
              </p>
            </p>
          </div>
        </div>

        {/* Assignment */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Assignment
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                You may not assign its rights under these Terms of Service
                without the prior written consent of Blank Gaming Store.
              </p>
            </p>
          </div>
        </div>

        {/* Binding Effect */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Binding Effect
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                These Terms of Service shall be binding on your heirs, personal
                and legal representatives, estate, successors-in-title and
                permitted assigns (where applicable) you.
              </p>
            </p>
          </div>
        </div>

        {/* Return Policy */}
        <div className="border-b border-gray-200 pb-4">
          <div className="text-center">
            <span className="text-lg font-medium text-gray-900 underline">
              Return Policy
            </span>
          </div>

          <div className="mt-4">
            <p className="text-base text-gray-500">
              <br />
              <p>
                Any form of Topup, Voucher Code, Voucher PIN, CD Key, Gift Card,
                Time Card, Expansion, BGS Credits, Game Credits, Pre-paid Card,
                Telco Credits, Phone Credits, Game Key purchased into your
                account is neither refundable nor exchangeable.
                <br />
                <br />
                Disclaimer:
                <br />
                <br />
                <p>
                  SoftPin
                  <br />
                  <br />
                  <li className="px-4">
                    Does not include manuals, Installation CDs, box, physical
                    card or other physical elements.
                  </li>
                  <li className="px-4">
                    You will be able to view your code(s) in your order
                    Transaction page after the completion of your order.
                  </li>
                  <li className="px-4">
                    Once you have completed the purchase for any Pre-Order, CD
                    Key, Time Card, Game Expansion, Game Point, Prepaid Card,
                    Trial/Beta/Item Code, Game Key from Blank Gaming Store, this
                    would mean you agree that all information you have entered
                    are accurate and correct.
                  </li>
                  <li className="px-4">
                    Any purchase of Pre-Order, CD Key, Time Card, Expansion,
                    Game Point, Prepaid Card, Trial/Beta/Item Code, Game Key
                    from Blank Gaming Store are non-refundable,
                    non-transferable, and not exchangeable once sold.
                  </li>
                  <li className="px-4">
                    Blank Gaming Store reserves the right to amend any of the
                    terms and conditions above without prior notice.
                  </li>
                </p>
                <br />
                <br />
                <p>
                  Direct Top Up
                  <br />
                  <br />
                  <li className="px-4">
                    Does not include manuals, Installation CDs, box, physical
                    card or other physical elements.
                  </li>
                  <li className="px-4">
                    Please read the product description thoroughly and confirm
                    that the game name, server, game user id, character name,
                    account name and the amount purchased, i.e. information, are
                    correct.
                  </li>
                  <li className="px-4">
                    Blank Gaming Store assumes no liability for wrong purchases
                    made by the customer due to negligence and/or false/wrong
                    information provided, which may result in damages/
                    losses/wrong top up. By purchasing any products from Blank
                    Gaming Store, the customer understands, acknowledges and
                    accepts this release of liability.
                  </li>
                  <li className="px-4">
                    If you encounter any difficulties, please do not hesitate to
                    contact our Customer Support for further assistance.
                  </li>
                </p>
              </p>
            </p>
          </div>
        </div>

        <div>
          <h2 className="text-1xl ext-gray-900 mb-8">
            For Further Questions and Queries. Contact us at:{" "}
            <a href="mailto:support@blankgamingstore.com">
              <strong>support@blankgamingstore.com</strong>
            </a>
          </h2>
          <div className="flex justify-end">
            <AiOutlineClockCircle className="mt-1 mr-1" />
            <h2 className="flex justify-end">Last Updated: May 29, 2023</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
