import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { backend_url, server } from "../../server";
import { AiOutlineCamera } from "react-icons/ai";
import styles from "../../styles/styles";
import axios from "axios";
import { loadSeller } from "../../redux/actions/user";
import { showToastError, showToastSuccess } from "../../static/toastMessage";
import ShopHelmet from "../../helmet/shopHelmet";

const ShopSettings = () => {
  const { seller } = useSelector((state) => state.seller);
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState(seller && seller.name);
  const [description, setDescription] = useState(
    seller && seller.description ? seller.description : ""
  );
  const [address, setAddress] = useState(seller && seller.address);
  const [phoneNumber, setPhoneNumber] = useState(seller && seller.phoneNumber);
  const [zipCode, setZipcode] = useState(seller && seller.zipCode);

  const email = seller?.email;

  const dispatch = useDispatch();

  const handleImage = async (e) => {
    const file = e.target.files[0];
    setAvatar(file);

    const formData = new FormData();

    formData.append("image", e.target.files[0]);

    await axios
      .put(`${server}/shop/update-shop-avatar`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((response) => {
        dispatch(loadSeller());
        showToastSuccess(
          `${seller.name} Profile Picture Updated Successfully!`
        );
      })
      .catch((error) => {
        showToastError(error.response.data.message);
      });
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/shop/update-shop-info`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          description,
        },
        { withCredentials: true }
      )
      .then((res) => {
        showToastSuccess(`${seller.name} Info Updated Successfully!`);
        dispatch(loadSeller());
      })
      .catch((error) => {
        showToastError(error.response.data.message);
      });
  };

  return (
    <ShopHelmet title={seller.name} page="Settings">
      <div className="w-full h-min flex flex-col items-center">
        <div className="flex w-full 800px:w-[80%] flex-col justify-center my-5">
          <div className="w-full flex items-center justify-center">
            <div className="relative">
              <img
                src={
                  avatar
                    ? URL.createObjectURL(avatar)
                    : `${backend_url}/${seller.avatar}`
                }
                alt=""
                className="w-[200px] h-[200px] rounded-full"
              />
              <label htmlFor="image" className="cursor-pointer">
                <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[15px]">
                  <input
                    type="file"
                    id="image"
                    className="hidden"
                    onChange={handleImage}
                  />
                  <AiOutlineCamera />
                </div>
              </label>
            </div>
          </div>

          {/* Shop Info */}
          <form
            aria-aria-required={true}
            className="flex flex-col items-center"
            onSubmit={updateHandler}
          >
            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-2">Shop Name</label>
              </div>
              <input
                type="name"
                placeholder={`${seller.name}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                required
              />
            </div>
            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-2">Shop description</label>
              </div>
              <input
                type="name"
                placeholder={`${
                  seller?.description
                    ? seller.description
                    : "Enter your shop description"
                }`}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              />
            </div>
            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-2">Shop Address</label>
              </div>
              <input
                type="name"
                placeholder={seller?.address}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                required
              />
            </div>

            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-2">Shop Phone Number</label>
              </div>
              <input
                type="number"
                placeholder={seller?.phoneNumber}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                required
              />
            </div>

            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-2">Shop Zip Code</label>
              </div>
              <input
                type="number"
                placeholder={seller?.zipCode}
                value={zipCode}
                onChange={(e) => setZipcode(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                required
              />
            </div>

            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <input
                type="submit"
                value="Update Shop"
                className={`${styles.input} bg-black text-[#fff] mb-4 !w-[95%] 800px:mb-0 cursor-pointer`}
                required
              />
            </div>
          </form>
        </div>
      </div>
    </ShopHelmet>
  );
};

export default ShopSettings;
