import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineDelete } from "react-icons/ai";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { server } from "../../server";
import { getAllUsers } from "../../redux/actions/user";
import UserHelmet from "../../helmet/userHelmet";
import { showToastSuccess } from "../../static/toastMessage";

const AllUsers = () => {
  const { users, user } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${server}/user/delete-user/${id}`, { withCredentials: true })
      .then((res) => {
        showToastSuccess(res.data.message);
      });

    dispatch(getAllUsers());
  };

  const columns = [
    { field: "id", headerName: "User ID", minWidth: 150, flex: 0.7 },

    {
      field: "name",
      headerName: "Name",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "role",
      headerName: "User Role",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "joinedAt",
      headerName: "Joined Date",
      type: "text",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "Delete User",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() =>
                setUserId(params.id) ||
                setUserRole(params.row.role) ||
                setUserName(params.row.name) ||
                setUserEmail(params.row.email) ||
                setOpen(true)
              }
            >
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];
  users &&
    users.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        email: item.email,
        role: item.role,
        joinedAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <div className="w-full flex justify-center pt-5">
      <div className="w-[97%]">
        <UserHelmet title={user?.name} page="All Users">
          <h3 className="text-[22px] font-Poppins pb-2">All Users</h3>
          <div className="w-full min-h-[45vh] bg-white rounded">
            <DataGrid
              rows={row}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              autoHeight
            />
          </div>
        </UserHelmet>
        {userRole === "admin" || userName === user?.name ? (
          <>
            {open && (
              <UserHelmet title={user?.name} page={`Admin Error`}>
                <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
                  <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
                    <div className="w-full flex justify-end cursor-pointer">
                      <RxCross1 size={25} onClick={() => setOpen(false)} />
                    </div>
                    <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                      Sorry, You cannot delete an admin.
                    </h3>
                    <h4 className="text-[20px] text-center py-5 font-Poppins text-[#000000cb]">
                      <span>Name: {userName}</span>
                      <br />
                      <span>Role: {userRole.toUpperCase()}</span>
                      <br />
                      <span>Email: {userEmail}</span>
                    </h4>
                    <div className="w-full flex items-center justify-center">
                      <div
                        className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </UserHelmet>
            )}
          </>
        ) : (
          <>
            {open && (
              <UserHelmet
                title={user?.name}
                page={`Confirm Details - ${userName}`}
              >
                <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
                  <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
                    <div className="w-full flex justify-end cursor-pointer">
                      <RxCross1 size={25} onClick={() => setOpen(false)} />
                    </div>
                    <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                      Confirm the details of user you are deleting.
                    </h3>
                    <h4 className="text-[20px] text-center py-5 font-Poppins text-[#000000cb]">
                      <span>Name: {userName}</span>
                      <br />
                      <span>Email: {userEmail}</span>
                    </h4>
                    <div className="w-full flex items-center justify-center">
                      <div
                        className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </div>
                      <div
                        className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                        onClick={() => setOpen(false) || handleDelete(userId)}
                      >
                        Confirm
                      </div>
                    </div>
                  </div>
                </div>
              </UserHelmet>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AllUsers;
