import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productData } from "../../static/data";
import styles from "../../styles/styles";
import ProductCard from "../Route/ProductCard/ProductCard";
import { useParams } from "react-router-dom";

const SuggestedProduct = ({ data }) => {
  const { allProducts } = useSelector((state) => state.products);
  const [relatedProducts, setRelatedProducts] = useState([]);

  const currentIdParams = useParams();

  useEffect(() => {
    if (allProducts && data.category) {
      const filteredProducts = allProducts.filter(
        (product) =>
          product.category === data.category &&
          product._id !== currentIdParams.id
      );
      setRelatedProducts(filteredProducts);
    }
  }, [allProducts, data.category, currentIdParams.id]);

  // const relatedProducts =
  //   productData &&
  //   productData.filter((product) => {
  //     return product._id !== currentIdParams.id;
  //   });

  return (
    <div>
      {data ? (
        <div className={`p-4 ${styles.section}`}>
          <h2
            className={`${styles.heading} text-[25px] font-[500] border-b mb-5`}
          >
            Related Product
          </h2>

          {relatedProducts.length > 0 ? (
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {relatedProducts.map((product, index) => (
                <ProductCard data={product} key={index} />
              ))}
            </div>
          ) : (
            <div className={`p-4 ${styles.section} flex justify-center`}>
              <h2
                className={`${styles.heading} text-[25px] font-[500] border-b mb-5`}
              >
                No Related Products Found!
              </h2>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SuggestedProduct;
