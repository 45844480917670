import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { backend_url, server } from "../../server";
import { showToastError, showToastSuccess } from "../../static/toastMessage";
import axios from "axios";
import ShopHelmet from "../../helmet/shopHelmet";
import Loader from "../Layout/Loader";
import LoadingOverlay from "../Layout/LoadingOverlay";
import { RxCross1 } from "react-icons/rx";

const OrderDetails = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");

  const [dataFetched, setDataFetched] = useState(false);
  const [fonepayImageData, setFonepayImageData] = useState(null);

  const [pinsOpen, setPINSOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pinsItem, setPINSItem] = useState(null);
  const [pinsOrders, setPinsOrders] = useState([]);
  const [isLoadingPins, setIsLoadingPins] = useState(false);
  const [copiedStates, setCopiedStates] = useState([]);

  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id))
      .then(() => {
        setDataFetched(true);
      })
      .catch((error) => {
        setDataFetched(true);
      });
  }, [dispatch]);

  const data = orders && orders.find((item) => item._id === id);

  useEffect(() => {
    if (dataFetched && !data) {
      window.location.href = "/404";
    }
  }, [dataFetched, data]);

  if (!dataFetched && !data) {
    return <Loader />;
  }

  const orderUpdateHandler = async (e) => {
    await axios
      .put(
        `${server}/order/update-order-status/${id}`,
        { status },
        { withCredentials: true }
      )
      .then((res) => {
        showToastSuccess(`Order updated from ${data?.status} successfully!`);
        navigate("/dashboard-orders");
      })
      .catch((error) => {
        showToastError(error.response.data.message);
      });
  };

  const refundOrderUpdateHandler = async (e) => {
    await axios
      .put(
        `${server}/order/process-order-refund/${id}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        showToastSuccess(`Order updated from ${data?.status} successfully!`);
        dispatch(getAllOrdersOfShop(seller._id));
      })
      .catch((error) => {
        showToastError(error.response.data.message);
      });
  };

  const updateRemarks = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/order/order-remarks/${id}`,
        { remarks },
        { withCredentials: true }
      )
      .then((res) => {
        showToastSuccess(`Order remarks updated from ${data?.remarks}!`);
        navigate("/dashboard-orders");
      })
      .catch((error) => {
        showToastError(error.response.data.message);
      });
  };

  const viewImage = async (paymentData) => {
    const body = {
      tokenId: data?.paymentInfo?.id,
    };

    const imageData = await axios.post(
      `${server}/payment/${paymentData}/getData`,
      body
    );

    window.open(`${backend_url}${imageData.data.payment.images}`, "_blank");
  };

  const confirmReceived = async (paymentData) => {
    try {
      setIsLoadingConfirm(true);

      setTimeout(async () => {
        const body = {
          tokenId: data?.paymentInfo?.id,
        };

        const updateStatus = await axios.post(
          `${server}/payment/${paymentData}/updateStatus`,
          body
        );

        const newBody = {
          status: updateStatus.data.payment.status,
          statusCode: updateStatus.data.payment.statusCode,
          transId: updateStatus.data.payment.transactionId,
          orderId: updateStatus.data.payment.orderId,
          TokenId: updateStatus.data.payment.token,
        };

        const updateRes = await axios.post(
          `${server}/order/update-${paymentData}-status`,
          newBody
        );

        if (updateRes.data.order.paymentInfo.status === "Succeeded") {
          showToastSuccess("Payment Status Updated Successfulyl!");
          window.location.reload();
        } else {
          showToastError("Something went wrong");
        }
        setIsLoadingConfirm(false);
      }, 2000);
    } catch (error) {
      setIsLoadingConfirm(false);
      showToastError(error);
    }
  };

  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(date).toLocaleString("en-US", options);
  };

  const statusInformation =
    data &&
    data?.cart?.flatMap((item) => {
      return item.formFields.map((field) => {
        return field.status;
      });
    });

  const isStatus = Object.values(statusInformation).some((status) => status);

  const razerReferenceID =
    data &&
    data.cart.flatMap((r) => {
      return r.razerReferenceID;
    });

  const hasRazerReferenceId = () => {
    return razerReferenceID.length > 0;
  };

  const tags =
    data &&
    data.cart?.map((t) => {
      return t.tags;
    });

  const fetchPinsOrders = async () => {
    try {
      setIsLoadingPins(true);

      const body = {
        orderId: data?.orderId,
      };

      const response = await axios.post(
        `${server}/order/seller/get-pins-order`,
        body,
        { withCredentials: true }
      );

      setPinsOrders(response.data.data.pins);

      setIsLoadingPins(false);
    } catch (error) {
      setIsLoadingPins(false);
      showToastError(error.response.data.message);
    }
  };

  const handleCopyToClipboard = async (serialNumber, index) => {
    const tempInput = document.createElement("input");
    tempInput.style.position = "absolute";
    tempInput.style.left = "-1000px";
    tempInput.value = serialNumber;

    document.body.appendChild(tempInput);

    tempInput.select();
    tempInput.setSelectionRange(0, 99999);

    document.execCommand("copy");

    document.body.removeChild(tempInput);

    setCopiedStates((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = true;
      return updatedStates;
    });

    setTimeout(() => {
      setCopiedStates((prevState) => {
        const updatedStates = [...prevState];
        updatedStates[index] = false;
        return updatedStates;
      });
    }, 3000);
  };

  return (
    <>
      {isLoadingConfirm && <LoadingOverlay />}

      <ShopHelmet title={seller.name} page={`Order Details - ${data?.status}`}>
        <div className={`py-4 min-h-screen ${styles.section}`}>
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center">
              <BsFillBagFill size={30} color="crimson" />
              <h1 className="pl-2 text-[25px]">Order Details</h1>
            </div>
            <Link to="/dashboard-orders">
              <div
                className={`${styles.button} !bg-[#fce1e6] !rounded-[4px] text-[#e94560] font-[600] !h-[45px] text-[18px]`}
              >
                Order List
              </div>
            </Link>
          </div>

          <div className="w-full flex items-center justify-between pt-6">
            <h5 className="text-[#00000084]">
              Order ID:{" "}
              <strong>
                <span>{data?.orderId}</span>
              </strong>
            </h5>{" "}
            <h5 className="text-[#00000084]">
              Placed on: <span>{formatDate(data?.createdAt)}</span>
            </h5>
          </div>

          <div className="w-full flex items-center justify-between">
            {hasRazerReferenceId() && (
              <h5 className="text-[#00000084]">
                Razer Reference ID:{" "}
                <strong>{razerReferenceID.join(", ")}</strong>
              </h5>
            )}
          </div>

          <br />

          {/* Ordered Items */}
          {data &&
            data?.cart.map((item, index) => (
              <div className="w-full flex items-start mb-5">
                <img
                  src={`${backend_url}${item.images[0]}`}
                  alt=""
                  className="w-[80x] h-[80px] rounded-md"
                />
                <div className="w-full">
                  <h5 className="pl-3 text-[20px]">
                    {item.name} - {item.productType}
                  </h5>
                  <h5 className="pl-3 text-[20px] text-[#00000091]">
                    Rs {item.originalPrice} x {item.qty}
                  </h5>
                </div>
                {data && data?.status && (
                  <>
                    {tags.includes("API") && !tags.includes("Validation") && (
                      <div
                        className={`${styles.button} !w-[350px] text-white !text-[14px] !font-[600]`}
                        onClick={() =>
                          setPINSOpen(true) ||
                          setPINSItem(item) ||
                          fetchPinsOrders()
                        }
                      >
                        Check {item?.name} PINS
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}

          {pinsOpen && (
            <div className="w-full fixed top-0 left-0 h-screen bg-[#0005] z-50 flex items-center justify-center">
              {isLoadingPins ? (
                <LoadingOverlay />
              ) : (
                <div className="w-[40%] h-min bg-[#fff] shadow rounded-md p-3">
                  <div className="max-[799px]:hidden w-full 800px:flex items-center">
                    <div className="w-full 800px:w-[500%]">
                      <h2 className="text-[30px] pb-2 font-[500] font-Poppins text-center">
                        PINS For {pinsItem.name}
                      </h2>
                    </div>
                    <div className="w-full 800px:w-[40%]">
                      <div className="w-full flex justify-end p-3">
                        <RxCross1
                          size={30}
                          onClick={() => setPINSOpen(false)}
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Mobile */}

                  <div className="max-[799px]:block 800px:hidden w-full items-center">
                    <div className="w-full min-[799px]:w-[40%]">
                      <div className="w-full flex justify-end p-3">
                        <RxCross1
                          size={30}
                          onClick={() => setPINSOpen(false)}
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                    <div className="w-full min-[799px]:w-[500%]">
                      <h2 className="text-[30px] pb-2 font-[500] font-Poppins text-center">
                        PINS For {pinsItem.name}
                      </h2>
                    </div>
                  </div>

                  <br />
                  {pinsOrders.map((pin, index) => (
                    <div key={index} className="w-full items-center">
                      <div
                        align="center"
                        className="border-solid border-[1px] border-[#3957db] rounded font-semibold text-xl text-[#3957db] !leading-[20px] p-[10px]"
                        onClick={() =>
                          handleCopyToClipboard(pin.serialNumber, index)
                        }
                      >
                        {copiedStates[index]
                          ? "Copied!"
                          : `S/N ${pin.serialNumber}`}
                      </div>
                      <div className="pb-[15px] !leading-[18px]"></div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="border-t w-full text-right">
            <h5 className="pt-3 text-[18px]">
              Total Price: <strong>Rs {data?.totalPrice}</strong>
            </h5>
          </div>

          <br />
          <br />

          {/* Shipping and Payment Info */}

          <div className="w-full 800px:flex items-center">
            <div className="w-full 800px:w-[50%]">
              <h4 className="pt-3 text-[20px] font-[600]">User Details:</h4>
              <h4 className="pt-3 text-[20px]">Name - {data?.user.name}</h4>
              <h4 className="text-[20px]">Email - {data?.user?.email}</h4>
              <h4 className="text-[20px]">
                Country -{" "}
                {data?.shippingAddress.country === ""
                  ? "Nepal"
                  : data?.shippingAddress.country}
              </h4>
              <h4 className="text-[20px]">
                Phone Number - + {data?.user?.phoneNumbers}
              </h4>
            </div>
            <div className="w-full 800px:w-[20%]">
              <h4 className="pt-3 text-[20px] font-[600]">Payment Info:</h4>
              <h4 className="pt-3">
                Paid Via -{" "}
                <span className="uppercase">
                  <strong>
                    {data?.paymentInfo?.type === "Fonepay(QR)" ||
                    data?.paymentInfo?.type === "eSewa(QR)" ? (
                      <>
                        {data?.paymentInfo?.type}
                        {data?.paymentInfo?.status === "Processing" ? (
                          <>
                            <div
                              className="mt-2 text-center cursor-pointer appearance-none  block w-full pt-1.5 h-[35px] bg-white border shadow-sm border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm hover:shadow-md"
                              onClick={() =>
                                data?.paymentInfo?.type === "eSewa(QR)"
                                  ? viewImage("esewa")
                                  : viewImage("fonepay")
                              }
                            >
                              View QR Image
                            </div>{" "}
                            <div
                              className="mt-2 text-center cursor-pointer appearance-none  block w-full pt-1.5 h-[35px] bg-white border shadow-sm border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm hover:shadow-md"
                              onClick={() =>
                                data?.paymentInfo?.type === "eSewa(QR)"
                                  ? confirmReceived("esewa")
                                  : confirmReceived("fonepay")
                              }
                            >
                              Confirm Payment
                            </div>
                          </>
                        ) : (
                          <div
                            className="mt-2 text-center cursor-pointer appearance-none  block w-full pt-1.5 h-[35px] bg-white border shadow-sm border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm hover:shadow-md"
                            onClick={() =>
                              data?.paymentInfo?.type === "eSewa(QR)"
                                ? viewImage("esewa")
                                : viewImage("fonepay")
                            }
                          >
                            View QR Image
                          </div>
                        )}
                      </>
                    ) : (
                      data?.paymentInfo?.type
                    )}
                  </strong>
                </span>
              </h4>
              <h4>
                Status -{" "}
                <span className="uppercase">
                  <strong>
                    {data?.paymentInfo?.status
                      ? data?.paymentInfo?.status
                      : "Not Paid"}
                  </strong>
                </span>
              </h4>
              <br />
            </div>
            {isStatus && (
              <>
                <div className="w-full 800px:w-[40%]"></div>
                <div className="w-full 800px:w-[40%]">
                  {data?.cart.map((item) => {
                    if (item.formFields.some((field) => field.status)) {
                      return (
                        <>
                          <h4 className="pt-3 text-[20px] font-[600]">
                            Details of {item.name}
                          </h4>
                          <div key={item._id}>
                            {Object.entries(item.formValues).map(
                              ([fieldName, value]) => (
                                <div key={fieldName} className="pt-3">
                                  {
                                    item.formFields.find(
                                      (field) => field.name === fieldName
                                    )?.placeholder
                                  }
                                  :{" "}
                                  <strong>
                                    {item.formFields
                                      .find((field) => field.name === fieldName)
                                      ?.options?.find(
                                        (option) => option.value === value
                                      )?.name || value}
                                  </strong>
                                </div>
                              )
                            )}
                          </div>
                        </>
                      );
                    }
                  })}
                </div>
              </>
            )}
          </div>

          <br />

          {/* Update Status */}
          <div className="w-full 800px:flex items-center">
            <div className="w-full 800px:w-[60%]">
              <h4 className="pt-3 text-[20px] font-[600]">Order Status:</h4>
              {data?.status !== "Initiating Refund" &&
                data?.status !== "Refund Success" &&
                data?.status !== "Refund Refused" && (
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="w-[200px] mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                  >
                    {[
                      "Processing",
                      "Payment On Hold",
                      "Info Incorrect",
                      "Cancelled",
                      "Transferred to Merchant API Delivery System",
                      "Delivering",
                      "Delivered",
                    ]
                      .slice(
                        [
                          "Processing",
                          "Payment On Hold",
                          "Info Incorrect",
                          "Cancelled",
                          "Transferred to Merchant API Delivery System",
                          "Delivering",
                          "Delivered",
                        ].indexOf(data?.status)
                      )
                      .map((option, index) => (
                        <option value={option} key={index}>
                          {option}
                        </option>
                      ))}
                  </select>
                )}

              {data?.status === "Initiating Refund" ||
              data?.status === "Refund Success" ||
              data?.status === "Refund Refused" ? (
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="w-[200px] mt-2 border h-[35px] rounded-[5px] cursor-pointer"
                >
                  {["Initiating Refund", "Refund Success", "Refund Refused"]
                    .slice(
                      [
                        "Initiating Refund",
                        "Refund Success",
                        "Refund Refused",
                      ].indexOf(data?.status)
                    )
                    .map((option, index) => (
                      <option value={option} key={index}>
                        {option}
                      </option>
                    ))}
                </select>
              ) : null}

              <div
                className={`${styles.button} mt-5 !bg-[#FCE1E6] !rounded-[4px] text-[#E94560] font-[600] !h-[45px] text-[18px]`}
                onClick={
                  data?.status !== "Initiating Refund"
                    ? orderUpdateHandler
                    : refundOrderUpdateHandler
                }
              >
                Update Status
              </div>
            </div>
            <form className="w-full 800px:w-[40%]" onSubmit={updateRemarks}>
              <div>
                <label className="pb-2">
                  Remarks <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={(e) => setRemarks(e.target.value)}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder={data?.remarks}
                />
              </div>
              <br />
              <div>
                <input
                  type="submit"
                  value="Submit Remarks"
                  className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] bg-white border shadow-sm border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm hover:shadow-md"
                />
              </div>
            </form>
          </div>
        </div>
      </ShopHelmet>
    </>
  );
};

export default OrderDetails;
