import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import UserInbox from "../components/Inbox/UserInbox";

const UserInboxPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <UserInbox />
      {/* <Footer /> */}
    </div>
  );
};

export default UserInboxPage;
