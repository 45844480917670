import React from "react";
import { useSelector } from "react-redux";
import EventCard from "../components/Events/EventCard";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Loader from "../components/Layout/Loader";
import Helmet from "../helmet/helmet";

const EventsPage = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Helmet title="Events">
          <div>
            <Header activeHeading={4} />
            {allEvents.length !== 0 && (
              <EventCard active={true} data={allEvents && allEvents[0]} />
            )}
            <h4 className="text-center text-[50px] pt-[120px]">
              {allEvents?.length === 0 && "No Events are running currently!"}
            </h4>
          </div>
        </Helmet>
      )}
    </>
  );
};

export default EventsPage;
