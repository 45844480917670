// export const server = "http://localhost:8000/api/v2";
export const server = "https://backend.blankgamingstore.com/api/v2";
// export const server = "https://demo.backend.blankgamingstore.com/api/v2";

// export const backend_url = "http://localhost:8000/";
export const backend_url = "https://backend.blankgamingstore.com/";
// export const backend_url = "https://demo.backend.blankgamingstore.com/";

// export const socket_url = "http://localhost:4000";
export const socket_url = "https://socket.blankgamingstore.com";
// export const socket_url = "https://demo.socket.blankgamingstore.com";

// export const banking_portal_url = "http://localhost:5000";
export const banking_portal_url = "https://pay.bank.blankgamingstore.com";
// export const banking_portal_url = "https://demo.pay.bank.blankgamingstore.com";
