import React, { useEffect } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import Helmet from "../helmet/helmet";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Helmet title="About Us">
      <div>
        <Header />
        <About />
        <Footer />
      </div>
    </Helmet>
  );
};

const About = () => {
  return (
    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">About Us </h2>

      <div className="mx-auto space-y-4">
        {/* Info About Company and Website */}
        <div className="border-b border-gray-200 pb-4">
          <span className="text-lg font-medium text-gray-900">
            Info About Company and Website
          </span>
          <div className="mt-4">
            <p className="text-base text-gray-500">
              Welcome to{" "}
              <Link to="https://blankgamingstore.com">
                <strong>blankgamingstore.com</strong>
              </Link>{" "}
              We are from the Himalayan Country of Nepal (Federal Democratic
              Republic of Nepal). Our Comapny was established in 2076 B.S (2019
              A.D). We have been providing our services via our store and now we
              have launched our web version for the purchase of various gaming
              top-up, gift cards and other subscriptions purhcases services.
              <br />
              <br />
              Registration Number: <strong>28-31-0240</strong>
              <br />
              PAN / VAT Number: <strong>612674217</strong>
              <br />
              Registered Country: <strong>Nepal</strong>
              <br />
              Registered Company Name:{" "}
              <strong>
                Manjushree Pustak Griha (a.k.a Blank Gaming Store)
              </strong>
            </p>
          </div>
        </div>
        {/* Goals */}
        <div className="border-b border-gray-200 pb-4">
          <span className="text-lg font-medium text-gray-900">
            Company Goal
          </span>
          <div className="mt-4">
            <p className="text-base text-gray-500">
              Our Company goal here in Blank Gaming Store is to provide
              efficient, affordable and instant means to purchase various
              subscriptions, gaming and other's services that is readily
              avilable globaly. Furthermore, We are looking forward to customer
              sastisfaction and hope to gain loyal customer base while providing
              zero-hassle services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
