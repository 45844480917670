import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineLoading,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { backend_url, server } from "../../../server";
import { addToCart } from "../../../redux/actions/cart";
import styles from "../../../styles/styles";
import {
  removeFromWishlist,
  addToWishlist,
} from "../../../redux/actions/wishlist";
import EditorComponent from "../../Editor/EditorComponent";
import { showToastError, showToastSuccess } from "../../../static/toastMessage";
import axios from "axios";
import { RiShoppingBasketFill } from "react-icons/ri";
import { FaBolt, FaClock, FaGlobe } from "react-icons/fa";
import { GiUsaFlag } from "react-icons/gi";

const ProductDetailsCard = ({ setOpen, data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { products } = useSelector((state) => state.products);
  const { cart } = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [price, setPrice] = useState("");
  const [manualPriceError, setManualPriceError] = useState("");
  const [formValues, setFormValues] = useState({});

  const [cartLoader, setCartLoader] = useState(false);

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  //   const [select, setSelect] = useState(false);

  const incrementCount = () => {
    if (count < 10) {
      setCount(count + 1);
    } else {
      showToastError("Only 10 items are allowed per order.");
    }
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    } else {
      showToastError("At least 1 items should be ordered.");
    }
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const activeEnterInformation =
    data &&
    data.formFields.map((p) => {
      return p.required;
    });

  const statusInformation =
    data &&
    data?.formFields?.map((s) => {
      return s.status;
    });

  const isStatus = statusInformation?.some((status) => status);
  const isRequired = activeEnterInformation?.some((required) => required);
  const dataTags = data?.tags.split(",").map((tag) => tag.trim());

  const buyNowButton = async (id) => {
    if (cartLoader) {
      return;
    }

    const isFormValuesEmpty = Object.values(formValues).every(
      (value) => !value
    );

    if (price === "") {
      showToastError("Please Select a Price!");
    } else if (isFormValuesEmpty && isRequired === true) {
      showToastError("Please enter the required fields!");
    } else {
      try {
        setCartLoader(true);

        setTimeout(async () => {
          if (cart.length > 0) {
            showToastError("Cart is limited to one item at a time currently.");
            navigate("/review-order");
          } else {
            const razerToken = await axios.post(
              `${server}/razer/generate-token`
            );

            if (razerToken.status === 200 || razerToken.status === 201) {
              let selectedPriceData;
              let newPrice;
              let itemInsideBracket;

              const isItemExist = cart && cart.find((i) => i._id === id);

              const tags = data.tags.split(",").map((tag) => tag.trim());

              if (tags.includes("Razer_Direct")) {
                const priceNPR = price * 145;

                const altData = data.priceData.find(
                  (item) => item.cost === "Rs 0 (0 USD)"
                );

                selectedPriceData = {
                  cost: `Rs ${priceNPR} (${price} ${altData.currency})`,
                  currency: altData.currency,
                  currencyPrice: price,
                  sku: altData.sku,
                  _id: altData._id,
                };

                newPrice = selectedPriceData.cost.replace(
                  /^[A-Za-z]+|\(.*?\)/g,
                  ""
                );

                const regex = /\(([^)]+)\)/;
                const matches = regex.exec(selectedPriceData.cost);
                itemInsideBracket = matches ? matches[1] : "";
              } else {
                selectedPriceData = data.priceData.find(
                  (item) => item.cost === price
                );

                const regex = /\(([^)]+)\)/;
                const matches = regex.exec(price);
                itemInsideBracket = matches ? matches[1] : "";
                newPrice = price.replace(/^[A-Za-z]+|\(.*?\)/g, "");
              }

              console.log(selectedPriceData);

              const availableAtOnce = 10;

              if (isItemExist) {
                showToastError(`${data.name} is already in the cart!`);
              } else {
                if (data.stock < count) {
                  showToastError(`${data.stock} are only in stocks!`);
                } else if (availableAtOnce < count) {
                  showToastError(
                    `${availableAtOnce} items can only be done in one checkout!`
                  );
                } else {
                  if (tags.includes("API")) {
                    const generateRazerReferenceID = await axios.get(
                      `${server}/razer/generate-razer-order-id`
                    );

                    const getIPResponse = await axios.get(
                      `${server}/user/getIP`,
                      {
                        withCredentials: true,
                      }
                    );

                    const razerReferenceID = `${generateRazerReferenceID.data.orderId}SKU${selectedPriceData.sku}`;

                    if (tags.includes("Validation_Required")) {
                      const cartData = {
                        ...data,
                        originalPrice: newPrice,
                        razerReferenceID: razerReferenceID,
                        productType: itemInsideBracket,
                        formValues: isStatus === true ? formValues : {},
                        priceFieldsData: selectedPriceData,
                        qty: count,
                      };

                      const customValueFields = [];

                      data?.formFields.forEach((field) => {
                        const fieldName = field.name;
                        const valueCode = formValues[fieldName];

                        customValueFields.push({
                          field_name: fieldName,
                          value_code: valueCode,
                        });
                      });

                      const productData = {
                        sku: selectedPriceData.sku,
                        custom_value: customValueFields,
                      };

                      const amountVerification =
                        selectedPriceData.currencyPrice;

                      const body = {
                        token: razerToken.data.data,
                        razerReferenceID: razerReferenceID,
                        currency: selectedPriceData.currency,
                        amount: amountVerification,
                        product: productData,
                        consumer_ip: getIPResponse.data.data.userIP,
                        consumer_country_code:
                          getIPResponse.data.data.countryCode,
                      };

                      const razerValidation = await axios.post(
                        `${server}/razer/validateAccount`,
                        body
                      );

                      if (
                        razerValidation.data.data.data.validate_status ===
                        "success"
                      ) {
                        dispatch(addToCart(cartData));
                        navigate("/review-order");
                      } else {
                        showToastError("Invalid Account");
                      }
                    } else {
                      const cartData = {
                        ...data,
                        originalPrice: newPrice,
                        productType: itemInsideBracket,
                        razerReferenceID: razerReferenceID,
                        formValues: isStatus === true ? formValues : {},
                        priceFieldsData: selectedPriceData,
                        qty: count,
                      };

                      const productData = [
                        {
                          sku: selectedPriceData.sku,
                          quantity: count,
                        },
                      ];

                      const body = {
                        razerReferenceID: razerReferenceID,
                        currency: selectedPriceData.currency,
                        product: productData,
                        consumer_ip: getIPResponse.data.data.userIP,
                        consumer_country_code:
                          getIPResponse.data.data.countryCode,
                      };

                      const pinOrderData = await axios.post(
                        `${server}/razer/pinData`,
                        body
                      );

                      if (pinOrderData.status === 201) {
                        dispatch(addToCart(cartData));
                        navigate("/review-order");
                      } else {
                        showToastError("Contact Support");
                      }
                    }
                  } else {
                    const cartData = {
                      ...data,
                      originalPrice: newPrice,
                      productType: itemInsideBracket,
                      formValues: isStatus === true ? formValues : {},
                      priceFieldsData: selectedPriceData,
                      qty: count,
                    };

                    dispatch(addToCart(cartData));
                    navigate("/review-order");
                  }
                }
              }
            } else {
              showToastError("API Error, Refresh and Try Again!");
            }
          }

          setCartLoader(false);
        }, 2000);
      } catch (error) {
        showToastError("Contact Admin/Seller!");
      }
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    showToastSuccess(
      `${data.name} has been removed from wishlist successfully!`
    );
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    showToastSuccess(`${data.name} has been added to wishlist successfully!`);
    dispatch(addToWishlist(data));
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  return (
    <div className="bg-[#fff]">
      {data ? (
        <div className="fixed w-full h-screen top-0 left-0 bg-[#00000030] z-40 flex items-center justify-center cursor-default">
          <div className="w-[90%] 800px:w-[60%] h-[90vh] overflow-y-auto 800px:h-[75vh] bg-white rounded-md shadow-sm relative p-4">
            <RxCross1
              size={30}
              className="absolute right-3 top-3 z-50 cursor-pointer"
              onClick={() => setOpen(false)}
            />

            <div className="block w-full 800px:flex">
              <div className="w-full 800px:w-[50%]">
                <img
                  src={`${backend_url}${data.images && data.images[0]}`}
                  alt=""
                  className="h-[350px] w-[380px] mb-4 rounded-lg"
                />
                <div className="flex">
                  <Link to={`/shop/preview/${data.shop._id}`} className="flex">
                    <img
                      src={`${backend_url}${data?.shop?.avatar}`}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full mr-2"
                    />
                  </Link>
                  <div>
                    <Link
                      to={`/shop/preview/${data.shop._id}`}
                      className="flex"
                    >
                      <h3 className={`${styles.shop_name}`}>
                        {data.shop.name}
                      </h3>
                    </Link>
                    <h5 className="pb-3 text-[15px] cursor-text">
                      ({averageRating}/5) Ratings
                    </h5>
                  </div>
                </div>
              </div>

              <div className="w-full 800px:w-[50%] pt-5 pl-[5px] pr-[5px]">
                <div className="w-full">
                  <h1 className={`${styles.productTitle}`}>{data?.name}</h1>
                  {dataTags?.includes("Instant_Delivery") ||
                  dataTags?.includes("Global") ||
                  dataTags?.includes("Manual") ||
                  dataTags?.includes("USA") ? (
                    <>
                      <ul class="feature">
                        {dataTags?.includes("Global") && (
                          <li>
                            <div class="T">
                              <span region="global">
                                <FaGlobe />
                              </span>
                            </div>
                            <div class="C">
                              <b>Global</b>
                            </div>
                          </li>
                        )}
                        {dataTags?.includes("USA") && (
                          <li>
                            <div class="T">
                              <span region="global">
                                <GiUsaFlag />
                              </span>
                            </div>
                            <div class="C">
                              <b>United States of America (USA)</b>
                            </div>
                          </li>
                        )}
                        {dataTags?.includes("Manual") && (
                          <li>
                            <div class="T">
                              <span region="offline_bolt">
                                <FaClock />
                              </span>
                            </div>
                            <div class="C">
                              <b>5-30 mins</b>
                            </div>
                          </li>
                        )}
                        {dataTags?.includes("Instant_Delivery") && (
                          <li>
                            <div class="T">
                              <span icon="offline_bolt">
                                <FaBolt />
                              </span>
                            </div>
                            <div class="C">
                              <b>Instant Delivery</b>
                            </div>
                          </li>
                        )}
                      </ul>
                    </>
                  ) : null}
                </div>
                <p className="cursor-text mt-5">
                  <EditorComponent description={data.quickDescription} />
                </p>

                {/* <div className="flex pt-6"> */}
                <div className="w-full pt-5">
                  {data.tags.includes("API") &&
                  data.tags.includes("Validation_Required") &&
                  data.tags.includes("Razer_Direct") ? (
                    <div className="flex items-center mt-4 justify-between pr-8">
                      <label className="block pb-2 font-[500] cursor-text">
                        Enter Price for {data?.name}
                      </label>
                      <div>
                        <div className="currency-input-icon">
                          <input
                            type="number"
                            onChange={(e) => {
                              const inputPrice = e.target.value;
                              setPrice(inputPrice);

                              if (inputPrice <= 0) {
                                setManualPriceError(
                                  "Price must be a positive value"
                                );
                                setPrice("");
                              } else {
                                setManualPriceError("");
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px] rounded-l shadow-lg ml-1"
                            placeholder="Enter in USD"
                          />
                          <i>$</i>
                        </div>
                        <div>
                          {manualPriceError && (
                            <span className="text-red-500">
                              {manualPriceError}
                            </span>
                          )}
                          {price && (
                            <span className="text-base font-bold pl-2 text-green-500">
                              NPR {price * 145}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <label className="block pb-2 font-[500] cursor-text">
                        Select Amount of {data?.name}
                      </label>
                      <select
                        name=""
                        id=""
                        required
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="w-[95%] border h-[40px] rounded-[5px] cursor-pointer"
                      >
                        <option className="block border pb-2">
                          Select an amount of {data?.name} you want
                        </option>
                        {data?.priceData.map((i, index) => {
                          return (
                            <option
                              className="block pb-2"
                              key={index}
                              value={i.cost}
                            >
                              {i.cost}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  )}
                </div>

                {data.tags.includes("API") &&
                  !data.tags.includes("Validation") && (
                    <div className="flex items-center mt-4 justify-between pr-5">
                      <label className="block pb-2 text-[14px] font-[500] cursor-text">
                        Select Quantity for {data?.name}
                      </label>
                      <div>
                        <button
                          className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                          onClick={decrementCount}
                        >
                          -
                        </button>
                        <span className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px]">
                          {count}
                        </span>
                        <button
                          className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                          onClick={incrementCount}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  )}

                {isStatus === true ? (
                  <>
                    <hr className="h-px mt-8 w-[95%] bg-gray-200 border-0 dark:bg-gray-700" />
                    <div className="w-full pt-5">
                      <label className="block pb-2 font-[500] cursor-text">
                        Enter Details For {data?.name}
                      </label>
                      {data.formFields.map((field, index) => (
                        <div key={index}>
                          {field.type === "select" && (
                            <select
                              name={field.name}
                              required={field.required}
                              value={formValues[field.name] || ""}
                              onChange={(event) =>
                                handleInputChange(event, field.placeholder)
                              }
                              className="mt-2 w-[95%] border h-[35px] rounded-[5px] cursor-pointer"
                            >
                              <option value="" disabled>
                                Choose an {field.placeholder}
                              </option>
                              {field.options.map((option) => (
                                <option key={option._id} value={option.value}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          )}
                          {field.type !== "select" && (
                            <input
                              type={field.type}
                              name={field.name}
                              placeholder={field.placeholder}
                              required={field.required}
                              value={formValues[field.name] || ""}
                              onChange={(event) =>
                                handleInputChange(event, field.placeholder)
                              }
                              onWheel={(e) => e.target.blur()}
                              className="mt-2 appearance-none block w-[95%] px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}

                <div className="flex items-center mt-6 justify-between pr-3">
                  <div className="flex" disabled={cartLoader}>
                    <div
                      className={`${styles.button} !rounded mr-4 !h-11 flex items-center`}
                      onClick={() => buyNowButton(data._id)}
                    >
                      <span className="text-white flex items-center">
                        {cartLoader ? (
                          <>
                            <AiOutlineLoading className="animate-spin text-white ml-1" />
                          </>
                        ) : (
                          <>
                            Buy Now <RiShoppingBasketFill className="ml-1" />
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => removeFromWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => addToWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Add to wishlist"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetailsCard;
