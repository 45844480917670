import React, { useEffect } from "react";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/107043-success.json";
import Helmet from "../../helmet/helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";

const FonepayOrderSuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        var currentURL = document.URL;

        const fonepayTrans = currentURL.split("?")[1].split("=")[1];

        if (!fonepayTrans) {
          window.location.href = "/404";
        } else {
          const Infobody = {
            transId: fonepayTrans,
          };

          const res = await axios.post(
            `${server}/payment/fonepay/getInfo`,
            Infobody
          );

          const body = {
            status: res.data.fonepayPayment.status,
            statusCode: res.data.fonepayPayment.statusCode,
            transId: res.data.fonepayPayment.transactionId,
            orderId: res.data.fonepayPayment.orderId,
            TokenId: res.data.fonepayPayment.token,
          };

          const orderRes = await axios.post(
            `${server}/order/update-fonepay-status`,
            body
          );

          if (
            orderRes.data.order.paymentInfo.status === "Processing" ||
            orderRes.data.order.paymentInfo.status === "Succeeded"
          ) {
            setTimeout(() => {
              navigate(`/user/order/${orderRes.data.order._id}`);
            }, 800);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Helmet title="Order Success">
      <div>
        <Header />
        <Success />
        <Footer />
      </div>
    </Helmet>
  );
};

const Success = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} width={300} height={300} />
      <h5 className="text-center mb-14 text-[25px] text-[#000000a1]">
        Your order is successful 😍
      </h5>
      <br />
      <br />
    </div>
  );
};
export default FonepayOrderSuccessPage;
