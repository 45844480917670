import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { BsPencil } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { server } from "../../server";
import styles from "../../styles/styles";
import { Button } from "@material-ui/core";
import UserHelmet from "../../helmet/userHelmet";
import { useSelector } from "react-redux";
import { showToastError, showToastSuccess } from "../../static/toastMessage";

const AllWithdraw = () => {
  const { user } = useSelector((state) => state.user);

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [withdrawData, setWithdrawData] = useState();
  const [withdrawStatus, setWithdrawStatus] = useState("Processing");

  useEffect(() => {
    axios
      .get(`${server}/withdraw/get-all-withdraw-request`, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data.withdraws);
      })
      .catch((error) => {
        showToastError(error.response.data.message);
      });
  }, []);

  const columns = [
    { field: "id", headerName: "Withdraw Id", minWidth: 150, flex: 0.3 },
    {
      field: "name",
      headerName: "Shop Name",
      minWidth: 80,
      flex: 0.3,
    },
    {
      field: "shopId",
      headerName: "Shop Id",
      minWidth: 80,
      flex: 0.3,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 80,
      flex: 0.3,
    },
    {
      field: "status",
      headerName: "Status",
      type: "text",
      minWidth: 80,
      flex: 0.2,
    },
    {
      field: "createdAt",
      headerName: "Requested Date",
      type: "number",
      minWidth: 80,
      flex: 0.4,
    },
    {
      field: " ",
      headerName: "Update Status",
      type: "number",
      minWidth: 130,
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Button
            className={`${
              params.row.status !== "Processing" ? "invisible" : ""
            } cursor-pointer`}
            onClick={() => setOpen(true) || setWithdrawData(params.row)}
          >
            <BsPencil size={20} />
          </Button>
        );
      },
    },
  ];

  const handleSubmit = async () => {
    await axios
      .put(
        `${server}/withdraw/update-withdraw-request/${withdrawData.id}`,
        {
          sellerId: withdrawData.shopId,
        },
        { withCredentials: true }
      )
      .then((res) => {
        showToastSuccess("Withdraw request updated successfully!");
        setData(res.data.withdraws);
        setOpen(false);
        window.location.reload();
      });
  };

  const row = [];

  data &&
    data.forEach((item) => {
      row.push({
        id: item._id,
        shopId: item.seller._id,
        name: item.seller.name,
        amount: "Rs " + item.amount,
        status: item.status,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <div className="w-full flex items-center pt-5 justify-center">
      <UserHelmet title={user?.name} page="All Withdraw">
        <div className="w-[95%] bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      </UserHelmet>
      {open && (
        <UserHelmet
          title={user?.name}
          page={`${withdrawData.name} - Withdraw Request`}
        >
          <div className="w-full fixed h-screen top-0 left-0 bg-[#00000031] z-[9999] flex items-center justify-center">
            <div className="w-[50%] min-h-[40vh] bg-white rounded shadow p-4">
              <div className="flex justify-end w-full">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              </div>
              <h1 className="text-[25px] text-center font-Poppins">
                Update Withdraw status
              </h1>
              <br />
              <div className="flex">
                <h1 className="text-[15px] font-Poppins">
                  Bank Account Info of Shop:{" "}
                  <strong>{withdrawData.name}</strong>
                </h1>
              </div>
              <div className="flex">
                <h1 className="text-[15px] font-Poppins">
                  Shop Id: <strong>{withdrawData.shopId}</strong>
                </h1>
              </div>
              <br />
              <select
                name=""
                id=""
                onChange={(e) => setWithdrawStatus(e.target.value)}
                className="w-[200px] h-[35px] border rounded cursor-pointer"
              >
                {withdrawData.status === "Success" ? (
                  <option value={withdrawStatus}>{withdrawData.status}</option>
                ) : (
                  <>
                    <option value={withdrawStatus}>
                      {withdrawData.status}
                    </option>
                    <option value={withdrawStatus}>Success</option>
                  </>
                )}
              </select>
              <button
                type="submit"
                className={`block ${styles.button} text-white !h-[42px] mt-4 text-[18px]`}
                onClick={handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
        </UserHelmet>
      )}
    </div>
  );
};

export default AllWithdraw;
